import { UnknownAction } from "@reduxjs/toolkit";
import localForage from "localforage";
import { PersistConfig, persistCombineReducers } from "redux-persist";
// Common Reducers
import {
    coreReducers,
    resetFinancingData,
    sanitizeCheckoutData,
    sanitizeCommonData,
} from "tsi-common-react/src/apps/reducers";

// Site-specific Reducers
import { reducers as retailReducers } from "./RetailLocator/reducers";
import { IReduxState } from "./reducers.interfaces";

export const storage = localForage.createInstance({
    name: "tempurpedic-redux",
});
const persistConfig: PersistConfig<IReduxState> = {
    key: "redux-global",
    storage: storage,
    transforms: [sanitizeCommonData, sanitizeCheckoutData, resetFinancingData],
    whitelist: [
        "common",
        "checkout",
        "financing",
        // 'retail',
    ],
};
export const reducers = persistCombineReducers<IReduxState, UnknownAction>(
    persistConfig,
    {
        ...coreReducers,
        retail: retailReducers,
    },
);
