import * as t from "io-ts";
import { NumberFromString } from "io-ts-types/lib/NumberFromString";

import { StreamValue } from "../../models/streamfield-blocks";
import { codecFromEnum } from "../../models/utils";

export enum GridFilterSelectionMode {
    SELECT_ONE = "one",
    SELECT_MANY = "many",
}

export enum PriceComparator {
    LT = "LT",
    LTE = "LTE",
    GT = "GT",
    GTE = "GTE",
}

export enum SortDirection {
    ASC = "ASC",
    DESC = "DESC",
}

const GridFilterSelectionModeCodec = codecFromEnum(
    "GridFilterSelectionMode",
    GridFilterSelectionMode,
);
const PriceComparatorCodec = codecFromEnum("PriceComparator", PriceComparator);
const SortDirectionCodec = codecFromEnum("SortDirection", SortDirection);

export const SingleBoundPriceRange = StreamValue(
    "single_bound_price_range",
    t.interface({
        id: t.string,
        comparator: PriceComparatorCodec,
        amount: NumberFromString,
    }),
);

export const DualBoundPriceRange = StreamValue(
    "dual_bound_price_range",
    t.interface({
        id: t.string,
        lower_bound: t.interface({
            comparator: PriceComparatorCodec,
            amount: NumberFromString,
        }),
        upper_bound: t.interface({
            comparator: PriceComparatorCodec,
            amount: NumberFromString,
        }),
    }),
);

export const PriceRange = t.union([SingleBoundPriceRange, DualBoundPriceRange]);

export const BooleanAttribute = t.interface({
    attribute: t.string,
    label: t.string,
});

const AttributeValueGridFilter = StreamValue(
    "attribute_value_grid_filter",
    t.interface({
        filter_id: t.string,
        label: t.string,
        selection_mode: GridFilterSelectionModeCodec,
    }),
);

const ProductClassGridFilter = StreamValue(
    "product_class_grid_filter",
    t.interface({
        filter_id: t.string,
        label: t.string,
        selection_mode: GridFilterSelectionModeCodec,
    }),
);

const PriceRangeGridFilter = StreamValue(
    "price_range_grid_filter",
    t.interface({
        filter_id: t.string,
        label: t.string,
        selection_mode: GridFilterSelectionModeCodec,
        ranges: t.array(PriceRange),
    }),
);

const BooleanAttributeGridFilter = StreamValue(
    "boolean_attribute_grid_filter",
    t.interface({
        filter_id: t.string,
        label: t.string,
        selection_mode: GridFilterSelectionModeCodec,
        attributes: t.array(BooleanAttribute),
    }),
);

const AttributeValueGridSorter = StreamValue(
    "attribute_value_grid_sorter",
    t.interface({
        id: t.string,
        label: t.string,
        direction: SortDirectionCodec,
        attribute: t.string,
    }),
);

const PriceGridSorter = StreamValue(
    "price_grid_sorter",
    t.interface({
        id: t.string,
        label: t.string,
        direction: SortDirectionCodec,
    }),
);

const PillowsPriceGridSorter = StreamValue(
    "pillows_price_grid_sorter",
    t.interface({
        id: t.string,
        label: t.string,
        direction: SortDirectionCodec,
    }),
);

const RatingGridSorter = StreamValue(
    "rating_grid_sorter",
    t.interface({
        id: t.string,
        label: t.string,
        direction: SortDirectionCodec,
    }),
);

const RatingNumberGridSorter = StreamValue(
    "rating_number_grid_sorter",
    t.interface({
        id: t.string,
        label: t.string,
        direction: SortDirectionCodec,
    }),
);

const DateCreatedGridSorter = StreamValue(
    "date_created_grid_sorter",
    t.interface({
        id: t.string,
        label: t.string,
        direction: SortDirectionCodec,
    }),
);

export const GridFilter = t.union([
    AttributeValueGridFilter,
    ProductClassGridFilter,
    PriceRangeGridFilter,
    BooleanAttributeGridFilter,
]);

export const GridSorter = t.union([
    AttributeValueGridSorter,
    PriceGridSorter,
    PillowsPriceGridSorter,
    RatingGridSorter,
    RatingNumberGridSorter,
    DateCreatedGridSorter,
]);

export const GridFilters = t.array(GridFilter);
export const GridSorters = t.array(GridSorter);
