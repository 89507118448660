import { useAppDispatch, useAppSelector } from "../../reducers";
import { stepInformation } from "../constants";
import { IStepData, OptionStepsType } from "../models.interfaces";
import { onSelectOption } from "../reducers";

interface IUseStepInformationProps {
    step: keyof OptionStepsType;
}

export const useStepInformation = (props: IUseStepInformationProps) => {
    const { selections } = useAppSelector((state) => state.mattressmatch.ui);
    const dispatch = useAppDispatch();
    const { step } = props;
    const info = stepInformation[step];

    const onClick = async (option: IStepData) => {
        dispatch(
            onSelectOption({
                selectedOption: option.level,
                step: props.step,
            }),
        );
    };

    return {
        stepInfo: info,
        selectedLevel: selections[info.key],
        selectedData: info.data.find((d) => d.level === selections[info.key]),
        onClick: onClick,
    };
};
