import React from "react";
import { t } from "ttag";

import { Category } from "../interfaces";

interface Props {
    categories: (Category & { override_url?: string })[];
    city?: string;
    state?: string;
}

export const CategoriesFooter = ({ categories, city, state }: Props) => {
    const heroElem = document.querySelector<HTMLElement>(".tempurlove-hero");
    const rootURL = heroElem?.dataset.tempurLoveRootUrl;
    return (
        <footer className="social-icons">
            <div className="social-icons__tags">
                {categories.map((category) => (
                    <>
                        <span className="ada-screenreader-only">
                            {t`Filter Stories by category`}
                        </span>
                        <a
                            key={category.slug}
                            href={
                                category.override_url ||
                                (rootURL || "") + "c/" + category.slug
                            }
                            title={category.name}
                        >
                            <img src={category.image} alt={category.name} />
                            <span>{category.name}</span>
                        </a>
                    </>
                ))}
                {city && state && (
                    <span>
                        {city}, {state}
                    </span>
                )}
            </div>
        </footer>
    );
};
