// Load lazysizes library
import "lazysizes";
import { decorateElements } from "tsi-common-react/src/utils/react";

const shiftBackgroundImage = (
    elem: HTMLElement,
    shiftDetails: {
        shift_amount_x: number;
    },
) => {
    if (!shiftDetails) {
        return;
    }
    const xShift = shiftDetails.shift_amount_x;
    if (xShift) {
        elem.style.backgroundPositionX = `${xShift}%`;
    }
};

const markNodeAsVisible = (elem: HTMLElement) => {
    elem.classList.add("lazy-background--visible");
    const backgroundImage = elem.dataset.backgroundImage;
    const backgroundShift = elem.dataset.backgroundImageShift;

    if (backgroundImage) {
        elem.style.backgroundImage = `url(${backgroundImage})`;

        // if background shift is specified, apply background position to the element
        const shiftDetails = JSON.parse(backgroundShift || "{}");
        shiftBackgroundImage(elem, shiftDetails);
    }
    // if we're styling a Background Image Full Height,
    // then grab the height and style the block accordingly
    if (elem.classList.contains("background--image-full-height")) {
        elem.style.minHeight = `${elem.dataset.backgroundImageHeight}px`;
    }
};

decorateElements(".lazy-background", (elems) => {
    // Register the intersection observers to load this background image when it becomes visible
    // If the UA is headless chrome (Puppeteer) load everything immediately.
    if (
        "IntersectionObserver" in window &&
        !window.navigator.userAgent.includes("HeadlessChrome")
    ) {
        const lazyBackgroundObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    markNodeAsVisible(entry.target as HTMLElement);
                    lazyBackgroundObserver.unobserve(entry.target);
                }
            });
        });
        elems.forEach((elem) => {
            lazyBackgroundObserver.observe(elem);
        });
    } else {
        elems.forEach(markNodeAsVisible);
    }
});
