import * as Accordion from "@radix-ui/react-accordion";
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { rootProductSelector } from "tsi-common-react/src/apps/configurator/selectors";
import { Image } from "tsi-common-react/src/common/Image";
import { IProduct } from "tsi-common-react/src/models/catalogue.interfaces";
import { t } from "ttag";

import minus from "../../img/pdp/hotspot/minus.png";
import plus from "../../img/pdp/hotspot/plus.png";
import { IReduxState } from "../../reducers.interfaces";
import { IHotspotContent } from "../models";

import styles from "./Hotspot.module.scss";

interface IOwnProps {
    hotspotContent: IHotspotContent;
}
interface IReduxProps {
    rootProduct: IProduct | null;
}
type IProps = IOwnProps & IReduxProps;
interface IState {
    isSmallScreen: boolean;
}
export class Hotspot extends React.Component<IProps, IState> {
    private readonly SMALL_SCREEN_WIDTH_THRESHOLD = 1366;

    state: IState = {
        isSmallScreen: false,
    };

    componentDidMount() {
        this.checkScreenSize();
        window.addEventListener("resize", () => {
            this.checkScreenSize();
        });
    }

    private isSmallScreenWidth() {
        return window.innerWidth < this.SMALL_SCREEN_WIDTH_THRESHOLD;
    }

    private checkScreenSize() {
        this.setState({
            isSmallScreen: this.isSmallScreenWidth(),
        });
    }

    render() {
        const rootProduct = this.props.rootProduct;
        if (!rootProduct) {
            return null;
        }
        const tipIds = Object.keys(this.props.hotspotContent).filter((key) =>
            key.includes("hotspot"),
        );
        // Detect if page is Breeze PLP or ActiveBreeze PDP from page link
        const pageLink = rootProduct.link?.toString();
        const rootClasses = classNames({
            [styles.root]: true,
            [styles.adapt]: rootProduct.slug.includes("adapt"),
            [styles.breeze]: pageLink?.includes("breeze-collection"),
            [styles.activeBreeze]: pageLink?.includes("active-breeze"),
            [styles.cloud]: rootProduct.slug.includes("cloud"),
            [styles.ergoSmartBase]:
                rootProduct.slug.includes("ergo-smart-base"),
        });

        return (
            <div className={rootClasses}>
                <div
                    className={styles.header}
                    dangerouslySetInnerHTML={{
                        __html: this.props.hotspotContent.header_content,
                    }}
                ></div>
                <Accordion.Root
                    className={styles.accordion}
                    type="single"
                    defaultValue={tipIds[1]}
                    collapsible={true}
                >
                    {tipIds.map((tipId: string) => (
                        <Accordion.Item
                            key={tipId}
                            value={tipId}
                            className={styles.accordionItem}
                        >
                            <Accordion.Header>
                                <Accordion.Trigger className={styles.button}>
                                    <div className={styles.pulse}> </div>
                                    <Image
                                        lazy={false}
                                        src={plus}
                                        className={styles.buttonImageExpand}
                                        aria-hidden="true"
                                        alt={t`Hotspot Icon Expand`}
                                    />
                                    <Image
                                        lazy={false}
                                        src={minus}
                                        className={styles.buttonImageCollapse}
                                        aria-hidden="true"
                                        alt={t`Hotspot Icon Collapse`}
                                    />
                                </Accordion.Trigger>
                            </Accordion.Header>
                            <Accordion.Content
                                className={styles.accordionPanel}
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: this.props.hotspotContent[
                                            tipId as keyof IHotspotContent
                                        ],
                                    }}
                                ></div>
                            </Accordion.Content>
                        </Accordion.Item>
                    ))}
                </Accordion.Root>
            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState, ownProps: IOwnProps) => {
    return {
        rootProduct: rootProductSelector(state.configurator),
        ...ownProps,
    };
};

export const HotspotBlock = connect(mapStateToProps)(Hotspot);
