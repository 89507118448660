import classNames from "classnames";
import React from "react";
import Trans from "tsi-common-react/src/common/Trans";
import { trackAddToBasketEvent } from "tsi-common-react/src/utils/analytics";
import { t } from "ttag";

import { IBasketLineSuggestionProps } from "./BasketLineSuggestion.interfaces";

interface IState {}

export class BasketLineSuggestionC extends React.Component<
    IBasketLineSuggestionProps,
    IState
> {
    private addToBasket() {
        this.props.addBasketLine(this.props.product.url, 1);
        trackAddToBasketEvent(this.props.product, 1);
    }

    private buildAddToBasketButton() {
        let addToCartLabel: string = t`Add to cart`;
        let isPreorder = false;
        if (
            this.props.product.attributes &&
            this.props.product.attributes.preorder &&
            this.props.product.attributes.preorder.value
        ) {
            addToCartLabel = t`Pre-Order`;
            isPreorder = true;
        }

        const buttonClasses = classNames({
            "button": true,
            "button--secondary": isPreorder,
        });

        return (
            <button
                className={buttonClasses}
                onClick={this.addToBasket.bind(this)}
            >
                {addToCartLabel}
            </button>
        );
    }

    render() {
        return (
            <div
                role="region"
                aria-label="Upsell Carousel"
                className="basket-suggestion-c"
            >
                <div className="basket-suggestion-c__header">
                    <div className="basket-suggestion-c__title basket-suggestion-c__title">
                        {t`$300 Instant Credit!`}
                    </div>
                    <span className="basket-suggestion-c__subtitle">
                        <Trans
                            fmtString={t`Add the TEMPUR-Ergo<Superscript1>®</Superscript1> Plus! to your order and get $300 instant credit!<Superscript2>*</Superscript2>`}
                            data={{
                                Superscript1: (content) => (
                                    <sup key="1">{content}</sup>
                                ),

                                Superscript2: (content) => (
                                    <sup key="2">{content}</sup>
                                ),
                            }}
                        />
                    </span>
                </div>
                <div className="basket-suggestion-c__content">
                    <div className="basket-suggestion-c__content--left basket-suggestion-c__content--left-2"></div>
                    <div className="basket-suggestion-c__content--right">
                        <div className="basket-suggestion-c__list-container">
                            <div className="basket-suggestion-c__list-title">
                                <Trans
                                    fmtString={t`Why You'll Love The TEMPUR-Ergo<Superscript>®</Superscript> Plus!`}
                                    data={{
                                        Superscript: (content) => (
                                            <sup key="1">{content}</sup>
                                        ),
                                    }}
                                />
                            </div>
                            <ul className="basket-suggestion-c__list">
                                <li className="basket-suggestion-c__list-item">
                                    {t`Lifts your head and feet to the perfect position.`}
                                </li>
                                <li className="basket-suggestion-c__list-item">
                                    {t`Personalized comfort for reading, relaxing, and sleeping.`}
                                </li>
                                <li className="basket-suggestion-c__list-item">
                                    {t`Virtually unlimited ergonomic positions.`}
                                </li>
                            </ul>
                        </div>
                        <div className="basket-suggestion-c__price-container">
                            <div className="basket-suggestion-c__price">
                                $1,199.00
                            </div>
                            <div className="basket-suggestion-c__price-callout">
                                <span className="basket-suggestion-c__price-callout--underlined">
                                    {t`$300 INSTANT CREDIT APPLIED IN CART`}
                                </span>
                            </div>
                        </div>
                        <div className="basket-suggestion-c__ctas">
                            {this.buildAddToBasketButton()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
