import classNames from "classnames";
import React from "react";
import { IProduct } from "tsi-common-react/src/models/catalogue.interfaces";
import format from "tsi-common-react/src/utils/format";

interface IProps {
    product: IProduct;
    variant: IProduct;
    last: boolean;
}

interface IState {}

/**
 * A tiny product widget to show within the PDP Bundle
 */
export class TinyProduct extends React.Component<IProps, IState> {
    render() {
        const product = this.props.product;
        const variant = this.props.variant;

        const wrapperClass = classNames({
            "pdp-bundle-item": true,
            "pdp-bundle-item--last": this.props.last,
        });

        const imageStyle: React.CSSProperties = {
            backgroundImage:
                product && product.images && product.images.length
                    ? `url(${product.images[0].original})`
                    : undefined,
        };

        return (
            <div className={wrapperClass}>
                <div
                    className="pdp-bundle-item__image"
                    style={imageStyle}
                ></div>
                <div className="pdp-bundle-item__copy">
                    <div className="pdp-bundle-item__title">
                        {product.title}
                    </div>
                    <div className="pdp-bundle-item__category">
                        {product.subtitle}
                    </div>
                    <div className="pdp-bundle-item__price">
                        {format.money(variant.price.cosmetic_excl_tax)}
                    </div>
                </div>
            </div>
        );
    }
}
