import classNames from "classnames";
import React from "react";
import SVG from "react-inlinesvg";
import ReactTooltip from "react-tooltip";
import { StoreCategory } from "tsi-common-react/src/api/retail";
import { Link } from "tsi-common-react/src/common/Link";
import {
    RetailStoreEventCTAType,
    RetailStoreEventType,
} from "tsi-common-react/src/constants";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { ISyncStoreID, isoMiles } from "tsi-common-react/src/models/nominals";
import { trackRetailStoreEvent } from "tsi-common-react/src/utils/analytics";
import { formatPhoneNumber } from "tsi-common-react/src/utils/format";
import { metersToMiles } from "tsi-common-react/src/utils/maps";
import { t } from "ttag";

import iconIconDirectionBlue from "../../img/retail-locator/icon-direction-blue.svg";
import iconIconInfoBlue from "../../img/retail-locator/icon-info-blue.svg";
import openLinkIconBlue from "../../img/retail-locator/open-link-icon-blue.png";
import iconQuestionMarkCircleFilled from "../../svg/question-mark-circle-filled.svg";

interface IStoreRowLocationProps {
    store: IRetailStoreWithDistance;
    showWebsite: boolean;
    getStoreNumber: (store: IRetailStoreWithDistance) => number;
}

enum ConsumerProgramName {
    SEC = "Sleep Experience Center",
    SBR = "Smart Base Retailer",
    SBID = "Smart Base Innovation Display",
}

interface IProgramInfo {
    name: ConsumerProgramName;
    tooltipText: string;
}

const tooltipTexts: { [T in ConsumerProgramName]: string } = {
    [ConsumerProgramName.SEC]: t`Tempur-Pedic Sleep Experience Centers offer interactive kiosks and a broad selection of Tempur-Pedic products to help you discover and explore our mattresses, adjustable bases, pillows and more.`,
    [ConsumerProgramName.SBR]: t`Tempur-Pedic Smart Base Retailers offer our TEMPUR-Ergo&reg; Smart Bases, powered by Sleeptracker&reg;AI. From an automatic snore response feature to daily sleep tracking and coaching, Ergo Smart Bases help you take your sleep to the next level.`,
    [ConsumerProgramName.SBID]: t`Smart Base Innovation Display retailers offer interactive sleep experiences and automated demonstrations of Ergo Smart Bases, powered by Sleeptracker-AI, to help you take your sleep to the next level.`,
};

const getProgramInfo = (name: ConsumerProgramName): IProgramInfo => {
    return {
        name: name,
        tooltipText: tooltipTexts[name] || "",
    };
};

const getDisplayedProgram = (
    activePrograms: Set<string>,
): IProgramInfo | null => {
    // If SEC + SBR + SBID, then show SBID
    if (
        activePrograms.has(ConsumerProgramName.SEC) &&
        activePrograms.has(ConsumerProgramName.SBR) &&
        activePrograms.has(ConsumerProgramName.SBID)
    ) {
        return getProgramInfo(ConsumerProgramName.SBID);
    }
    // If SEC + SBR, then show SBR
    if (
        activePrograms.has(ConsumerProgramName.SEC) &&
        activePrograms.has(ConsumerProgramName.SBR)
    ) {
        return getProgramInfo(ConsumerProgramName.SBR);
    }
    // If SEC + SBID, then show SBID
    if (
        activePrograms.has(ConsumerProgramName.SEC) &&
        activePrograms.has(ConsumerProgramName.SBID)
    ) {
        return getProgramInfo(ConsumerProgramName.SBID);
    }
    // If SEC, then show SEC
    if (activePrograms.has(ConsumerProgramName.SEC)) {
        return getProgramInfo(ConsumerProgramName.SEC);
    }
    // If anything exists, show it.
    if (activePrograms.size > 0) {
        return getProgramInfo(
            Array.from(activePrograms)[0] as ConsumerProgramName,
        );
    }
    // No programs to show.
    return null;
};

const ConsumerPrograms = (props: { activePrograms: string | string[] }) => {
    const programs = new Set(props.activePrograms);
    const program = getDisplayedProgram(programs);
    if (!program) {
        return null;
    }
    return (
        <span className="loc__active" key={program.name}>
            {program.name}
            <span
                data-tip={program.tooltipText}
                data-multiline={true}
                data-html={true}
            >
                <SVG
                    aria-hidden="true"
                    className="loc__question-mark-icon"
                    src={iconQuestionMarkCircleFilled}
                    title={t`Questions Icon`}
                />
            </span>
        </span>
    );
};

export const StoreRowLocation = (props: IStoreRowLocationProps) => {
    // Convert distance from meters to miles
    const distInMiles = isoMiles
        .unwrap(metersToMiles(props.store.distance))
        .toFixed();
    const phoneInUSFormat = formatPhoneNumber(props.store.phone);
    // Encode destination address for Directions link
    const destinationAddress = encodeURI(
        [
            props.store.name,
            props.store.address,
            props.store.address2,
            props.store.city,
            props.store.state,
            props.store.postal,
        ].join(" "),
    );
    // Monitor data layer events when clicking on store location details like directions, phone numbers.
    const onClickLocationInfo = (
        event: React.MouseEvent<HTMLElement>,
        ctaType: RetailStoreEventCTAType,
        textContent: string | null,
    ) => {
        event.stopPropagation();
        const element = event.currentTarget as HTMLElement;
        const linkText = textContent || element.textContent?.trim();
        const linkUrl = element.getAttribute("href");
        const eventType =
            props.store.data.classification_tempur === StoreCategory.FLAGSHIP
                ? RetailStoreEventType.TEMPUR_STORE
                : RetailStoreEventType.RETAIL_STORE;
        trackRetailStoreEvent(
            eventType,
            props.store,
            ctaType,
            linkText,
            linkUrl,
        );
    };
    return (
        <div className="loc">
            <div className="loc__info">
                <div className="loc__label">
                    {props.getStoreNumber(props.store)}.
                </div>
                <div className="loc__location">
                    <h4 className="loc__name al-store-locator__location-name">
                        <span className="loc__title">{props.store.name}</span>
                        <span className="loc__programs">
                            {props.store.elite && (
                                <span className="loc__elite">Elite</span>
                            )}
                            {props.store.featured && (
                                <span className="loc__flagship">Flagship</span>
                            )}
                            {!props.store.elite && !props.store.featured && (
                                <span className="loc__authorized">
                                    Authorized
                                </span>
                            )}
                        </span>
                    </h4>
                    <div className="loc__addr">
                        <span className="loc__addr1">
                            {props.store.address}{" "}
                        </span>
                        <span className="loc__addr2">
                            {props.store.address2}{" "}
                        </span>
                        <span className="loc__city">{props.store.city}, </span>
                        <span className="loc__state">{props.store.state} </span>
                        <span className="loc__postal">
                            {props.store.postal}
                        </span>
                    </div>
                    <div className="loc__programs">
                        {props.store.data.active_consumer_programs && (
                            <ConsumerPrograms
                                activePrograms={
                                    props.store.data.active_consumer_programs
                                }
                            />
                        )}
                        <ReactTooltip
                            className="quicksearch__tooltip"
                            event={"mouseover focus"}
                            eventOff="scroll mousewheel mouseleave mouseout blur"
                            role="tooltip"
                            isCapture={true}
                        />
                    </div>
                </div>
                <div className="loc__contact">
                    <span className="loc__dist">{distInMiles} miles</span>
                    <div className="loc__dir-info">
                        <a
                            className="loc__dir loc__id-80426"
                            href={`https://www.google.com/maps/dir/?api=1&destination=${destinationAddress}`}
                            target="_blank"
                            rel="noreferrer"
                            onClick={(event) => {
                                onClickLocationInfo(
                                    event,
                                    RetailStoreEventCTAType.MAP_DIRECTIONS,
                                    "Get icon directions",
                                );
                            }}
                        >
                            <img
                                src={iconIconDirectionBlue}
                                alt="Directions to this location"
                            />
                        </a>{" "}
                        {/*TODO: Fix this URL*/}
                        <a
                            className="loc__dir"
                            href={`/locations/states/${props.store.state}/cities/${props.store.city}/stores/${props.store.external_id}/`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={iconIconInfoBlue}
                                alt="Information about this location"
                            />
                        </a>
                    </div>
                </div>
                <div className="loc__phone">
                    <a
                        className="loc__phone-number"
                        href={`tel:+${props.store.phone}`}
                        onClick={(event) => {
                            onClickLocationInfo(
                                event,
                                RetailStoreEventCTAType.CALL,
                                null,
                            );
                        }}
                    >
                        {phoneInUSFormat}
                    </a>
                </div>
                {props.showWebsite && props.store.web && (
                    <Link
                        href={props.store.web}
                        target="_blank"
                        className="loc__website"
                        rel="nofollow noreferrer"
                    >
                        Website
                        <img src={openLinkIconBlue} alt="Website" />
                    </Link>
                )}
            </div>
        </div>
    );
};

interface IStoreSubRowProps {
    store: IRetailStoreWithDistance;
    selectedStoreID: ISyncStoreID | null;
    getStoreNumber: (store: IRetailStoreWithDistance) => number;
    onSelectStore: (
        storeID: ISyncStoreID,
        e: React.MouseEvent<HTMLElement>,
    ) => void;
}

const StoreSubRow = (props: IStoreSubRowProps) => {
    const classes = classNames({
        "locations__list-item": true,
        "list-focus": props.selectedStoreID === props.store.external_id,
    });
    return (
        <li
            className={classes}
            onClick={props.onSelectStore.bind(
                undefined,
                props.store.external_id,
            )}
        >
            <StoreRowLocation
                store={props.store}
                showWebsite={true}
                getStoreNumber={props.getStoreNumber}
            />
        </li>
    );
};

interface IStoreRowProps extends IStoreSubRowProps {
    nestedStores: IRetailStoreWithDistance[];
}

interface IStoreRowState {
    nestedStoresOpen: boolean;
}

export class LocatorNearestStoresRow extends React.PureComponent<
    IStoreRowProps,
    IStoreRowState
> {
    state: IStoreRowState = {
        nestedStoresOpen: false,
    };

    private readonly onToggleNested = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        this.setState((s) => {
            return {
                nestedStoresOpen: !s.nestedStoresOpen,
            };
        });
    };

    private buildNestedStoreList() {
        if (
            this.props.nestedStores.length <= 0 ||
            !this.state.nestedStoresOpen
        ) {
            return null;
        }
        return (
            <ul className="loc__nest">
                {this.props.nestedStores.map((nestedStore) => {
                    return (
                        <StoreSubRow
                            key={`${nestedStore.external_id}`}
                            store={nestedStore}
                            selectedStoreID={this.props.selectedStoreID}
                            getStoreNumber={this.props.getStoreNumber}
                            onSelectStore={this.props.onSelectStore}
                        />
                    );
                })}
            </ul>
        );
    }

    private buildNestedStoreHandle() {
        if (this.props.nestedStores.length <= 0) {
            return null;
        }
        const linkLabel = this.state.nestedStoresOpen
            ? t`Hide (${this.props.nestedStores.length}) other locations`
            : t`Show (${this.props.nestedStores.length}) other locations`;
        const classes = classNames({
            "loc__nest-trigger": true,
            "loc__nest-trigger--visible": this.state.nestedStoresOpen,
            "loc__nest-trigger--hidden": !this.state.nestedStoresOpen,
        });
        return (
            <div className="loc__nest-trigger-wrapper">
                <a
                    title="Other Locations"
                    className={classes}
                    onClick={this.onToggleNested}
                >
                    {linkLabel}
                    <span className="caret"></span>
                </a>
            </div>
        );
    }

    render() {
        const classes = classNames({
            "locations__list-item": true,
            "list-focus":
                this.props.selectedStoreID === this.props.store.external_id,
        });
        return (
            <li
                className={classes}
                onClick={this.props.onSelectStore.bind(
                    undefined,
                    this.props.store.external_id,
                )}
            >
                <StoreRowLocation
                    store={this.props.store}
                    showWebsite={true}
                    getStoreNumber={this.props.getStoreNumber}
                />
                {this.buildNestedStoreList()}
                {this.buildNestedStoreHandle()}
            </li>
        );
    }
}
