import classNames from "classnames";
import Flickity from "flickity-imagesloaded";
import React from "react";
import { t } from "ttag";

export interface ISlideshowCell {
    thumbnailURL: string;
    thumbnail: string;
    headline: string;
    descript: string;
    label: string;
    styling: string;
}

interface IProps {
    images: ISlideshowCell[];
}

interface IState {
    selectedIndex: number;
}

export class FlagshipSlideshow extends React.Component<IProps, IState> {
    private readonly slideshowCarousel = React.createRef<HTMLDivElement>();
    private slideshowFlkty: Flickity | null = null;

    state: IState = {
        selectedIndex: 0,
    };

    private readonly onSelectSlideNav = (
        slideNum: number,
        event: React.MouseEvent<HTMLElement>,
    ) => {
        if (event) {
            event.preventDefault();
        }
        if (this.slideshowFlkty) {
            this.slideshowFlkty.select(slideNum);
        }
    };

    componentDidMount() {
        const slideshowCarouselElem = this.slideshowCarousel.current;
        if (slideshowCarouselElem) {
            this.initFlickity(slideshowCarouselElem);
        }
    }

    private initFlickity(carousel: HTMLDivElement) {
        if (this.slideshowFlkty) {
            this.slideshowFlkty.destroy();
        }
        this.slideshowFlkty = new Flickity(carousel, {
            cellSelector: ".slideshow__cell",
            cellAlign: "left",
            contain: true,
            pageDots: false,
            prevNextButtons: false,
            wrapAround: true,
            setGallerySize: false,
            imagesLoaded: true,
        });
        if (this.slideshowFlkty) {
            this.slideshowFlkty.on("change", () => {
                this.setState({
                    selectedIndex: this.slideshowFlkty
                        ? this.slideshowFlkty.selectedIndex
                        : 0,
                });
            });
        }
    }

    private buildSlides() {
        const slides = this.props.images.map((img) => {
            const slideStyle = {
                backgroundImage: `url(${img.thumbnailURL})`,
            };
            const slideClasses = classNames({
                "slideshow__cell": true,
                "slideshow__cell--inverse": img.styling !== "",
            });
            return (
                <div key={img.thumbnailURL} className={slideClasses}>
                    <div className="slideshow__background" style={slideStyle}>
                        <div className="slideshow__content">
                            <div className="slideshow__copy">
                                <h3 className="slideshow__headline type_x1point5">
                                    {img.headline}
                                </h3>
                                <div
                                    className="slideshow__descript"
                                    dangerouslySetInnerHTML={{
                                        __html: img.descript,
                                    }}
                                ></div>
                            </div>
                            <div className="slideshow__copy-bg"></div>
                        </div>
                    </div>
                </div>
            );
        });
        return slides;
    }

    private buildSlidesNav() {
        const slidesNav = this.props.images.map((img, i) => {
            const slideNavClasses = classNames({
                "slideshow__thumb-nav-item": true,
                "slideshow__thumb-nav-item--selected":
                    i === this.state.selectedIndex,
            });
            return (
                <li key={img.thumbnailURL} className={slideNavClasses}>
                    <button
                        key={img.thumbnailURL}
                        onClick={this.onSelectSlideNav.bind(this, i)}
                        title={img.headline}
                    >
                        <img src={img.thumbnail} alt="" />
                        <span
                            className="slideshow__thumb-nav-label"
                            dangerouslySetInnerHTML={{ __html: img.label }}
                        ></span>
                    </button>
                </li>
            );
        });
        return slidesNav;
    }

    render() {
        return (
            <div
                className="slideshow slideshow--thumbs"
                ref={this.slideshowCarousel}
            >
                {this.buildSlides()}
                <div className="slideshow__thumb-nav u-list-unstyled">
                    <span className="ada-screenreader-only">
                        {t`Activating this element will cause content in the slideshow to be updated`}
                    </span>
                    <ul>{this.buildSlidesNav()}</ul>
                </div>
            </div>
        );
    }
}
