import React from "react";

import { IProduct } from "../../../models/catalogue.interfaces";
import { isoProductCategoryID } from "../../../models/nominals";
import { check } from "../../../models/utils";
import { strToBool } from "../../../utils/format";
import {
    AttributeValueGridFilter,
    BooleanAttributeGridFilter,
    GridFilterConfig,
    PriceRangeGridFilter,
    ProductClassGridFilter,
} from "../filters";
import { GridFilters, GridSorters } from "../models";
import {
    AttributeValueGridSorter,
    DateCreatedGridSorter,
    GridSorterConfig,
    PillowsPriceGridSorter,
    PriceGridSorter,
    RatingGridSorter,
    RatingNumberGridSorter,
} from "../sorters";
import { ProductGrid } from "./ProductGrid";
import { SOProductGrid } from "./SOProductGrid";

interface IProps {
    gridVariant: string;
    productCategoryID: string;
    filters: string;
    sorters: string;
    hideSort: string;
    disableHoverOverlay: string;
    buildExternalCallout?: (rootProduct: IProduct) => React.ReactNode;
}

export const buildFilters = (filterConfigJSON: string): GridFilterConfig[] => {
    const filterConfig = check(
        GridFilters.decode(JSON.parse(filterConfigJSON)),
    );
    return filterConfig.map((data): GridFilterConfig => {
        switch (data.type) {
            case "attribute_value_grid_filter":
                return new AttributeValueGridFilter(data.value);

            case "product_class_grid_filter":
                return new ProductClassGridFilter(data.value);

            case "price_range_grid_filter":
                return new PriceRangeGridFilter(data.value);

            case "boolean_attribute_grid_filter":
                return new BooleanAttributeGridFilter(data.value);
        }
    });
};

export const buildSorters = (sorterConfigJSON: string): GridSorterConfig[] => {
    const sorterConfig = check(
        GridSorters.decode(JSON.parse(sorterConfigJSON)),
    );
    return sorterConfig.map((data): GridSorterConfig => {
        switch (data.type) {
            case "attribute_value_grid_sorter":
                return new AttributeValueGridSorter(data.value);

            case "price_grid_sorter":
                return new PriceGridSorter(data.value);

            case "pillows_price_grid_sorter":
                return new PillowsPriceGridSorter(data.value);

            case "rating_grid_sorter":
                return new RatingGridSorter(data.value);

            case "rating_number_grid_sorter":
                return new RatingNumberGridSorter(data.value);

            case "date_created_grid_sorter":
                return new DateCreatedGridSorter(data.value);
        }
    });
};

export const StreamFieldProductGrid = (props: IProps) => {
    if (props.gridVariant === "sleep-outfitters") {
        return (
            <SOProductGrid
                gridVariant={props.gridVariant}
                productCategoryID={isoProductCategoryID.wrap(
                    parseInt(props.productCategoryID, 10),
                )}
                filters={buildFilters(props.filters)}
                sorters={buildSorters(props.sorters)}
                hideSort={strToBool(props.hideSort)}
                disableHoverOverlay={strToBool(props.disableHoverOverlay)}
                buildExternalCallout={props.buildExternalCallout}
            />
        );
    }
    return (
        <ProductGrid
            gridVariant={props.gridVariant}
            productCategoryID={isoProductCategoryID.wrap(
                parseInt(props.productCategoryID, 10),
            )}
            filters={buildFilters(props.filters)}
            sorters={buildSorters(props.sorters)}
            hideSort={strToBool(props.hideSort)}
            disableHoverOverlay={strToBool(props.disableHoverOverlay)}
            buildExternalCallout={props.buildExternalCallout}
        />
    );
};
