import { useEffect, useState } from "react";

import { useIsSSR } from "../utils/ssr";

interface IDimensions {
    width: number;
    height: number;
}

interface IBodyResizeEvent {
    type: "width" | "height" | "both";
    prevSize: IDimensions;
    newSize: IDimensions;
}

export type OnResizeHandler = (event: IBodyResizeEvent) => void;

export const WindowResizeSensor = (props: { onResize: OnResizeHandler }) => {
    const isSSR = useIsSSR();
    const [size, setSize] = useState({
        width: isSSR ? 0 : document.body.offsetWidth,
        height: isSSR ? 0 : document.body.offsetHeight,
    });

    const getChangeType = (
        prevSize: IDimensions,
        newSize: IDimensions,
    ): IBodyResizeEvent["type"] | null => {
        const changedWidth = prevSize.width !== newSize.width;
        const changedHeight = prevSize.height !== newSize.height;
        if (changedWidth && changedHeight) {
            return "both";
        }
        if (changedWidth) {
            return "width";
        }
        if (changedHeight) {
            return "height";
        }
        return null;
    };

    const trackResize = (): void => {
        const newSize: IDimensions = {
            width: document.body.offsetWidth,
            height: document.body.offsetHeight,
        };
        const changeType = getChangeType(size, newSize);
        if (changeType) {
            props.onResize({
                type: changeType,
                prevSize: size,
                newSize: newSize,
            });
        }
        setSize(newSize);
    };

    useEffect(() => {
        window.addEventListener("resize", trackResize);
        return () => {
            window.removeEventListener("resize", trackResize);
        };
    }, []);
    return null;
};
