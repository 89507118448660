import React from "react";
import { connect } from "react-redux";
import { t } from "ttag";

import { FormInput } from "../../../forms/FormInput";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { Dispatchers } from "../dispatchers";
import {
    ICustomerInformationState,
    IWriteReviewFormState,
} from "../reducers.interfaces";
import { getCustomerInformationState } from "../selectors";

interface IOwnProps {
    email?: string;
}

interface IReduxProps {
    userInputFormState: ICustomerInformationState;
    forms: { [formUUID: string]: IWriteReviewFormState };
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class MultiProductWriteReviewFormGlobalInputSectionComponent extends React.Component<
    IProps,
    IState
> {
    private readonly handleEmailChange = async (
        e: React.FormEvent<HTMLInputElement>,
    ) => {
        this.props.dispatchers.setCustomerInformationEmail(
            e.currentTarget.value,
        );
    };

    private readonly handleNameChange = async (
        e: React.FormEvent<HTMLInputElement>,
    ) => {
        this.props.dispatchers.setCustomerInformationName(
            e.currentTarget.value,
        );
    };

    private readonly handleLocationChange = async (
        e: React.FormEvent<HTMLInputElement>,
    ) => {
        this.props.dispatchers.setCustomerInformationLocation(
            e.currentTarget.value,
        );
    };

    private readonly getNameErrors = () => {
        const errForm = Object.entries(this.props.forms).find(
            ([_i, formState]) => {
                return formState.errors?.name && formState.errors.name.length;
            },
        );
        return errForm ? errForm[1].errors.name : [];
    };

    private readonly getLocationErrors = () => {
        const errForm = Object.entries(this.props.forms).find(
            ([_i, formState]) => {
                return (
                    formState.errors?.location &&
                    formState.errors.location.length
                );
            },
        );
        return errForm ? errForm[1].errors.location : [];
    };

    private readonly getEmailErrors = () => {
        const errForm = Object.entries(this.props.forms).find(
            ([_i, formState]) => {
                return (
                    formState.errors?.email_collection &&
                    formState.errors.email_collection.length
                );
            },
        );
        return errForm ? errForm[1].errors.email_collection : [];
    };
    render() {
        return (
            <div>
                <FormInput
                    type="text"
                    name="name"
                    id="name"
                    onChange={this.handleNameChange}
                    required={true}
                    label={t`Nickname`}
                    labelPlacement={"static"}
                    placeholder={t`e.g., Happy Sleeper`}
                    value={this.props.userInputFormState.name}
                    errors={this.getNameErrors()}
                    showErrorMessages={Boolean(this.getNameErrors().length)}
                />
                <FormInput
                    type="text"
                    name="location"
                    id="location"
                    onChange={this.handleLocationChange}
                    required={true}
                    label={t`Location`}
                    labelPlacement={"static"}
                    placeholder={t`e.g., Boston, MA`}
                    value={this.props.userInputFormState.location}
                    errors={this.getLocationErrors()}
                    showErrorMessages={Boolean(this.getLocationErrors().length)}
                />
                <FormInput
                    type="email"
                    name="email"
                    id="email"
                    onChange={this.handleEmailChange}
                    required={true}
                    label={t`Your email address`}
                    labelPlacement={"static"}
                    placeholder={t`e.g., jdoe@example.com`}
                    value={this.props.userInputFormState.email_collection}
                    errors={this.getEmailErrors()}
                    showErrorMessages={Boolean(this.getEmailErrors().length)}
                />
            </div>
        );
    }
}

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        ...ownProps,
        userInputFormState: getCustomerInformationState(rootState.reviews),
        forms: rootState.reviews.ui.writeReviewForms,
    };
};

export const MultiProductWriteReviewFormGlobalInputSection = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MultiProductWriteReviewFormGlobalInputSectionComponent);
