import React from "react";
import { connect } from "react-redux";

import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { Actions } from "../actions";
import { ICustomerDetails } from "../models.interfaces";
import { FormView } from "./abstract/FormView";

interface IOwnProps {
    customer: ICustomerDetails;
}

interface IReduxProps {}

interface IDispatchProps {
    actions: Actions;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

const CustomerDetailsEditComponent = (props: IProps) => {
    return (
        <FormView
            url={props.customer.url}
            baseURL={"/api/csr/users/"}
            actions={props.actions}
            fields={[
                {
                    label: "Username",
                    name: "username",
                    isRequired: true,
                    type: "text",
                },
                {
                    label: "First Name",
                    name: "first_name",
                    placeholder: "",
                    isRequired: false,
                    type: "text",
                },
                {
                    label: "Last Name",
                    name: "last_name",
                    placeholder: "",
                    isRequired: false,
                    type: "text",
                },
                {
                    label: "Email Address",
                    name: "email",
                    placeholder: "",
                    type: "email",
                },
            ]}
            initialValues={props.customer}
        />
    );
};

const mapStateToProps: TStateMapper<"csr", IReduxProps, IOwnProps> = (
    _rootState,
    ownProps,
) => {
    return {
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const actions = new Actions(dispatch);
    return {
        actions: actions,
    };
};

export const CustomerDetailsEdit = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CustomerDetailsEditComponent);
