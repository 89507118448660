import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";

import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import { IFilterOption } from "../models.interfaces";

import styles from "./GridFilter.module.scss";

interface IOwnProps {
    filterID: string;
    option: IFilterOption;
    onChange: (
        optionNamespace: string,
        filterID: string,
        optionID: string,
        isSelected: boolean,
    ) => void;
}

interface IReduxProps {
    optionNamespace: string;
    isSelected: boolean;
}

interface IDispatchProps {}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

export class GridFilterOptionComponent extends React.PureComponent<
    IProps,
    IState
> {
    render() {
        const optionClasses = classNames({
            [styles.filterOption]: true,
            [`product-grid-filter__option--${this.props.option.id}`]: true,
        });
        const inputID = `check-box-${this.props.option.id}`;
        return (
            <div className={optionClasses}>
                <input
                    id={inputID}
                    type="checkbox"
                    className="product-grid-filter__input al-product-grid__filter-input"
                    value={this.props.option.id}
                    checked={this.props.isSelected}
                    onChange={(e) => {
                        this.props.onChange(
                            this.props.optionNamespace,
                            this.props.filterID,
                            this.props.option.id,
                            e.currentTarget.checked,
                        );
                    }}
                />
                <label htmlFor={inputID}>
                    <span className={styles.filterOptionLabel}>
                        {this.props.option.label}
                    </span>
                </label>
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"productgrid2", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.productgrid2 || defaults;
    const filterState = state.filters[ownProps.filterID];
    const selectedValues = filterState ? filterState.selectedValues : null;
    return {
        ...ownProps,
        isSelected: selectedValues
            ? selectedValues.includes(ownProps.option.id)
            : false,
        optionNamespace: state.products[0]?.product_class_slug || "",
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (_dispatch) => {
    return {};
};

export const GridFilterOption = connect(
    mapStateToProps,
    mapDispatchToProps,
)(GridFilterOptionComponent);
