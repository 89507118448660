import React from "react";
import SVG from "react-inlinesvg";
import { t } from "ttag";

import lockIcon from "../../../../img/icons/lock-icon.svg";
import { FinancingPortalClose } from "../FinancingPortal";

import styles from "./index.module.scss";

export const FinancingModalHeader = (props: { title: string }) => {
    return (
        <header className={styles.root}>
            <div>
                <SVG
                    role="img"
                    aria-labelledby="credit-card-lock-icon"
                    aria-hidden="true"
                    className={styles.headerImage}
                    src={lockIcon}
                >
                    <title id="credit-card-lock-icon">
                        {t`Credit Card Application icon, displayed as a lock`}
                    </title>
                </SVG>
                <h1 className={styles.heading}>{props.title}</h1>
            </div>
            <FinancingPortalClose className={styles.button}>
                {t`Close`}
            </FinancingPortalClose>
        </header>
    );
};
