import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { snapUserDataLayerState } from "tsi-common-react/src/api/datalayer";
import { AccountMenu } from "tsi-common-react/src/apps/authentication/components/AccountMenu";
import { SessionKeepAlive } from "tsi-common-react/src/apps/authentication/components/SessionKeepAlive";
import { registerCascades } from "tsi-common-react/src/apps/common/cascades";
import { Dispatchers } from "tsi-common-react/src/apps/common/dispatchers";
import { Loaders } from "tsi-common-react/src/apps/common/loaders";
import { isoWebPageURL } from "tsi-common-react/src/models/nominals";
import { UGCListSubBlock } from "tsi-common-react/src/models/ugc";
import { readyStateComplete } from "tsi-common-react/src/utils/events";
import { strToBool } from "tsi-common-react/src/utils/format";
import {
    dynamicPlaceComponent,
    placeComponent,
} from "tsi-common-react/src/utils/react";
import { getPageSetting } from "tsi-common-react/src/utils/settings";
import { urls } from "tsi-common-react/src/utils/urls";

import { rehydratingStore, store } from "../store";
// These components aren't lazy loaded because they appear on every page
import { MainMenu } from "./components/MainMenu";
import { SearchNav } from "./components/SearchNav";
import { getMenuConfiguration } from "./menu";

interface IUGCGridData {
    title: string;
    desc: string;
    posts: UGCListSubBlock[];
    cta: number;
}

interface IUGCCarouselData {
    desc: string;
    posts: UGCListSubBlock[];
}

export const dispatchers = new Dispatchers(store.dispatch);
export const loaders = new Loaders(dispatchers);

// Register store cascades
registerCascades(store);

// Load data for main menu from Wagtail JSON
const menuItems = getMenuConfiguration();
if (menuItems) {
    placeComponent(
        '[data-place-react="main-menu-dropdown"]',
        <MainMenu menuItems={menuItems} />,
    );
}

// Render account menu
dynamicPlaceComponent('[data-place-react="auth-login-menu-link"]', async () => {
    return (
        <Provider store={store}>
            <AccountMenu />
        </Provider>
    );
});

// Place site search bar
const enableStoreSearch = strToBool(
    getPageSetting("retail-locator-display-enabled"),
);
placeComponent(
    '[data-place-react="search-nav"]',
    <Provider store={store}>
        <SearchNav enableStoreSearch={enableStoreSearch} />
    </Provider>,
);

// Render standalone login forms
dynamicPlaceComponent(
    '[data-place-react="auth-login-standalone"]',
    async (elem) => {
        const { LoginForm } = await import(
            "tsi-common-react/src/apps/authentication/components/LoginForm"
        );
        const nextURL = elem.dataset.continueUrl
            ? isoWebPageURL.wrap(elem.dataset.continueUrl)
            : undefined;
        return <LoginForm nextURL={nextURL} />;
    },
);

// Render Session Keep-Alive Modal
placeComponent(
    '[data-place-react="session-keep-alive-modal"]',
    <SessionKeepAlive />,
);

// Render Value Props bar (generally on the home page)
dynamicPlaceComponent('[data-place-react="value-props-bar"]', async (elem) => {
    const { ValuePropsBar } = await import(
        "tsi-common-react/src/common/ValuePropsBar"
    );
    const parsedData = JSON.parse(elem.dataset.cms || "");
    return <ValuePropsBar valueProps={parsedData.value_props} />;
});

// Render Tooltip for White Glow Delivery in Order Page
dynamicPlaceComponent('[data-place-react="tooltip-white-glove"]', async () => {
    const { TooltipWhiteGlove } = await import(
        "./components/TooltipWhiteGlove"
    );
    return <TooltipWhiteGlove />;
});

// Render Email Signup Form in Footer
dynamicPlaceComponent('[data-place-react="tempur-me-signup"]', async () => {
    const { TempurMeFooterSignup } = await import(
        "./components/TempurMeFooterSignup"
    );
    return <TempurMeFooterSignup />;
});

// Render Service Footer
dynamicPlaceComponent('[data-place-react="service-footer"]', async (elem) => {
    const { ServiceFooter } = await import(
        "tsi-common-react/src/apps/common/containers/ServiceFooter"
    );
    const snippets = JSON.parse(elem.dataset.snippets || "null");
    const interval = Number(elem.dataset.interval) || null;
    const loops = Number(elem.dataset.loops) || null;

    return (
        <Provider store={store}>
            <ServiceFooter
                snippets={snippets.snippets}
                interval={interval}
                loops={loops}
            />
        </Provider>
    );
});

// Place UGC Grid component
dynamicPlaceComponent('[data-place-react="ugc-grid"]', async (elem) => {
    const { UGCGrid } = await import("tsi-common-react/src/common/UGCGrid");
    const ugcGridData: IUGCGridData = JSON.parse(elem.dataset.posts || "");
    await readyStateComplete;
    return <UGCGrid tiles={ugcGridData.posts} />;
});

// Place UGC Carousel component
dynamicPlaceComponent('[data-place-react="ugc-carousel"]', async (elem) => {
    const { UGCCarousel } = await import(
        "tsi-common-react/src/common/UGCCarousel"
    );
    const ugcCarouselData: IUGCCarouselData = JSON.parse(
        elem.dataset.posts || "",
    );
    await readyStateComplete;
    return <UGCCarousel tiles={ugcCarouselData.posts} />;
});

// Place Live Chat Links
dynamicPlaceComponent('[data-place-react="chat-link"]', async (elem) => {
    const { ChatLink } = await import(
        "tsi-common-react/src/apps/chat/ChatLink"
    );
    return (
        <ChatLink
            className={elem.dataset.chatLinkClass}
            chatOnlineText={elem.dataset.chatOnlineText}
            chatOfflineText={elem.dataset.chatOfflineText}
            chatOfflineLink={
                elem.dataset.chatOfflineLink
                    ? isoWebPageURL.wrap(elem.dataset.chatOfflineLink)
                    : urls.pageURL("contact-link")
            }
        />
    );
});

dynamicPlaceComponent('[data-place-react="footer-widgets"]', async (elem) => {
    const { FooterWidgets } = await import(
        "tsi-common-react/src/apps/common/containers/FooterWidgets"
    );
    return (
        <Provider store={store}>
            <FooterWidgets
                phoneNumber={elem.dataset.phoneNumber || ""}
                showContactSupportWidget={strToBool(
                    elem.dataset.showContactSupport || "",
                )}
                showNearbyStoresWidget={strToBool(
                    elem.dataset.showNearbyStores || "",
                )}
            />
        </Provider>
    );
});

// Chat Card block
dynamicPlaceComponent('[data-place-react="chat-card"]', async () => {
    const { ChatCard } = await import("./components/ChatCard");
    return <ChatCard />;
});

// Render countdown timer
dynamicPlaceComponent('[data-place-react="countdown-timer"]', async (elem) => {
    const { CountdownTimer } = await import(
        "tsi-common-react/src/apps/countdown-timer/container/CountdownTimer"
    );
    const timerData = JSON.parse(elem.dataset.timers || "");
    const timersExpiredPromo = elem.dataset.promo || "";
    return (
        <CountdownTimer
            timerData={timerData}
            timersExpiredPromo={timersExpiredPromo}
        />
    );
});

// Render user data layer element
dynamicPlaceComponent('[data-place-react="user-data-layer"]', async (elem) => {
    await readyStateComplete;
    // Pause for 1 second to allow the data layer push scripts to run after readyState is complete
    await (async () => {
        return new Promise((res) => {
            setTimeout(res, 1000);
        });
    })();
    const orderID = elem.dataset.order || "";
    await snapUserDataLayerState(orderID);
    return null;
});

// Render the CSR toolbar
(async () => {
    // Wait for Redux store to finish loading
    await rehydratingStore;

    const user = await loaders.loadCurrentUser();
    if (!user || !user.is_csr) {
        return;
    }
    // Load the toolbar component
    const { Toolbar } = await import(
        "tsi-common-react/src/apps/csr/components/Toolbar"
    );

    // Create container elements
    const csrToolbarContainer = document.createElement("div");
    csrToolbarContainer.id = "oscarcsr-toolbar-reset";
    document.body.prepend(csrToolbarContainer);

    const csrToolbar = document.createElement("div");
    csrToolbar.id = "oscarcsr-toolbar";
    csrToolbarContainer.appendChild(csrToolbar);

    // Render the toolbar
    createRoot(csrToolbar).render(
        <Provider store={store}>
            <Toolbar
                dashboard={isoWebPageURL.wrap("/store/dashboard/")}
                basket={isoWebPageURL.wrap("/store/basket/")}
            />
        </Provider>,
    );
})();
