import React from "react";
import { RecommendedProducts } from "tsi-common-react/src/models/ugc";
import { check } from "tsi-common-react/src/models/utils";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";

dynamicPlaceComponent('[data-place-react="ugc-api-grid"]', async (elem) => {
    const { UGCApiGrid } = await import(
        "tsi-common-react/src/common/UGCApiGrid"
    );
    const pageSize = parseInt(elem.dataset.pageSize || "", 10);
    const random = elem.dataset.random !== undefined;
    return <UGCApiGrid pageSize={pageSize} random={random} />;
});

dynamicPlaceComponent(
    '[data-place-react="love-featured-products"]',
    async (elem) => {
        const { UGCRecommendations } = await import(
            "tsi-common-react/src/common/UGCRecommendations"
        );
        const recommendations = check(
            RecommendedProducts.decode(
                JSON.parse(elem.dataset.recommendations || "[]"),
            ),
        );
        return <UGCRecommendations recommendations={recommendations} />;
    },
);
