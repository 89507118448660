import { AdaChatConnector } from "tsi-common-react/src/apps/chat/connector-ada";
import { registerChatConnector } from "tsi-common-react/src/apps/chat/index";
import { strToBool } from "tsi-common-react/src/utils/format";
import { getPageSetting } from "tsi-common-react/src/utils/settings";

const chatEnabled = strToBool(getPageSetting("chat-enabled", "false"));
const hideChat = strToBool(getPageSetting("chat-hide-widget", "true"));
if (chatEnabled) {
    const chatConnector = new AdaChatConnector({
        settings: {
            handle: "tsi-tempurpedic",
        },
    });
    registerChatConnector(chatConnector);
    chatConnector.init({
        hideChat: hideChat,
    });
}
