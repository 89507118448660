import React from "react";
import { formatDistance } from "tsi-common-react/src/apps/retail/helpers";
import {
    RetailStoreEventCTAType,
    RetailStoreEventType,
} from "tsi-common-react/src/constants";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { trackRetailStoreEvent } from "tsi-common-react/src/utils/analytics";
import { formatPhoneNumber } from "tsi-common-react/src/utils/format";
import {
    getGoogleLatLng,
    loadGMaps,
    newMap,
} from "tsi-common-react/src/utils/maps";
import { t } from "ttag";

import iconClock from "../../img/retail-locator/icon-clock.png";
import iconDriving from "../../img/retail-locator/icon-driving.png";
import iconMarker2 from "../../img/retail-locator/icon-marker2.png";
import iconPhone from "../../img/retail-locator/icon-phone.png";
import locationIcon from "../../img/retail-locator/location-icon.png";
import { ICMSFlagshipStorePage } from "../models.interfaces";

interface IProps {
    mapMountElement: HTMLElement;
    cmsProps: ICMSFlagshipStorePage;
    store: IRetailStoreWithDistance;
}

interface IState {}

export class FlagshipStoreInfoMap extends React.Component<IProps, IState> {
    componentDidMount() {
        this.renderMap();
    }

    private async renderMap() {
        // Load Google Maps API
        await loadGMaps();
        const position = getGoogleLatLng(this.props.store);
        const retailMap = await newMap(this.props.mapMountElement, {
            center: position,
            zoom: 16,
            scrollwheel: false,
            draggable: true,
            disableDefaultUI: false,
            clickableIcons: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
        });
        const marker = new google.maps.Marker({
            position: position,
            icon: iconMarker2,
            title: "Tempur-Pedic Store",
        });
        marker.setMap(retailMap);
    }

    // Monitor data layer events when clicking on store location details like directions, phone numbers.
    private onClickLocationInfo(
        ctaType: RetailStoreEventCTAType,
        textContent: string | null,
        event: React.MouseEvent<HTMLElement>,
    ) {
        event.stopPropagation();
        const element = event.currentTarget as HTMLElement;
        const linkText = textContent || element.textContent?.trim();
        const linkUrl = element.getAttribute("href");
        trackRetailStoreEvent(
            RetailStoreEventType.TEMPUR_STORE,
            this.props.store,
            ctaType,
            linkText,
            linkUrl,
        );
    }

    render() {
        // Convert distance from meters to miles
        const phoneInUSFormat = formatPhoneNumber(this.props.store.phone);
        // encode destination address for Directions link
        const destinationAddress = encodeURI(
            this.props.store.name +
                " " +
                this.props.store.address +
                " " +
                this.props.store.address2 +
                " " +
                this.props.store.city +
                "," +
                this.props.store.state +
                " " +
                this.props.store.postal,
        );

        return (
            <>
                <div className="location__distance">
                    <div className="location__distance-text">
                        <span className="location__distance-text--large">
                            {formatDistance(this.props.store.distance)}
                        </span>
                        <br />
                        <span className="location__distance-text--small">
                            {t`miles`}
                        </span>
                    </div>
                </div>
                <div className="location__info">
                    <div className="location__header">
                        <div className="location__name1">
                            {this.props.cmsProps.title}
                        </div>
                        <div className="location__name2">
                            {this.props.cmsProps.shopping_district}
                        </div>
                    </div>
                    <div className="location__location">
                        <span className="location__icon">
                            <img alt={t`Location`} src={locationIcon} />
                        </span>
                        <div className="location__address">
                            <div className="location__address">
                                {this.props.cmsProps.address}
                                <br />
                                {this.props.cmsProps.city},{" "}
                                {this.props.cmsProps.state}{" "}
                                {this.props.cmsProps.postal}
                            </div>
                        </div>
                        <div className="location__nearby">
                            {this.props.cmsProps.located_near}
                        </div>
                    </div>
                    <div className="location__contact">
                        <div className="location__item location__hours">
                            <span className="location__icon">
                                <img alt={t`Hours`} src={iconClock} />
                            </span>
                            <span className="location__tooltip-trigger">
                                {this.props.cmsProps.hours_summary_abbr
                                    .split(",")
                                    .map((hour, index) => (
                                        <span key={`key-${index}`}>{hour}</span>
                                    ))}
                            </span>
                        </div>
                        <div className="location__item location__directions">
                            <span className="location__icon">
                                <img alt={t`Directions`} src={iconDriving} />
                            </span>
                            <a
                                href={`https://www.google.com/maps/dir/?api=1&destination=${destinationAddress}`}
                                target="_blank"
                                rel="noreferrer"
                                onClick={this.onClickLocationInfo.bind(
                                    this,
                                    RetailStoreEventCTAType.MAP_DIRECTIONS,
                                    "Get icon directions",
                                )}
                            >
                                {t`Directions`}
                            </a>
                        </div>
                        <div className="location__item location__phone">
                            <span className="location__icon">
                                <img alt={t`Phone Number`} src={iconPhone} />
                            </span>
                            <a
                                href={`tel:${this.props.store.phone}`}
                                title={t`Phone Number`}
                                onClick={this.onClickLocationInfo.bind(
                                    this,
                                    RetailStoreEventCTAType.CALL,
                                    null,
                                )}
                            >
                                {phoneInUSFormat}
                            </a>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
