import React from "react";
import { t } from "ttag";

import { Trans } from "../../../common/Trans";
import { strings } from "../../../localization";
import { useAppDispatch } from "../../reducers";
import { closeModal, openApplyModal } from "../reducers";

import styles from "./index.module.scss";

export const FinancingPreQualDenied = () => {
    const dispatch = useAppDispatch();
    const cardName = strings.get("FINANCING_CARD_NAME");
    return (
        <div className={styles.root}>
            <h2 className={styles.heading}>{t`We’re Sorry.`}</h2>
            <p className={styles.copy}>
                <Trans
                    fmtString={t`We did not find a pre-qualified offer for you at this time. However, you can still apply for the ${cardName} by selecting <B>Continue to Application</B>.`}
                    data={{
                        SUP: (content) => <sup key={1}>{content}</sup>,
                        B: (content) => <strong key={2}>{content}</strong>,
                    }}
                />
            </p>
            <div className={styles.buttonContainer}>
                <button
                    className="button"
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(openApplyModal({}));
                    }}
                >
                    {t`Continue to Application`}
                </button>
                <button
                    className="button button--secondary"
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(closeModal());
                    }}
                >
                    {t`No, Thanks`}
                </button>
            </div>
        </div>
    );
};
