import React from "react";

import { strings } from "../../../localization";

export const ConsentDisclaimer = () => (
    <span
        className="financing-form__disclosure-text"
        dangerouslySetInnerHTML={{
            __html: strings.get("FINANCING_CONSENT_DISCLAIMER") || "",
        }}
    ></span>
);
