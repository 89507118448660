import memoize from "memoize-one";
import React from "react";
import { connect } from "react-redux";
import { t } from "ttag";

import { LoadingSpinner } from "../../../common/LoadingSpinner";
import { strings } from "../../../localization";
import { IAddressCountry } from "../../../models/address.interfaces";
import { CompleteOrderPaymentInitData } from "../../../models/checkout";
import { IUser } from "../../../models/user.interfaces";
import { check } from "../../../models/utils";
import { Dispatchers as CommonDispatchers } from "../../common/dispatchers";
import { Loaders as CommonLoaders } from "../../common/loaders";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { Actions, ICheckoutHTTPError } from "../actions";
import { BillingAddressSummary } from "../checkout-steps/BillingAddressSummary";
import { CheckoutStepContainer } from "../checkout-steps/CheckoutStepContainer";
import { EmailAddressSummary } from "../checkout-steps/EmailAddressSummary";
import { PaymentMethods } from "../checkout-steps/PaymentMethods";
import { ShippingAddressSummary } from "../checkout-steps/ShippingAddressSummary";
import { CSRHeader } from "../components/CSRHeader";
import { OrderSummary } from "../components/OrderSummary";
import { PaymentChildFrameListener } from "../components/PaymentChildFrameListener";
import { Step } from "../constants";
import { defaults } from "../defaults";
import { Dispatchers } from "../dispatchers";
import { Loaders } from "../loaders";
import { IReduxState } from "../reducers.interfaces";
import {
    IErrorMsgTemplateContext,
    filterFinancingPlans,
    renderErrorMessageTemplate,
    sortFinancingPlans,
} from "../utils";

interface IStepErrors {
    global?: string[];
    payment_method?: string[];
}

interface IOwnProps {
    initDataJSON: string;
    buildFinancingUpsell: (grandTotal: string) => JSX.Element | null;
    enableSplitPay?: boolean;
    disableFreeShippingMessage?: boolean;
}

interface IReduxProps extends IReduxState {
    user: IUser | null;
}

interface IDispatchProps {
    commonLoaders: CommonLoaders;
    loaders: Loaders;
    dispatchers: Dispatchers;
    actions: Actions;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    isLoading: boolean;
    messages: IStepErrors;
}

class CompleteDeferredPaymentComponent extends React.Component<IProps, IState> {
    public MOBILE_WIDTH_THRESHOLD = 769;

    public state: IState = {
        isLoading: true,
        messages: {},
    };

    private readonly parseInitData = memoize((initDataJSON: string) => {
        return check(
            CompleteOrderPaymentInitData.decode(JSON.parse(initDataJSON)),
        );
    });

    private get initData() {
        return this.parseInitData(this.props.initDataJSON);
    }

    async componentDidMount() {
        // Load all the data necessary to begin checkout
        type PreLoadData = [
            Promise<IUser>,
            Promise<IAddressCountry[]>,
            Promise<string[]>,
        ];
        const loading: PreLoadData = [
            this.props.commonLoaders.loadCurrentUser(),
            this.props.loaders.loadCountries(),
            this.props.loaders.loadPaymentMethods(),
        ];
        const [user] = await Promise.all(loading);

        // Load assisted user if the active user is a CSR
        if (user.is_csr) {
            try {
                await this.props.loaders.loadAssistedUser();
            } catch (e) {
                console.warn(e);
            }
        }

        // Load the email address associated with the basket. Has to be deliberately
        // after the call to loadAssistedUser to prevent a race-condition.
        await Promise.all([
            this.props.loaders.loadEmailAddress(),
            this.props.loaders.loadUserAddresses(),
        ]);

        const sortedPlans = sortFinancingPlans(
            filterFinancingPlans(this.initData.financing_plans),
        );
        this.props.dispatchers.setFinancingPlans(sortedPlans);

        this.props.loaders.importShippingAddr(this.initData.shipping_address);
        this.props.loaders.importBillingAddr(this.initData.billing_address);

        // Load the order data into Redux
        this.props.dispatchers.beginCompleteDeferredPayment(
            this.initData.order,
        );
        // Jump to the payment step
        this.props.dispatchers.changeFormField({
            current_step: Step.PAYMENT_METHODS,
        });

        // Loading is complete
        this.setState({
            isLoading: false,
        });
    }

    private readonly onCheckoutError = (err: ICheckoutHTTPError) => {
        console.error(err);

        const errors = (err && err.response ? err.response.body : {}) || {};
        const messages: IStepErrors = {};
        const ctx: IErrorMsgTemplateContext = {
            orderID: this.initData.order.number,
        };
        if (errors.global) {
            this.props.dispatchers.updatePaymentError(errors.global[0]);
        } else if (errors.payment) {
            messages.payment_method = [
                renderErrorMessageTemplate(
                    strings.get("CHECKOUT_ERROR_PAYMENT_METHOD") || "",
                    ctx,
                ),
            ].concat(errors.payment);
        } else {
            messages.payment_method = [
                renderErrorMessageTemplate(
                    strings.get("CHECKOUT_ERROR_FALLBACK") || "",
                    ctx,
                ),
            ];
        }
        this.setState({ messages: messages });
    };

    private readonly onPlaceOrder = () => {
        this.props.actions
            .completeDeferredPayment(
                this.props.user,
                this.initData.basket.token,
                this.initData.order.token,
                this.props,
            )
            .catch((err) => {
                this.onCheckoutError(err);
            });
    };

    private renderStepLogin() {
        // Only display the email step if the user already has an email address. Otherwise,
        // it becomes part of the shipping address step.
        let emailStep: JSX.Element | null = null;
        if (
            this.props.user &&
            this.props.user.email &&
            !this.props.user.is_csr
        ) {
            emailStep = (
                <CheckoutStepContainer
                    key={Step.LOGIN}
                    className="checkout-step--email checkout-step--first"
                    heading={t`Email Address`}
                >
                    <EmailAddressSummary user={this.props.user} />
                </CheckoutStepContainer>
            );
        }
        return emailStep;
    }

    private renderStepShippingAddress(idx: number) {
        return (
            <CheckoutStepContainer
                key={Step.SHIPPING_ADDRESS}
                className="checkout-step--shipping-address form"
                heading={`${idx}. ${strings.get(
                    "CHECKOUT_SECTION_TITLE_SHIPPING_ADDRESS",
                )}`}
            >
                <ShippingAddressSummary />
            </CheckoutStepContainer>
        );
    }

    private renderStepBillingAddress(idx: number) {
        return (
            <CheckoutStepContainer
                key={Step.BILLING_ADDRESS}
                className="checkout-step--billing-address form"
                heading={`${idx}. ${strings.get(
                    "CHECKOUT_SECTION_TITLE_BILLING_ADDRESS",
                )}`}
            >
                <BillingAddressSummary />
            </CheckoutStepContainer>
        );
    }

    private renderStepPaymentMethod(idx: number) {
        const isCSR = this.props.user && this.props.user.is_csr;
        return (
            <PaymentMethods
                key={Step.PAYMENT_METHODS}
                heading={`${idx}. ${strings.get(
                    "CHECKOUT_SECTION_TITLE_PAYMENT_METHODS",
                )}`}
                errors={this.state.messages.payment_method || []}
                buildFinancingUpsell={this.props.buildFinancingUpsell}
                enableSplitPay={this.props.enableSplitPay ? true : false}
                isCSR={!!isCSR}
                onContinue={this.onPlaceOrder}
            />
        );
    }

    private renderStep(idx: number, step: Step) {
        switch (step) {
            case Step.LOGIN:
                return this.renderStepLogin();
            case Step.SHIPPING_ADDRESS:
                return this.renderStepShippingAddress(idx);
            case Step.BILLING_ADDRESS:
                return this.renderStepBillingAddress(idx);
            case Step.PAYMENT_METHODS:
                return this.renderStepPaymentMethod(idx);
            case Step.PLACING_ORDER:
            default:
                return null;
        }
        return null;
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="checkout__react-container">
                    <LoadingSpinner />
                </div>
            );
        }
        const formSteps = [
            Step.LOGIN,
            Step.SHIPPING_ADDRESS,
            Step.BILLING_ADDRESS,
            Step.PAYMENT_METHODS,
            Step.PLACING_ORDER,
        ];
        return (
            <div className="checkout__react-container">
                <div className="checkout__column--left">
                    <CSRHeader user={this.props.user} />
                    {formSteps.map((step, idx) => this.renderStep(idx, step))}
                </div>
                <div className="checkout__column--right basket-summary">
                    <h2 className="basket-summary__header">
                        {t`Order Summary`}
                    </h2>
                    <OrderSummary
                        order={this.initData.order}
                        disableFreeShippingMessage={
                            this.props.disableFreeShippingMessage
                        }
                    />
                </div>
                <PaymentChildFrameListener
                    onCheckoutError={this.onCheckoutError}
                    hasPaymentMethodError={!!this.state.messages.payment_method}
                />
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.checkout || defaults;
    return {
        user: rootState.common.user,
        ...state,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const commonDispatchers = new CommonDispatchers(dispatch);
    const commonLoaders = new CommonLoaders(commonDispatchers);
    const dispatchers = new Dispatchers(dispatch);
    const loaders = new Loaders(dispatchers);
    const actions = new Actions(loaders, dispatchers);
    return {
        commonLoaders: commonLoaders,
        dispatchers: dispatchers,
        loaders: loaders,
        actions: actions,
    };
};

export const CompleteDeferredPayment = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CompleteDeferredPaymentComponent);
