import React from "react";
import { connect } from "react-redux";

import { loadCategory } from "../../../api/products";
import { LoadingSpinner } from "../../../common/LoadingSpinner";
import { IProduct } from "../../../models/catalogue.interfaces";
import { IProductCategoryID } from "../../../models/nominals";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { FiltersBar } from "../elements/FiltersBar";
import { GridContent } from "../elements/GridContent";
import { GridFilterConfig } from "../filters";
import { startHistoryListener } from "../history";
import {
    Action,
    IActionProductsLoadingDone,
    IActionProductsLoadingStarted,
} from "../reducers.interfaces";
import { GridSorterConfig } from "../sorters";

interface IOwnProps {
    gridVariant: string;
    productCategoryID: IProductCategoryID;
    filters: GridFilterConfig[];
    sorters?: GridSorterConfig[];
    hideSort?: boolean;
    disableHoverOverlay?: boolean;
    buildExternalCallout?: (rootProduct: IProduct) => React.ReactNode;
}

interface IReduxProps {
    isLoading: boolean;
}

interface IDispatchProps {
    onDidMount: () => void;
    onProductsLoadingStarted: () => void;
    onProductsLoadingDone: (products: IProduct[]) => void;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

export class SOProductGridComponent extends React.PureComponent<
    IProps,
    IState
> {
    componentDidMount() {
        this.props.onDidMount();
        this.loadProducts(this.props.productCategoryID);
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.productCategoryID !== this.props.productCategoryID) {
            this.loadProducts(this.props.productCategoryID);
        }
    }

    private async loadProducts(productCategoryID: IProductCategoryID) {
        this.props.onProductsLoadingStarted();
        const products = await loadCategory(productCategoryID);
        this.props.onProductsLoadingDone(products);
    }

    render() {
        let content: React.ReactNode | null = null;
        if (this.props.isLoading) {
            content = <LoadingSpinner />;
        } else {
            content = (
                <>
                    <FiltersBar
                        filters={this.props.filters}
                        sorters={this.props.sorters}
                    />
                    <GridContent
                        gridVariant={this.props.gridVariant}
                        cardType="grid-card"
                        filters={this.props.filters}
                        sorters={this.props.sorters}
                        disableHoverOverlay={this.props.disableHoverOverlay}
                        buildExternalCallout={this.props.buildExternalCallout}
                    />
                </>
            );
        }
        return (
            <>
                <div>{content}</div>
            </>
        );
    }
}

const mapStateToProps: TStateMapper<"productgrid2", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        ...ownProps,
        isLoading: rootState.productgrid2?.isLoading,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    return {
        onDidMount: () => {
            startHistoryListener(dispatch);
        },
        onProductsLoadingStarted: () => {
            dispatch<IActionProductsLoadingStarted>({
                type: Action.PRODUCTS_LOADING_STARTED,
            });
        },
        onProductsLoadingDone: (products) => {
            dispatch<IActionProductsLoadingDone>({
                type: Action.PRODUCTS_LOADING_DONE,
                payload: products,
            });
        },
    };
};

export const SOProductGrid = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SOProductGridComponent);
