import classNames from "classnames";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
import React from "react";

import playButtonCircle from "../../img/misc/play-button-circle.png";
import { UGCListSubBlock } from "../models/ugc";
import { focusElement } from "../utils/keyboardFocus";
import { Image } from "./Image";
import { SocialIcon } from "./SocialIcon";
import UGCGallery from "./UGCGallery";

interface IProps {
    tiles?: UGCListSubBlock[];
}

interface IState {
    isMobile: boolean;
    showGallery: boolean;
    selectedSlide: number;
    selectedTileElem: HTMLElement | null;
}

export class UGCGrid extends React.PureComponent<IProps, IState> {
    public MOBILE_WIDTH_THRESHOLD = 769;

    public state: IState = {
        isMobile: false,
        showGallery: false,
        selectedSlide: 1,
        selectedTileElem: null,
    };

    private readonly onGalleryClose = () => {
        this.setState({
            showGallery: false,
        });

        if (this.state.selectedTileElem) {
            const tileButtonElem = this.state.selectedTileElem
                .firstElementChild as HTMLElement;
            focusElement(tileButtonElem);
        }
    };

    private readonly checkMobileState = () => {
        const mobile: boolean = this.isMobileWidth() ? true : false;

        if (!this.isMobileWidth()) {
            this.removeTileMobileClass();
        }

        this.setState({
            isMobile: mobile,
        });
    };

    componentDidMount() {
        this.checkMobileState();
        window.addEventListener("resize", this.checkMobileState);
    }

    componentDidUpdate(prevPros: IProps) {
        // focus first element in new tiles
        if (prevPros.tiles && prevPros.tiles.length) {
            const elem = document.querySelectorAll<HTMLElement>(
                `.ugc-grid-tile__hover`,
            )[prevPros.tiles.length];
            focusElement(elem);
        }
    }

    private isMobileWidth() {
        return window.innerWidth < this.MOBILE_WIDTH_THRESHOLD;
    }

    private removeTileMobileClass() {
        const elems = document.querySelectorAll(
            ".ugc-grid-tile__mobile-clicked",
        );

        [].forEach.call(elems, (el: HTMLElement) => {
            el.classList.remove("ugc-grid-tile__mobile-clicked");
        });
    }

    private onTileClick(
        event: React.MouseEvent<HTMLElement>,
        tileIndex: number,
    ) {
        const targetTile = event.currentTarget as HTMLDivElement;
        if (this.state.isMobile) {
            const isClicked = targetTile.classList.contains(
                "ugc-grid-tile__mobile-clicked",
            );
            if (!isClicked) {
                this.removeTileMobileClass();
                targetTile.classList.add("ugc-grid-tile__mobile-clicked");
            } else {
                this.showGallery(tileIndex, targetTile);
                targetTile.classList.remove("ugc-grid-tile__mobile-clicked");
            }
        } else {
            this.showGallery(tileIndex, targetTile);
        }
    }

    private showGallery(tileIndex: number, targetTileElem: HTMLElement) {
        this.setState({
            showGallery: true,
            selectedSlide: tileIndex,
            selectedTileElem: targetTileElem,
        });
    }

    private buildSocialIcon(type: string, isHover: boolean) {
        const tileIconClasses = classNames({
            "ugc-grid-tile__icon": true,
            "ugc-grid-tile__icon--hover": isHover,
        });
        return <SocialIcon type={type} className={tileIconClasses} />;
    }

    private buildTiles() {
        if (!this.props.tiles) {
            return null;
        }

        return this.props.tiles.map((tile, key) => {
            if (!tile.post) {
                return null;
            }
            const onTileClick = (event: React.MouseEvent<HTMLElement>) => {
                this.onTileClick(event, key);
            };

            const ctaText =
                tile.post?.post_type === "video" ? "View video" : "View post";
            const ctaClasses = classNames({
                "ugc-grid-tile__cta": true,
                "al-ugc-grid__view-post": !(tile.post.post_type === "video"),
                "al-ugc-grid__view-video": tile.post.post_type === "video",
            });
            const tileBaseClasses = classNames({
                "lazyload": true,
                "ugc-grid-tile__base": true,
                "ugc-grid-tile__base--text":
                    tile.post.post_type === "text-only" && !tile.post.avatar,
                "ugc-grid-tile__base--avatar":
                    tile.post.post_type === "text-only" && tile.post.avatar,
                "ugc-grid-tile__base--photo": tile.post.post_type === "image",
                "ugc-grid-tile__base--video": tile.post.post_type === "video",
            });
            const tileHoverClasses = classNames({
                "ugc-grid-tile__hover": true,
                "ugc-grid-tile__hover--text": !tile.post.avatar,
                "ugc-grid-tile__hover--avatar": tile.post.avatar,
            });

            const isTilePhotoOrVideo =
                (tile.post.post_type === "image" ||
                    tile.post.post_type === "video") &&
                tile.post.photo;
            const playButtonImage = playButtonCircle;

            const tileCTA = tile.post.view_post ? (
                <a title={ctaText} className={ctaClasses} href={`#post-${key}`}>
                    {ctaText}
                </a>
            ) : null;

            const tileBase = isTilePhotoOrVideo ? (
                <div
                    aria-label={tile.post.photo_title || ""}
                    className={tileBaseClasses}
                    data-bg={tile.post.photo}
                >
                    {tile.post.post_type === "video" && (
                        <Image
                            src={playButtonImage}
                            className="ugc-grid-tile__video-icon"
                            alt="play video"
                        />
                    )}
                </div>
            ) : (
                <div className={tileBaseClasses}>
                    {tile.post.avatar && (
                        <Image
                            className="ugc-grid-tile__avatar"
                            src={tile.post.avatar}
                            alt={tile.post.avatar_title}
                        />
                    )}
                    <div className="ugc-grid-tile__copy">{tile.post.copy}</div>
                    <div className="ugc-grid-tile__attribution">
                        <span className="ugc-grid-tile__icon-container">
                            {this.buildSocialIcon(
                                tile.post.social_media,
                                false,
                            )}
                        </span>
                        <span className="ugc-grid-tile__username">
                            {tile.post.username}
                        </span>
                    </div>
                    {tileCTA}
                </div>
            );
            const tileHover = (
                <button
                    className={tileHoverClasses}
                    title={`${ctaText} ${tile.post.avatar_title}`}
                >
                    {tile.post.avatar && (
                        <Image
                            className="ugc-grid-tile__avatar"
                            src={tile.post.avatar}
                            alt={tile.post.avatar_title}
                        />
                    )}
                    <div className="ugc-grid-tile__copy">
                        {tile.post.pull_quote}
                    </div>
                    <div className="ugc-grid-tile__attribution">
                        <span className="ugc-grid-tile__icon-container">
                            {this.buildSocialIcon(tile.post.social_media, true)}
                        </span>
                        <span className="ugc-grid-tile__username">
                            {tile.post.username}
                        </span>
                    </div>
                    {tile.post.view_post && (
                        <span className={ctaClasses}>{ctaText}</span>
                    )}
                </button>
            );

            return (
                <div
                    className="ugc-grid-tile al-ugc-grid-tile"
                    onClick={onTileClick}
                    key={key}
                    role="group"
                    aria-label="Tempur-Pedic on Instagram"
                >
                    {tileHover}
                    {tileBase}
                </div>
            );
        });
    }

    render() {
        return (
            <span>
                <div className="ugc-grid__container u-flex-container al-ugc-grid-container">
                    {this.buildTiles()}
                </div>
                {this.state.showGallery && this.props.tiles && (
                    <UGCGallery
                        tiles={this.props.tiles}
                        initialSlide={this.state.selectedSlide}
                        onCloseGallery={this.onGalleryClose}
                    />
                )}
            </span>
        );
    }
}
