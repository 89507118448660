import classNames from "classnames";
import React from "react";
import SVG from "react-inlinesvg";

// Icons
import iconMenuBodyType from "../../../../img/icons/mattress-selector/menu-body-type.svg";
import iconMenuBudget from "../../../../img/icons/mattress-selector/menu-budget.svg";
import iconMenuCool from "../../../../img/icons/mattress-selector/menu-cooling.svg";
import iconMenuFeel from "../../../../img/icons/mattress-selector/menu-feel.svg";
import iconMenuResults from "../../../../img/icons/mattress-selector/menu-results.svg";
import { slugify } from "../../../utils/text";
import { useAppDispatch, useAppSelector } from "../../reducers";
import { Step, stepOrder } from "../constants";
import { defaults } from "../defaults";
import { onNavigate } from "../reducers";

interface IProps {
    step: Step;
    styles: string;
}

const icons = {
    "menu-body-type": iconMenuBodyType,
    "menu-budget": iconMenuBudget,
    "menu-cooling": iconMenuCool,
    "menu-feel": iconMenuFeel,
    "menu-results": iconMenuResults,
} as const;

const iconExists = (step: string): step is keyof typeof icons => {
    return Object.prototype.hasOwnProperty.call(icons, step);
};

const getIcon = (trackerStep: string): string | null => {
    const step = trackerStep.toLowerCase().replace(/\s+/g, "-");
    return iconExists(step) ? icons[step] : null;
};

export const NavButton = ({ step, styles }: IProps) => {
    const { selections, currentScreen } = useAppSelector(
        (state) => state.mattressmatch.ui,
    );
    const dispatch = useAppDispatch();
    const isComplete = (currentStep: Step) => {
        switch (currentStep) {
            case Step.SIZE:
                return true;
            case Step.FEEL:
                return selections.selectedFirmness !== 0;
            case Step.BODY_TYPE:
                return (
                    !!selections.selectedHeight && !!selections.selectedWeight
                );
            case Step.COOLING:
                return (
                    selections.selectedCoolness !==
                    defaults.ui.selections.selectedCoolness
                );
            case Step.BUDGET:
                return (
                    selections.selectedBudget !==
                    defaults.ui.selections.selectedBudget
                );
            case Step.RESULTS:
                return false;
        }
    };

    if (step === Step.SIZE) {
        return null;
    }

    const stepIdx = stepOrder.indexOf(step);
    const isDisabled =
        (stepIdx !== currentScreen && !isComplete(step)) ||
        stepIdx > currentScreen;
    const icon = getIcon(`menu-${slugify(step)}`);

    const buttonClasses = classNames({
        [styles]: true,
        completed: isComplete(step) && currentScreen > stepIdx,
        active: stepIdx === currentScreen,
    });

    return (
        <button
            key={step}
            onClick={() => {
                dispatch(
                    onNavigate({
                        currentScreen: stepOrder.indexOf(step),
                    }),
                );
            }}
            className={buttonClasses}
            data-key={step}
            disabled={isDisabled}
        >
            {icon && <SVG aria-hidden="true" src={icon} title={step} />}
            <span>{step}</span>
        </button>
    );
};
