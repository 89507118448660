import memoize from "memoize-one";
import React from "react";
import { connect } from "react-redux";

import { RichText } from "../../../common/RichText";
import { IOptionValues } from "../../../models/catalogue.interfaces";
import { check } from "../../../models/utils";
import { TStateMapper } from "../../reducers.interfaces";
import { TabFeatureAttributeBlockAttributeTabButton } from "../elements/TabFeatureAttributeBlockAttributeTabButton";
import { TabFeatureAttributeBlockAttributeTabContent } from "../elements/TabFeatureAttributeBlockAttributeTabContent";
import { TabFeatureAttributeBlock as TabFeatureAttributeBlockCodec } from "../models";
import { ITabFeatureAttributeBlock } from "../models.interfaces";
import { getOptionValueByIdx } from "../utils";

import styles from "./TabFeatureAttributeBlock.module.scss";

interface IOwnProps {
    blockJSON: string;
}

interface IReduxProps {
    optionValues: IOptionValues;
}

type IProps = IOwnProps & IReduxProps;

interface IState {
    selectedAttrValue: string | null;
}

class TabFeatureAttributeBlockComponent extends React.Component<
    IProps,
    IState
> {
    state: IState = {
        selectedAttrValue: null,
    };

    private readonly parseBlockData = memoize(
        (blockJSON: string): ITabFeatureAttributeBlock => {
            const block = check(
                TabFeatureAttributeBlockCodec.decode(JSON.parse(blockJSON)),
            );
            return block;
        },
    );

    private selectAttributeTab(selectedAttrValue: string) {
        this.setState({
            selectedAttrValue: selectedAttrValue,
        });
    }

    private get block() {
        return this.parseBlockData(this.props.blockJSON);
    }

    private get selectedAttrValue() {
        const validValues = this.block.tabs.map((tab) => tab.attribute_value);
        if (
            this.state.selectedAttrValue &&
            validValues.includes(this.state.selectedAttrValue)
        ) {
            return this.state.selectedAttrValue;
        }
        if (this.block.tabs.length > 0) {
            return this.block.tabs[0].attribute_value;
        }
        return null;
    }

    componentDidMount() {
        const selectedReduxValue = this.getSelectedAttributeFromRedux(
            this.props.optionValues,
        );
        this.setState({
            selectedAttrValue: selectedReduxValue || null,
        });
    }

    componentDidUpdate(prevProps: IProps) {
        // We track the selected tab in local component state so that the user can
        // change the tab WITHOUT affecting the configurator immediately. However,
        // when the user updates the related attribute in the configurator, we
        // update the current tab to match.
        const valueNew = this.getSelectedAttributeFromRedux(
            this.props.optionValues,
        );
        const valueOld = this.getSelectedAttributeFromRedux(
            prevProps.optionValues,
        );
        if (valueNew && valueOld !== valueNew) {
            this.setState({
                selectedAttrValue: valueNew,
            });
        }
    }

    private getSelectedAttributeFromRedux(optionValues: IOptionValues) {
        const selectedReduxValues = optionValues[this.block.attribute];
        if (!selectedReduxValues) {
            return;
        }
        return getOptionValueByIdx(selectedReduxValues, 0);
    }

    render() {
        return (
            <div className={`u-flex-container ${styles.root}`}>
                <header className={styles.header}>
                    <RichText html={this.block.header_content} />
                </header>
                <div
                    className={styles.tablist}
                    role="tablist"
                    aria-label="Tab Feature Attribute Block - Tab List"
                >
                    {this.block.tabs.map((attr, i) => (
                        <TabFeatureAttributeBlockAttributeTabButton
                            key={attr.attribute_value}
                            idx={i}
                            tab={attr}
                            onClick={() => {
                                this.selectAttributeTab(attr.attribute_value);
                            }}
                            isSelected={
                                attr.attribute_value === this.selectedAttrValue
                            }
                        />
                    ))}
                    <div className={styles.tablistBar}></div>
                </div>
                <div className={styles.content}>
                    {this.block.tabs.map((attr, i) => (
                        <TabFeatureAttributeBlockAttributeTabContent
                            key={attr.attribute_value}
                            attribute={this.block.attribute}
                            idx={i}
                            tab={attr}
                            isSelected={
                                attr.attribute_value === this.selectedAttrValue
                            }
                        />
                    ))}
                </div>
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        optionValues: rootState.configurator.ui.optionValues,
        ...ownProps,
    };
};

export const TabFeatureAttributeBlock = connect(mapStateToProps)(
    TabFeatureAttributeBlockComponent,
);
