import classNames from "classnames";
import React from "react";
import SVG from "react-inlinesvg";
import { t } from "ttag";

import iconAddIcon from "../../../img/icons/add_icon.svg";
import iconReviewsYes from "../../../img/icons/reviews-yes.svg";
import { getProduct, getProductURL } from "../../api/products";
import {
    IPLCOptionPanel,
    IPLCProductCategorySelector,
    IPLCProductCategorySelectorOption,
} from "../../apps/configurator/models.interfaces";
import {
    IActionSetSelectedCategory,
    ISelectedCategories,
} from "../../apps/configurator/reducers.interfaces";
import Flair from "../../common/Flair";
import { Image } from "../../common/Image";
import { RichText } from "../../common/RichText";
import {
    IOptionCode,
    IOptionSingleValue,
    IOptionValues,
    IProduct,
} from "../../models/catalogue.interfaces";
import {
    IProductCategoryID,
    IProductID,
    isoSafeHTML,
} from "../../models/nominals";
import { BreakPoint, IViewport } from "../../models/screen.interfaces";
import {
    IComparisonCardAttribute,
    IComparisonCardCategory,
    IComparisonCardProduct,
    IComparisonTabAttribute,
    IComparisonTabCategory,
    IComparisonTabProduct,
    ITabCardsBlock,
} from "../../models/streamfield-blocks";
import { notEmpty, unique } from "../../utils/functional";

interface IProps {
    theme?: string;
    tabCards: ITabCardsBlock;
    pageRootProducts: IProduct[] | null;
    pageRootProductID: IProductID | null;
    pageProductNamespace: string | null;
    pageCategorySelectors: IPLCProductCategorySelector[];
    pageOptionPanels: IPLCOptionPanel[];
    pageSelectedCategories: ISelectedCategories;
    pageSelectedOptionValues: IOptionValues;
    viewport: IViewport;
    onCardProductSelect: (product: IProduct) => void;
    onCardCategorySelect: (
        payload: IActionSetSelectedCategory["payload"],
    ) => void;
    onCardAttributeSelect: (
        namespace: string,
        code: IOptionCode,
        index: number,
        totalNumValues: number,
        value: IOptionSingleValue,
    ) => void;
}

interface IPLCProductCategory {
    id: IProductCategoryID;
    name: string;
}

interface IState {
    currentTab: number;
}

export class TabCardsBlock extends React.PureComponent<IProps, IState> {
    public state: IState = {
        currentTab: 0,
    };

    private readonly onTabPress = (
        event: React.MouseEvent<HTMLElement>,
        tabNum: number,
    ) => {
        event.preventDefault();
        this.setState({
            currentTab: tabNum,
        });
    };

    private readonly onTabSelect = (
        event: React.FormEvent<HTMLSelectElement>,
    ) => {
        this.setState({
            currentTab: parseInt(event.currentTarget.value, 10),
        });
    };

    private readonly onCategorySelect = async (
        category: IPLCProductCategory,
    ) => {
        const selector = this.getCategorySelector(category);

        if (selector) {
            this.props.onCardCategorySelect({
                selectorID: selector.id,
                categoryID: category.id,
                categorySelectors: this.props.pageCategorySelectors,
                optionPanels: this.props.pageOptionPanels,
            });
        }
    };

    private readonly onAttributeSelect = async (
        attributeCode: IOptionCode,
        attributeValue: string,
    ) => {
        // First, check that the attributeCode exists in the configurator
        if (!this.hasOptionPanel(attributeCode)) {
            return;
        }

        if (!this.props.pageProductNamespace) {
            return;
        }

        this.props.onCardAttributeSelect(
            this.props.pageProductNamespace,
            attributeCode,
            0,
            1,
            attributeValue,
        );
    };

    private readonly onProductSelect = async (productNumber: IProductID) => {
        const productURL = getProductURL(productNumber);
        const product = await getProduct(productURL);
        if (productNumber !== this.props.pageRootProductID) {
            this.props.onCardProductSelect(product);
        }
    };

    private getCategorySelector(category: IPLCProductCategory) {
        return this.props.pageCategorySelectors.find(
            (categorySelector: IPLCProductCategorySelector) => {
                return categorySelector.value.options.find(
                    (
                        categorySelectorOption: IPLCProductCategorySelectorOption,
                    ) => {
                        return (
                            categorySelectorOption.category.name ===
                            category.name
                        );
                    },
                );
            },
        );
    }

    private getOptionValues(attributeCode: IOptionCode): IOptionSingleValue[] {
        if (!this.props.pageRootProducts) {
            return [];
        }

        let allOptionValues: IOptionSingleValue[] = [];

        this.props.pageRootProducts.forEach((product: IProduct) => {
            if (!product) {
                return [];
            }

            const children = product.children;
            if (!children || children.length <= 0) {
                const attr = product.attributes[attributeCode];
                if (!attr) {
                    return [];
                }
                return Array.isArray(attr.value) ? attr.value : [attr.value];
            }
            const values = children
                .reduce<Array<IOptionSingleValue | null>>((memo, child) => {
                    const attr = child.attributes[attributeCode];
                    return memo.concat(attr ? attr.value : null);
                }, [])
                .filter(notEmpty)
                .filter(unique);
            values.forEach((value: IOptionSingleValue) => {
                allOptionValues.push(value);
            });

            return [];
        });

        allOptionValues = allOptionValues.filter(unique);

        return allOptionValues;
    }

    private hasOptionValue(attributeCode: IOptionCode, attributeValue: string) {
        return this.getOptionValues(attributeCode).includes(attributeValue);
    }

    private hasOptionPanel(attributeCode: IOptionCode) {
        return !!this.getOptionValues(attributeCode);
    }

    private isMobileWidth() {
        return this.props.viewport.width <= BreakPoint.SMALL;
    }

    private isMobileOrTabletWidth() {
        return this.props.viewport.width <= BreakPoint.MEDIUM;
    }

    private renderHeader() {
        const headerClasses = "tab-cards-block__header l-capped-width";
        if (this.isMobileWidth()) {
            const headerMobile =
                this.props.tabCards.header_content_mobile || "";
            return <RichText className={headerClasses} html={headerMobile} />;
        }
        const headerDesktop = this.props.tabCards.header_content_desktop || "";
        return <RichText className={headerClasses} html={headerDesktop} />;
    }

    private renderNav() {
        if (this.props.tabCards.hide_tabs) {
            return null;
        }
        let ariaLabel: string;
        let tabNames: JSX.Element[];

        if (this.isMobileWidth()) {
            ariaLabel =
                this.props.tabCards.aria_label ||
                "tab cards block navigation - dropdown";
            tabNames = this.props.tabCards.tabs.map((tab, i) => {
                return (
                    <option
                        key={i}
                        value={i}
                        dangerouslySetInnerHTML={{
                            __html: isoSafeHTML.unwrap(tab.value.tab_name),
                        }}
                    ></option>
                );
            });
            return (
                <select
                    dir="ltr"
                    value={this.state.currentTab}
                    onChange={this.onTabSelect}
                >
                    {tabNames}
                </select>
            );
        }

        ariaLabel =
            this.props.tabCards.aria_label ||
            "tab cards block navigation - tabs";
        tabNames = this.props.tabCards.tabs.map((tab, i) => {
            const isSelected = i === this.state.currentTab ? "selected" : "";
            return (
                <button
                    key={i}
                    className={isSelected}
                    onClick={(e) => {
                        this.onTabPress(e, i);
                    }}
                    dangerouslySetInnerHTML={{
                        __html: isoSafeHTML.unwrap(tab.value.tab_name),
                    }}
                ></button>
            );
        });
        return (
            <nav role="tablist" aria-label={ariaLabel}>
                {tabNames}
                <span></span>
            </nav>
        );
    }

    private renderTabContent() {
        const tab = this.props.tabCards.tabs[this.state.currentTab];

        if (!tab) {
            return null;
        }
        const tabCopy = tab.value.tab_support_copy || "";
        return (
            <div className="tab-cards-block__content-container">
                {tab.value.tab_support_copy && (
                    <div
                        className="tab-cards-block__content-copy"
                        dangerouslySetInnerHTML={{ __html: tabCopy }}
                    ></div>
                )}
                <section className="tab-cards-block__cards" role="list">
                    {this.renderTabCards(tab.type, tab.value)}
                </section>
            </div>
        );
    }

    private renderTabCards(
        tabType: string,
        tab:
            | IComparisonTabProduct
            | IComparisonTabCategory
            | IComparisonTabAttribute,
    ) {
        const tabCards = tab.tab_cards.map((tabCard, i: number) => {
            return (
                <div className="tab-card" key={i} role="listitem">
                    {tabCard.show_flair && <Flair flair={tabCard.card_flair} />}
                    <div className="tab-card__content">
                        {this.isMobileOrTabletWidth() ? (
                            <>
                                <div>
                                    <Image
                                        className="responsive-img"
                                        src={
                                            tabCard.card_image_mobile
                                                ? tabCard.card_image_mobile
                                                      .renditions?.default?.url
                                                : tabCard.card_image.renditions
                                                      ?.default?.url
                                        }
                                        alt={tabCard.card_image.title}
                                        lazy={false}
                                    />
                                </div>
                                <div>
                                    <h4>
                                        <RichText
                                            html={tabCard.card_title || ""}
                                        />
                                    </h4>
                                    <RichText
                                        className="tab-card__copy"
                                        html={tabCard.card_copy}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <h4>
                                    <RichText html={tabCard.card_title || ""} />
                                </h4>
                                <div>
                                    <Image
                                        className="responsive-img"
                                        src={
                                            tabCard.card_image.renditions
                                                ?.default?.url
                                        }
                                        alt={tabCard.card_image.title}
                                        lazy={false}
                                    />
                                </div>
                                <RichText
                                    className="tab-card__copy"
                                    html={tabCard.card_copy}
                                />
                            </>
                        )}
                        {this.renderTabCardButton(tabType, tabCard)}
                    </div>
                </div>
            );
        });
        return tabCards;
    }

    private renderTabCardButtonProduct(tabCard: IComparisonCardProduct) {
        const isSelected =
            tabCard.card_product === this.props.pageRootProductID;

        const pageHasProduct = this.props.pageRootProducts
            ? this.props.pageRootProducts.some((rootProduct) => {
                  return rootProduct.id === tabCard.card_product;
              })
            : false;
        // If the value of ProductChooserSubBlock isn't on this page, then don't render the button
        if (!pageHasProduct) {
            return null;
        }

        const buttonClasses = classNames({
            "button": true,
            "button--selected": isSelected,
        });
        return (
            this.props.pageRootProductID && (
                <button
                    className={buttonClasses}
                    onClick={() => {
                        this.onProductSelect(tabCard.card_product);
                    }}
                >
                    {isSelected ? t`Selected` : t`Select`}
                </button>
            )
        );
    }

    private renderTabCardButtonCategory(tabCard: IComparisonCardCategory) {
        let isSelected = false;
        const selector = this.getCategorySelector(tabCard.card_category);

        // If the value of CategoryChooserSubBlock isnt on this page, then dont render the button
        if (!selector) {
            return null;
        } else {
            if (
                this.props.pageSelectedCategories[selector.id] ===
                tabCard.card_category.id
            ) {
                isSelected = true;
            }
        }
        const buttonClasses = classNames({
            "button": true,
            "button--selected": isSelected,
        });
        return (
            <button
                className={buttonClasses}
                onClick={() => {
                    this.onCategorySelect(tabCard.card_category);
                }}
            >
                {isSelected ? t`Selected` : t`Select`}
            </button>
        );
    }

    private renderTabCardButtonAttribute(tabCard: IComparisonCardAttribute) {
        let isSelected = false;
        // The following is a list of optionCodes that have unique button styling per TSI request. Additionally, these attributes dont require a value (as they're just booleans) so we can ignore the value choice if the user has selected this type of Attribute.
        const booleanOptionCodes = [
            "option_chill",
            "option_advanced_support",
            "option_advanced_pressure_relief",
        ];

        const attribute: IOptionCode = tabCard.card_attribute as IOptionCode;
        const isBooleanType =
            Object.values(booleanOptionCodes).includes(attribute);
        const buttonText = isBooleanType ? "Add" : "Select";

        // If the value of ProductOptionValueChoiceBlock is not present in the card's ProductAttributeChoice, then dont render the button
        if (
            !isBooleanType &&
            !this.hasOptionValue(attribute, tabCard.card_attribute_value)
        ) {
            return null;
        }

        let cardValue = isBooleanType ? "1" : tabCard.card_attribute_value;
        if (
            this.hasOptionPanel(attribute) &&
            this.props.pageSelectedOptionValues[attribute] === cardValue
        ) {
            isSelected = true;

            // If a boolean type card is already selected, set it's value to 'false' to allow toggling off
            if (isBooleanType) {
                cardValue = "0";
            }
        }

        const selectedIcon = isSelected ? (
            <SVG src={iconReviewsYes} />
        ) : (
            <SVG src={iconAddIcon} />
        );

        const buttonClasses = classNames({
            "button": true,
            "button--selected": isSelected,
        });
        return (
            <button
                className={buttonClasses}
                onClick={() => {
                    this.onAttributeSelect(attribute, cardValue);
                }}
            >
                {isBooleanType && selectedIcon}
                {isSelected ? t`${buttonText}ed` : buttonText}
            </button>
        );
    }

    private renderTabCardButton(
        tabType: string,
        tabCard:
            | IComparisonCardProduct
            | IComparisonCardCategory
            | IComparisonCardAttribute,
    ) {
        switch (tabType) {
            case "tab_product":
                return this.renderTabCardButtonProduct(
                    tabCard as IComparisonCardProduct,
                );
                break;
            case "tab_category":
                return this.renderTabCardButtonCategory(
                    tabCard as IComparisonCardCategory,
                );
                break;
            case "tab_attribute":
                return this.renderTabCardButtonAttribute(
                    tabCard as IComparisonCardAttribute,
                );
                break;
            default:
                throw new Error(`Unexpected tab card type: ${tabType}`);
        }
    }

    render() {
        const blockClasses = classNames({
            "tab-cards-block": true,
            [`${this.props.theme}`]: !!this.props.theme,
        });
        return (
            <section className={blockClasses}>
                <section>
                    {this.renderHeader()}
                    {this.renderNav()}
                </section>
                {this.renderTabContent()}
            </section>
        );
    }
}
