import React from "react";
import { t } from "ttag";

import styles from "./NoResults.module.scss";

interface IProps {
    retailerName: string;
}

export const NoResults = (props: IProps) => {
    return (
        <div className={styles.root}>
            <p>{t`There are no ${props.retailerName} within your search criteria`}</p>
        </div>
    );
};
