import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";

import iconQuestionMarkCircleFilled from "../../../../img/icons/question-mark-circle-filled.svg";
import iconTrackerStepDelivered from "../../../../img/icons/tracker-step-delivered.svg";
import iconTrackerStepOrderPreparedForShipment from "../../../../img/icons/tracker-step-order-prepared-for-shipment.svg";
import iconTrackerStepOrderProcessing from "../../../../img/icons/tracker-step-order-processing.svg";
import iconTrackerStepPending from "../../../../img/icons/tracker-step-pending.svg";
import iconTrackerStepReadyForDelivery from "../../../../img/icons/tracker-step-ready-for-delivery.svg";
import iconTrackerStepShipped from "../../../../img/icons/tracker-step-shipped.svg";
import { UncontrolledPopover } from "../../../common/Popover";
import { BreakPoint } from "../../../models/screen.interfaces";
import { getViewportBreakpoint } from "../../../utils/detectMobile";

import styles from "./OrderDetailsTrackerModal.module.scss";

const icons = {
    "delivered": iconTrackerStepDelivered,
    "order-prepared-for-shipment": iconTrackerStepOrderPreparedForShipment,
    "order-processing": iconTrackerStepOrderProcessing,
    "pending": iconTrackerStepPending,
    "ready-for-delivery": iconTrackerStepReadyForDelivery,
    "shipped": iconTrackerStepShipped,
} as const;

const iconExists = (step: string): step is keyof typeof icons => {
    return Object.prototype.hasOwnProperty.call(icons, step);
};

const getIcon = (trackerStep: string): string | null => {
    const step = trackerStep.toLowerCase().replace(/\s+/g, "-");
    return iconExists(step) ? icons[step] : null;
};

export interface IDeliveryStepProps {
    value: {
        tracking_step_title: string;
        tracking_step_copy: string;
        tracker_step: string;
    };
}

interface IProps {
    deliverySteps: IDeliveryStepProps[];
    modalHead: string;
    modalSubHead: string | null;
    currentStep: string | undefined;
}

export const OrderDetailsTrackerModal = (props: IProps) => {
    const [placement, setPlacement] = useState<"left" | "right">("left");

    useEffect(() => {
        const currentPlacement =
            getViewportBreakpoint() >= BreakPoint.MEDIUM ? "left" : "right";
        setPlacement(currentPlacement);
        const handleResize = () => {
            const newPlacement =
                getViewportBreakpoint() >= BreakPoint.MEDIUM ? "left" : "right";
            setPlacement(newPlacement);
        };
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const buildShippingMessages = () => {
        if (!props.deliverySteps) {
            return null;
        }

        const deliveryStepsList = props.deliverySteps.map((step, index) => {
            const { tracking_step_title, tracking_step_copy, tracker_step } =
                step.value;
            const currentStepClass =
                tracker_step === props.currentStep ? styles.currentStep : "";
            const icon = getIcon(tracker_step);
            return (
                <li key={index} className={currentStepClass}>
                    {icon && (
                        <SVG
                            className={styles.stepIcon}
                            aria-hidden="true"
                            src={icon}
                        />
                    )}
                    <div>
                        <h3>{tracking_step_title}</h3>
                        <p>{tracking_step_copy}</p>
                    </div>
                </li>
            );
        });

        return deliveryStepsList;
    };

    return (
        <UncontrolledPopover
            triggerContent={
                <SVG aria-hidden="true" src={iconQuestionMarkCircleFilled} />
            }
            triggerBtnProps={{
                className: styles.orderDetailsTrackerModalTrigger,
            }}
            popperOptions={{
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, -18],
                        },
                    },
                ],
                placement: placement,
            }}
            modalProps={{
                bodyOpenClassName: `${styles.modalBodyOpen}`,
                overlayClassName: `${styles.root}`,
                className: `${styles.orderDetailsTrackerModal}`,
                contentLabel: props.modalHead,
                style: {
                    overlay: {
                        backgroundColor: "transparent",
                    },
                },
            }}
            showArrow={true}
            isStandardModalBelowSize={BreakPoint.MIN}
        >
            <div className={styles.orderDetailsTrackerModalContent}>
                <h1>{props.modalHead}</h1>
                <h2>{props.modalSubHead}</h2>
                <ul>{buildShippingMessages()}</ul>
            </div>
        </UncontrolledPopover>
    );
};
