import React from "react";
import { connect } from "react-redux";
import { t } from "ttag";

import { IAddressCountry } from "../../../models/address.interfaces";
import { money } from "../../../utils/format";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { Actions } from "../actions";
import {
    IBasket,
    IBasketLine,
    IBasketSearchResults,
    ICustomerSummary,
} from "../models.interfaces";
import { getBasketTotal } from "../utils";
import { BasketAssign } from "./BasketAssign";
import { CustomerDetails } from "./CustomerDetails";

interface IOwnProps {}

interface IReduxProps {
    countries: IAddressCountry[];
    basketSearch: IBasketSearchResults;
    assisting: {
        customer: ICustomerSummary | null;
        basket: IBasket | null;
    };
}

interface IDispatchProps {
    actions: Actions;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class BasketSearchComponent extends React.Component<IProps, IState> {
    private search: HTMLInputElement | null | undefined;

    componentDidMount() {
        // If no term is entered and the CSR is already assisting a basket, default to it.
        if (
            !this.props.basketSearch.term &&
            this.props.assisting.basket &&
            this.props.assisting.basket.encoded_basket_id
        ) {
            this.props.actions.setBasketSearchTerm(
                this.props.assisting.basket.encoded_basket_id,
            );
            this.props.actions.lookupBasket(
                this.props.assisting.basket.encoded_basket_id,
            );
            return;
        }
        // Otherwise, do the lookup based on the search bar
        this.props.actions.lookupBasket(this.props.basketSearch.term);
    }

    componentDidUpdate() {
        if (this.search) {
            this.search.focus();
        }
    }

    private readonly onSearchTermChange = (
        event: React.FormEvent<HTMLInputElement>,
    ) => {
        this.props.actions.setBasketSearchTerm(event.currentTarget.value);
    };

    private readonly onDoSearch = (event: React.FormEvent<HTMLElement>) => {
        event.preventDefault();
        if (this.search) {
            this.props.actions.lookupBasket(this.search.value);
        }
    };

    private readonly onStartNewBasket = (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        event.preventDefault();
        this.props.actions.startNewBasket();
    };

    private readonly onViewCustomerDetails = (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        event.preventDefault();
        const owner = this.props.basketSearch.owner;
        if (!owner) {
            return;
        }
        const component = <CustomerDetails url={owner.url} />;
        this.props.actions.pushViewStack("Customer Details", component);
    };

    private readonly onAssignBasket = (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        event.preventDefault();
        const basket = this.props.basketSearch.basket;
        if (!basket) {
            return;
        }
        const component = (
            <BasketAssign
                basketID={basket.id}
                email={
                    this.props.assisting.customer
                        ? this.props.assisting.customer.email
                        : ""
                }
            />
        );
        this.props.actions.pushViewStack(
            "Assign Basket to Customer",
            component,
        );
    };

    private readonly onStartAssisting = (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        event.preventDefault();
        const owner = this.props.basketSearch.owner;
        if (!owner) {
            return;
        }
        this.props.actions
            .startAssistingCustomer(owner.url)
            .then(() => {
                this.onDoSearch(event);
                return null;
            })
            .catch((err) => {
                console.error(err);
                alert(t`Error! Could not start assisting customer.`);
            });
    };

    private readonly onStopAssisting = (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        event.preventDefault();
        const owner = this.props.basketSearch.owner;
        if (!owner) {
            return;
        }
        this.props.actions
            .stopAssistingCustomer(owner.url)
            .then(() => {
                this.onDoSearch(event);
                return null;
            })
            .catch((err) => {
                console.error(err);
                alert(t`Error! Could not stop assisting customer.`);
            });
    };

    private buildLines(lines: IBasketLine[]) {
        if (lines.length <= 0) {
            return (
                <tr>
                    <td colSpan={7}>
                        <em>{t`Basket is empty`}</em>
                    </td>
                </tr>
            );
        }
        return lines.map((line) => {
            return (
                <tr key={line.id}>
                    <td>{line.id}</td>
                    <td>{line.product.title}</td>
                    <td>{line.quantity}</td>
                    <td>{money(line.price_excl_tax)}</td>
                    <td></td>
                </tr>
            );
        });
    }

    private renderResults() {
        const newBasketHelpText = this.props.assisting.customer
            ? t`Since you are currently assisting a customer, the new basket will be owned by and immediately visible to them.`
            : t`Since you are not currently assisting a customer, the new basket will be owned by you. You will have the option to assign the basket to a customer later.`;
        const buttonsTop = (
            <div className="button-group">
                <button onClick={this.onStartNewBasket}>
                    Start&nbsp;New&nbsp;Basket
                </button>
                <p className="button-group__help">
                    {t`Freeze the current basket and start a new basket.`}{" "}
                    {newBasketHelpText}
                </p>
            </div>
        );

        const basket = this.props.basketSearch.basket;
        const owner = this.props.basketSearch.owner;
        if (!basket || !owner || this.props.basketSearch.error) {
            return (
                <div className="csr-customer-basket">
                    <div className="csr-customer-basket__header">
                        <h2>{t`Actions`}</h2>
                        {buttonsTop}
                        <h2>{this.props.basketSearch.error}</h2>
                    </div>
                </div>
            );
        }

        let assistHelpText: string;
        if (owner.is_assisting) {
            assistHelpText = t`Return to editing your own basket by clicking "Stop Assisting Customer"`;
        } else if (this.props.assisting.customer) {
            assistHelpText = t`Click "Start Assisting Customer" to begin editing his or her basket. This will end your currently active customer assistance session.`;
        } else {
            assistHelpText = t`Click "Start Assisting Customer" to begin editing his or her basket.`;
        }
        const buttonsBottom = (
            <div className="button-group" key="view-customer-details">
                <div>
                    <button
                        title={t`View details about this customer.`}
                        onClick={this.onViewCustomerDetails}
                    >
                        View&nbsp;Customer&nbsp;Details
                    </button>
                </div>
                <div>
                    <button
                        title={t`Start assisting this customer. While assisting a customer, all changes affect the customer's basket rather than your own basket.`}
                        onClick={this.onStartAssisting}
                        disabled={owner.is_assisting}
                    >
                        Start&nbsp;Assisting&nbsp;Customer
                    </button>
                    <button
                        title={t`Stop assisting this customer. You will be returned to viewing and editing your own basket, rather than the customer's basket.`}
                        onClick={this.onStopAssisting}
                        disabled={!owner.is_assisting}
                    >
                        Stop&nbsp;Assisting&nbsp;Customer
                    </button>
                    <p className="button-group__help">{assistHelpText}</p>
                </div>
                <div>
                    <button
                        title={t`Email this basket to a customer.`}
                        onClick={this.onAssignBasket}
                        disabled={
                            !owner.is_assisting ||
                            !basket ||
                            basket.status !== "Open"
                        }
                    >
                        {t`Send Basket to Customer`}
                    </button>
                    <p className="button-group__help">
                        {t`Assign this basket to a customer and send it to them via email.`}
                    </p>
                </div>
            </div>
        );

        const created = basket.date_created
            ? new Date(basket.date_created).toLocaleString()
            : "-";
        const merged = basket.date_merged
            ? new Date(basket.date_merged).toLocaleString()
            : "-";
        const submitted = basket.date_submitted
            ? new Date(basket.date_submitted).toLocaleString()
            : "-";
        const totalExclTax = getBasketTotal(basket);
        const groups = owner.groups || [];
        const assistor = owner.currently_assisted_by;
        const currentAssistor = assistor
            ? `${assistor.first_name} ${assistor.last_name} <${assistor.email}>`
            : null;
        return (
            <div className="csr-customer-basket">
                <div className="csr-customer-basket__header">
                    <h2>{t`Actions`}</h2>
                    {buttonsTop}
                    <h2>
                        {t`Basket:`} <em>{basket.encoded_basket_id}</em>
                    </h2>
                </div>
                <div className="csr-customer-basket__basic">
                    <table>
                        <tbody>
                            <tr>
                                <th>{t`First Name`}</th>
                                <td>{owner.first_name || "-"}</td>
                            </tr>
                            <tr>
                                <th>{t`Last Name`}</th>
                                <td>{owner.last_name || "-"}</td>
                            </tr>
                            <tr>
                                <th>{t`Username`}</th>
                                <td>{owner.username || "-"}</td>
                            </tr>
                            <tr>
                                <th>{t`Email Address`}</th>
                                <td>{owner.email || "-"}</td>
                            </tr>
                            <tr>
                                <th>{t`Groups`}</th>
                                <td>
                                    {groups.length <= 0
                                        ? "-"
                                        : groups.map((name) => {
                                              return (
                                                  <span
                                                      className="csr-customer-group"
                                                      key={name}
                                                  >
                                                      {name}
                                                  </span>
                                              );
                                          })}
                                </td>
                            </tr>
                            <tr>
                                <th>{t`Currently being assisted by`}</th>
                                <td>{currentAssistor || "-"}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="csr-customer-basket__basic">
                    <table>
                        <tbody>
                            <tr>
                                <th>{t`Status`}</th>
                                <td>{basket.status}</td>
                            </tr>
                            <tr>
                                <th>{t`Total (excl. Tax)`}</th>
                                <td>{money(totalExclTax)}</td>
                            </tr>
                            <tr>
                                <th>{t`Date Created`}</th>
                                <td>{created}</td>
                            </tr>
                            <tr>
                                <th>{t`Date Merged`}</th>
                                <td>{merged}</td>
                            </tr>
                            <tr>
                                <th>{t`Date Submitted`}</th>
                                <td>{submitted}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="csr-customer-basket__lines">
                    {buttonsBottom}
                    <h2>{t`Lines`}</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>{t`Line ID`}</th>
                                <th>{t`Product`}</th>
                                <th>{t`Options`}</th>
                                <th>{t`Quantity`}</th>
                                <th>{t`Line Price (excl. Tax)`}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>{this.buildLines(basket.lines)}</tbody>
                    </table>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="csr-basket-search">
                <form onSubmit={this.onDoSearch}>
                    <p
                        className="csr-basket-search__help"
                        dangerouslySetInnerHTML={{
                            __html: t`Search for a customer's cart using their <em>Cart Reference Number</em>. For example: <em>972-1C2-3</em>.`,
                        }}
                    ></p>
                    <div className="csr-basket-search__input">
                        <input
                            type="search"
                            placeholder={t`Search`}
                            ref={(r) => {
                                this.search = r;
                            }}
                            value={this.props.basketSearch.term}
                            onChange={this.onSearchTermChange}
                        />
                    </div>
                    <div className="csr-basket-search__button">
                        <button type="submit">{t`Lookup`}</button>
                    </div>
                </form>
                <div className="csr-basket-search__results">
                    {this.renderResults()}
                </div>
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"csr", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        countries: rootState.csr.countries,
        basketSearch: rootState.csr.basketSearch,
        assisting: rootState.csr.assisting,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const actions = new Actions(dispatch);
    return {
        actions: actions,
    };
};

export const BasketSearch = connect(
    mapStateToProps,
    mapDispatchToProps,
)(BasketSearchComponent);
