import React from "react";
import { Provider } from "react-redux";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";

import { store } from "../store";

// Render Pillows Grid
dynamicPlaceComponent(
    '[data-place-react="stream-field-product-grid"]',
    async (elem) => {
        const { StreamFieldProductGrid } = await import(
            "tsi-common-react/src/apps/product-grid2/index"
        );
        return (
            <Provider store={store}>
                <StreamFieldProductGrid
                    gridVariant="streamfield"
                    productCategoryID={elem.dataset.categoryId || "0"}
                    filters={elem.dataset.filters || "[]"}
                    sorters={elem.dataset.sorters || "[]"}
                    hideSort={elem.dataset.hideSort || "no"}
                    disableHoverOverlay={
                        elem.dataset.disableHoverOverlay || "yes"
                    }
                />
            </Provider>
        );
    },
);
