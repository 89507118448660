import classNames from "classnames";
import React from "react";
import { Image } from "tsi-common-react/src/common/Image";
import { Link } from "tsi-common-react/src/common/Link";
import { t } from "ttag";

import { IDropdownMenuItem } from "../../menu.interface";

interface IProps {
    isActive: boolean;
    isMobile: boolean;
    linksArray: IDropdownMenuItem[];
    linkID: number;
}

interface IState {}

export class DropdownLinks extends React.Component<IProps, IState> {
    render() {
        const dropdownLinks = this.props.linksArray.map((obj, id) => {
            let badge: JSX.Element | null = null;
            if (obj.value.badge) {
                if (this.props.isMobile) {
                    badge = (
                        <span>
                            <span className="menu-dropdown-badge menu-dropdown-badge--mobile">
                                {obj.value.badge.mobile_text}
                            </span>
                        </span>
                    );
                } else {
                    badge = (
                        <span>
                            <Image
                                className={`menu-dropdown-badge menu-dropdown-badge--${obj.value.badge.position}`}
                                src={obj.value.badge.image.url}
                                alt={obj.value.badge.mobile_text}
                            />
                        </span>
                    );
                }
            }
            const titleClass = classNames({
                "menu-dropdown-block--lg-title": !this.props.isMobile,
                "menu-dropdown-block--sm-title": this.props.isMobile,
            });
            const pageURL =
                obj.type === "snippet_link"
                    ? obj.value.link
                    : obj.value.page.url;
            const target = obj.value.open_in_new_tab ? "_blank" : "_top";
            const rel = target === "_blank" ? "noopener noreferrer" : "";
            return (
                <Link
                    key={id}
                    href={pageURL}
                    className="menu-dropdown-block"
                    target={target}
                    rel={rel}
                    title={t`Shop ${obj.value.override_title}`}
                >
                    {badge}
                    <Image
                        className="menu-dropdown-block--image"
                        src={obj.value.image.url}
                        alt={obj.value.image.title}
                    />
                    <p className={titleClass}>{obj.value.override_title}</p>
                </Link>
            );
        });
        const linkClass = classNames({
            "main-dropdown-menu__shop-links": true,
            "main-dropdown-menu__shop-links--mobile": this.props.isMobile,
            "main-dropdown-menu__shop-links--mobile-active":
                this.props.isMobile && this.props.isActive,
        });
        return (
            <div
                aria-expanded={this.props.isActive}
                className={linkClass}
                key={this.props.linkID}
                data-subnav={this.props.linkID}
            >
                {dropdownLinks}
            </div>
        );
    }
}
