import React from "react";
import { connect } from "react-redux";

import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { Actions } from "../actions";
import { IPhone } from "../models.interfaces";
import { FormView } from "./abstract/FormView";

interface IOwnProps {
    phone: Partial<IPhone>;
}

interface IReduxProps {}

interface IDispatchProps {
    actions: Actions;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

const CustomerDetailsPhoneComponent = (props: IProps) => {
    return (
        <FormView
            url={props.phone.url}
            baseURL={"/api/csr/user-phones/"}
            actions={props.actions}
            fields={[
                {
                    name: "user",
                    type: "hidden",
                },
                {
                    label: "Phone Number",
                    name: "phone_number",
                    placeholder: "+1 (555) 555-5555",
                    isRequired: true,
                    type: "phone",
                },
            ]}
            initialValues={props.phone}
        />
    );
};

const mapStateToProps: TStateMapper<"csr", IReduxProps, IOwnProps> = (
    _rootState,
    ownProps,
) => {
    return {
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const actions = new Actions(dispatch);
    return {
        actions: actions,
    };
};

export const CustomerDetailsPhone = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CustomerDetailsPhoneComponent);
