import React from "react";
import { connect } from "react-redux";
import { TStateMapper } from "tsi-common-react/src/apps/reducers.interfaces";
import { formatDistance } from "tsi-common-react/src/apps/retail/helpers";
import { Link } from "tsi-common-react/src/common/Link";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { urls } from "tsi-common-react/src/utils/urls";
import { t } from "ttag";

import { nearestFlagshipStoreSelector } from "../selectors";

interface IReduxProps {
    store: IRetailStoreWithDistance | null;
}

interface IOwnProps {}

type IProps = IReduxProps & IOwnProps;

interface IState {}

class DistanceToStoreContainer extends React.Component<IProps, IState> {
    render() {
        let text: string = t`See Closest Location`;
        if (this.props.store && this.props.store.distance) {
            const formattedDist = formatDistance(this.props.store.distance);
            text = t`${formattedDist} Miles Away`;
        }
        return <Link href={urls.pageURL("tempur-pedic-stores")}>{text}</Link>;
    }
}

const mapStateToProps: TStateMapper<"retail", IReduxProps, IOwnProps> = (
    state,
    ownProps,
) => {
    return {
        ...ownProps,
        store: nearestFlagshipStoreSelector(state),
    };
};

export const DistanceToStore = connect(mapStateToProps)(
    DistanceToStoreContainer,
);
