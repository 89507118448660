import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { t } from "ttag";

import { ProductOptionSelectorSet } from "../../../common/ProductOptionSelectorSet";
import { ConfiguratorTypes } from "../../../constants";
import {
    IOptionCode,
    IOptionSingleValue,
    IOptionValues,
    IProduct,
} from "../../../models/catalogue.interfaces";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { getProductVariant } from "../../selectors";
import { defaults } from "../defaults";
import {
    Action,
    IActionSetOptionValue,
    IActionSetQuantity,
} from "../reducers.interfaces";

import styles from "./ProductCardConfigurator.module.scss";

interface IQuantitySelectorProps {
    rootProduct: IProduct;
    quantity: number;
    onQuantityChange: (e: React.FormEvent<HTMLSelectElement>) => void;
}

export const QuantitySelector = (props: IQuantitySelectorProps) => {
    const wrapperClasses = classNames({
        [styles.selectContainer]: true,
        "grid-card-configurator__select-container--product-grid": true,
    });
    const inputID = `quantity-${props.rootProduct.id}`;
    const options = Array<number>(5)
        .fill(0)
        .map((_, i) => i + 1)
        .map((i) => (
            <option key={i} value={i}>
                {i}
            </option>
        ));
    return (
        <div className={wrapperClasses}>
            <label htmlFor={inputID}>{t`Qty:`}</label>
            <select
                id={inputID}
                value={props.quantity}
                onChange={props.onQuantityChange}
            >
                <optgroup label={t`Qty:`}>{options}</optgroup>
            </select>
        </div>
    );
};

interface IOwnProps {
    rootProduct: IProduct;
}

interface IReduxProps {
    variant: IProduct | null;
    optionValues: IOptionValues;
    quantity: number;
}

interface IDispatchProps {
    onOptionChange: (
        namespace: string,
        code: IOptionCode,
        index: number,
        totalNumValues: number,
        value: IOptionSingleValue,
    ) => void;
    onQuantityChange: (e: React.FormEvent<HTMLSelectElement>) => void;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

export class ProductCardConfiguratorComponent extends React.PureComponent<
    IProps,
    IState
> {
    render() {
        const wrapperClass = classNames({
            [styles.options]: true,
        });
        const optionCodes = [
            ...(this.props.rootProduct.attributes.product_options?.value || []),
        ];
        // If feel option exists, move it to the end (to sort correctly for the UI)
        const hasFeel = optionCodes.indexOf("option_feel");
        if (hasFeel !== -1) {
            optionCodes.push(optionCodes.splice(hasFeel, 1)[0]);
        }
        return (
            <div className={wrapperClass}>
                {optionCodes.map((code) => (
                    <ProductOptionSelectorSet
                        key={code}
                        selectorID={code}
                        rootProduct={this.props.rootProduct}
                        variant={this.props.variant}
                        code={code}
                        values={this.props.optionValues}
                        onOptionChange={this.props.onOptionChange}
                        configuratorType={ConfiguratorTypes.UPDATED}
                        style={"full"}
                    />
                ))}
                <QuantitySelector
                    rootProduct={this.props.rootProduct}
                    quantity={this.props.quantity}
                    onQuantityChange={this.props.onQuantityChange}
                />
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"productgrid2", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.productgrid2 || defaults;
    return {
        ...ownProps,
        variant: getProductVariant([ownProps.rootProduct], state.optionValues),
        optionValues: state.optionValues,
        quantity: state.quantity,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    return {
        onOptionChange: (namespace, code, index, totalNumValues, value) => {
            dispatch<IActionSetOptionValue>({
                type: Action.SET_OPTION_VALUE,
                payload: {
                    namespace: namespace,
                    code: code,
                    index: index,
                    totalNumValues: totalNumValues,
                    value: value,
                },
            });
        },
        onQuantityChange: (event) => {
            const qty = parseInt(event.currentTarget.value, 10);
            dispatch<IActionSetQuantity>({
                type: Action.SET_QUANTITY,
                payload: qty,
            });
        },
    };
};

export const ProductCardConfigurator = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductCardConfiguratorComponent);
