import React from "react";
import { connect } from "react-redux";
import { StoreCategory } from "tsi-common-react/src/api/retail";
import {
    TDispatchMapper,
    TStateMapper,
} from "tsi-common-react/src/apps/reducers.interfaces";
import { IProps as IModalProps } from "tsi-common-react/src/common/Modal";
import {
    PopperOptions,
    UncontrolledPopover,
} from "tsi-common-react/src/common/Popover";
import { Trans } from "tsi-common-react/src/common/Trans";
import { isoMiles } from "tsi-common-react/src/models/nominals";
import { t } from "ttag";

import tabnavAll from "../../img/retail-locator/tabnav-all.jpg";
import tabnavClosest5 from "../../img/retail-locator/tabnav-closest5.png";
import tabnavElite from "../../img/retail-locator/tabnav-elite.png";
import tabnavFlagship from "../../img/retail-locator/tabnav-flagship.jpg";
import { LocatorQuickSearch as LocatorQuickSearchComponent } from "../components/LocatorQuickSearch";
import { Dispatchers } from "../dispatchers";
import { IQuickSearchItem } from "../models.interfaces";

interface IReduxProps {}

interface IOwnProps {
    selectedItem: string | undefined;
    hideElite?: boolean;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

type IProps = IReduxProps & IOwnProps & IDispatchProps;

interface IState {
    hash: string;
}

const flagshipTooltipText = t`Choose from the widest assortment of genuine
    Tempur-Pedic products to discover your perfect bed with assistance from our
    highly experienced sleep experts.`;
const eliteTooltipText = t`Tempur-Pedic Elite Retailers offer a broad
    selection of Tempur-Pedic mattresses and adjustable bases. They are
    recognized for their comprehensive product knowledge, extraordinary
    service, and high level of customer satisfaction.`;

const popperOptions: PopperOptions = {
    modifiers: [
        {
            name: "offset",
            options: {
                offset: [0, 16],
            },
        },
        {
            name: "flip",
            options: {
                fallbackPlacements: ["top", "bottom"],
            },
        },
    ],
    placement: "bottom",
};

const modalProps: Partial<IModalProps> = {
    bodyOpenClassName: "tooltip-component__container",
    overlayClassName: "tooltip-component__overlay",
    className: "tooltip-component",
    contentLabel: t`More Info`,
    style: {
        overlay: {
            backgroundColor: "rgba(255, 255, 255, 0)",
        },
        content: {
            border: "none",
            boxShadow: "none",
        },
    },
};

class LocatorQuickSearchContainer extends React.Component<IProps, IState> {
    state: IState = {
        hash: "",
    };

    private readonly items: IQuickSearchItem[] = [
        {
            id: "flagship",
            title: (
                <>
                    Tempur-Pedic<sup>®</sup> Stores
                </>
            ),
            descr: (
                <Trans
                    fmtString={t`<Popover>Tempur-Pedic Stores</Popover> near you.`}
                    data={{
                        Popover: (content) => (
                            <UncontrolledPopover
                                key="Popover"
                                triggerContent={content}
                                popperOptions={popperOptions}
                                modalProps={modalProps}
                                showArrow={true}
                            >
                                {flagshipTooltipText}
                            </UncontrolledPopover>
                        ),
                    }}
                />
            ),
            labelClassName: "quicksearch__nav-button--tpstore",
            imgSrc: tabnavFlagship,
            imgAlt: t`Tempur-Pedic® Stores`,
            href: "/tempur-pedic-stores/", // TODO: Fix this URL
            defaultFilterState: {
                limit: 0,
                distance: isoMiles.wrap(0),
                category: StoreCategory.FLAGSHIP,
                retailerName: [],
            },
        },
        {
            id: "closest-5",
            title: t`Closest 5 Retailers`,
            descr: t`Closest five retailers based on your location.`,
            labelClassName: "quicksearch__nav-button--closest",
            imgSrc: tabnavClosest5,
            imgAlt: t`Closest 5 Retailers`,
            href: "/find-a-retailer/#closest-5", // TODO: Fix this URL
            defaultFilterState: {
                limit: 5,
                category: null,
                retailerName: [],
            },
        },
        {
            id: "elite",
            title: t`Elite Retailers`,
            descr: (
                <Trans
                    fmtString={t`Find an <Popover>Elite</Popover> retailer near you.`}
                    data={{
                        Popover: (content) => (
                            <UncontrolledPopover
                                key="Popover"
                                triggerContent={content}
                                popperOptions={popperOptions}
                                modalProps={modalProps}
                                showArrow={true}
                            >
                                {eliteTooltipText}
                            </UncontrolledPopover>
                        ),
                    }}
                />
            ),
            labelClassName: "quicksearch__nav-button--elite",
            imgSrc: tabnavElite,
            imgAlt: t`Elite Retailers`,
            href: "/find-a-retailer/#elite", // TODO: Fix this URL
            defaultFilterState: {
                limit: 0,
                category: StoreCategory.ELITE,
                retailerName: [],
            },
        },
        {
            id: "all",
            title: t`All Retailers`,
            descr: t`All retailers in your area.`,
            labelClassName: "quicksearch__nav-button--all",
            imgSrc: tabnavAll,
            imgAlt: t`All Retailers`,
            href: "/find-a-retailer/#all", // TODO: Fix this URL
            defaultFilterState: {
                limit: 0,
                category: null,
                retailerName: [],
            },
        },
    ];

    private readonly getItems = () => {
        if (this.props.hideElite) {
            return this.items.filter((item) => item.id !== "elite");
        } else {
            return this.items;
        }
    };

    private readonly onHashChange = () => {
        const hash = window.location.hash.replace("#", "") || "closest-5";
        this.setState({
            hash: hash,
        });
        const items = this.getItems();
        const selectedItem = items.find((item) => item.id === hash);
        if (selectedItem) {
            this.props.dispatchers.updateStoreFilters(
                selectedItem.defaultFilterState,
            );
        }
    };

    componentDidMount() {
        this.onHashChange();
        window.addEventListener("hashchange", this.onHashChange);
    }

    componentWillUnmount() {
        window.removeEventListener("hashchange", this.onHashChange);
    }

    render() {
        const selectedItem =
            this.props.selectedItem || this.state.hash.replace("#", "");
        return (
            <LocatorQuickSearchComponent
                items={this.getItems()}
                selectedItem={selectedItem}
            />
        );
    }
}

const mapStateToProps: TStateMapper<"retail", IReduxProps, IOwnProps> = (
    _state,
    ownProps,
) => {
    return {
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const LocatorQuickSearch = connect(
    mapStateToProps,
    mapDispatchToProps,
)(LocatorQuickSearchContainer);
