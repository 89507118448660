import React from "react";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";
import {
    nearbyStoreSelector,
    preferredLocationSelector,
} from "tsi-common-react/src/apps/common/selectors";
import { TStateMapper } from "tsi-common-react/src/apps/reducers.interfaces";
import { ILocation } from "tsi-common-react/src/models/location.interfaces";
import { msgid, ngettext, t } from "ttag";

import iconStoreLocatorFooter from "../../svg/store_locator_footer.svg";

interface IOwnProps {}

interface IReduxProps {
    numStores: number;
    location: ILocation | null;
}

type IProps = IReduxProps & IOwnProps;

interface IState {}

class NearbyStoresAdContainer extends React.Component<IProps, IState> {
    private getAdText() {
        if (this.props.location) {
            return ngettext(
                msgid`${this.props.numStores} store near ${this.props.location.formatted_address}`,
                `${this.props.numStores} stores near ${this.props.location.formatted_address}`,
                this.props.numStores,
            );
        }
        return ngettext(
            msgid`${this.props.numStores} store near you`,
            `${this.props.numStores} stores near you`,
            this.props.numStores,
        );
    }

    render() {
        return (
            <div className="footer-widgets__offer">
                <SVG
                    className="footer-widget__icon"
                    src={iconStoreLocatorFooter}
                    title={t`Locate a Store Icon`}
                />
                <div className="footer-widget__content">
                    <h4>
                        <a
                            title={t`Find a Retailer`}
                            href="/find-a-retailer"
                            className="footer-widget__header"
                        >
                            {t`Feel it for yourself`}
                        </a>
                    </h4>
                    <span className="footer-widget__copy">
                        {this.getAdText()}
                    </span>{" "}
                    <a
                        title={t`Find a Retailer`}
                        href="/find-a-retailer"
                        className="text-link footer-widget__link"
                    >
                        {t`See List`}
                    </a>
                </div>
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"retail", IReduxProps, IOwnProps> = (
    state,
    ownProps,
) => {
    const props: IProps = {
        numStores: nearbyStoreSelector(state).length,
        location: preferredLocationSelector(state),
        ...ownProps,
    };
    return props;
};

export const NearbyStoresAd = connect(mapStateToProps)(NearbyStoresAdContainer);
