import React from "react";
import { t } from "ttag";

import styles from "./CancelError.module.scss";

export const CancelError = () => {
    return (
        <p className={styles.root}>
            {t`You are outside of the cancellation window. This order can no longer be cancelled or modified.`}
        </p>
    );
};
