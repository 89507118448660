import React from "react";

import { Modal } from "../../../common/Modal";
import { OrderCancellationInfo } from "../../../models/orders";
import { assertNever } from "../../../utils/never";
import { CancelModalViewState } from "../models";
import { CancelError } from "./CancelError";
import { CancelReasonForm } from "./CancelReasonForm";

import styles from "./CancelModal.module.scss";

interface IProps {
    viewState: CancelModalViewState;
    cancellationInfo: OrderCancellationInfo | null;
    selectedReason: string | null;
    enteredReason: string;
    onSelectReason: (reason: string) => void;
    onEnterReason: (reason: string) => void;
    onRequestClose: () => void;
    onSubmit: () => Promise<void>;
}

const ModalContent = (props: IProps) => {
    switch (props.viewState) {
        case CancelModalViewState.CLOSED:
        case CancelModalViewState.LOADING: {
            return null;
        }
        case CancelModalViewState.SELECT_REASON:
        case CancelModalViewState.SAVING: {
            if (!props.cancellationInfo) {
                return null;
            }
            return (
                <CancelReasonForm
                    {...props}
                    cancellationInfo={props.cancellationInfo}
                />
            );
        }
        case CancelModalViewState.ERROR: {
            return <CancelError />;
        }
    }
    assertNever(props.viewState);
};

export const CancelModal = (props: IProps) => {
    const modalStyle = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "30px 30px 30px 30px",
        },
    };
    return (
        <Modal
            className={styles.root}
            isOpen={props.viewState !== CancelModalViewState.CLOSED}
            onRequestClose={props.onRequestClose}
            style={modalStyle}
        >
            {props.cancellationInfo && <ModalContent {...props} />}
        </Modal>
    );
};
