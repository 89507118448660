import React from "react";

import { FormField } from "./FormField";

interface IProps<T> {
    name: T;
    options: [string, string][];
    onChange: (name: T, value: string) => void;
    value?: string;
    errors?: string[];
    label?: string;
    isRequired?: boolean;
    forwardRef?: (elem: HTMLSelectElement | null) => void;
}

export const SelectInput = <T extends string>(props: IProps<T>) => {
    return (
        <FormField {...props}>
            <select
                name={props.name}
                value={`${props.value}`}
                onChange={(event) => {
                    event.preventDefault();
                    props.onChange(props.name, event.currentTarget.value);
                }}
                required={props.isRequired}
                ref={props.forwardRef}
            >
                {props.options.map(([value, label]) => (
                    <option key={value} value={value}>
                        {label}
                    </option>
                ))}
            </select>
        </FormField>
    );
};
