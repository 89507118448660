import React from "react";

import { IField, IFieldSet, IValueSet } from "../models/formFields.interfaces";
import format from "../utils/format";

import styles from "./FieldsSummary.module.scss";

interface Props<T extends string> {
    step: number;
    heading: string;
    fieldOrder: readonly T[];
    fields: IFieldSet<T>;
    values: IValueSet<T>;
    onSummaryEdit?: (step: number) => void;
}

const getFieldValue = <T extends string>(
    value: string | boolean | undefined,
    settings: IField<T>,
) => {
    // For select fields, use the option label instead of the value.
    if (settings.type === "choice") {
        const choice = (settings.choices || []).find((c) => {
            return c.value === value;
        });
        return choice ? choice.label : value;
    }

    // For boolean fields, return "Yes" or "No".
    if (settings.type === "boolean") {
        return value ? "Yes" : "No";
    }

    // Is there a display formatter selected?
    if (settings.displayFormat && typeof value === "string") {
        return format[settings.displayFormat](value);
    }

    // Convert undefined to empty string
    if (value === undefined) {
        return "";
    }

    // Everything else, just return the value
    return value;
};

const Field = <T extends string>(
    props: Props<T> & {
        name: T;
    },
) => {
    const field = props.fields[props.name];
    const value = getFieldValue(props.values[props.name], field);
    return (
        <div className={styles.summaryLine}>
            <dt>{field.shortLabel || field.label}:</dt>
            <dd>{value}</dd>
        </div>
    );
};

export const FieldsSummary = <T extends string>(props: Props<T>) => {
    const fields = props.fieldOrder.filter((name) => !!props.fields[name]);
    const onEdit = () => {
        if (props.onSummaryEdit) {
            props.onSummaryEdit(props.step);
        }
    };
    return (
        <>
            <h3 className={styles.heading}>
                {props.heading}
                {props.onSummaryEdit && (
                    <button className={styles.editButton} onClick={onEdit}>
                        Edit
                    </button>
                )}
            </h3>
            <dl>
                {fields.map((name) => (
                    <Field key={name} {...props} name={name} />
                ))}
            </dl>
        </>
    );
};
