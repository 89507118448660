import React from "react";
import { connect } from "react-redux";
import { LocationInput } from "tsi-common-react/src/apps/common/containers/LocationInput";
import { TStateMapper } from "tsi-common-react/src/apps/reducers.interfaces";
import { t } from "ttag";

interface IReduxProps {}

interface IOwnProps {
    onSubmit?: () => void;
}

type IProps = IReduxProps & IOwnProps;

interface IState {}

class LocatorSearchContainer extends React.Component<IProps, IState> {
    private readonly onSubmit = async (
        event: React.FormEvent<HTMLFormElement>,
    ) => {
        event.preventDefault();
    };

    render() {
        const locationInputID = "location-autocomplete";
        return (
            <form className="form form--locator" onSubmit={this.onSubmit}>
                <fieldset>
                    <legend className="filters__instruct">
                        {t`Change your location to find retailers in that area.`}
                    </legend>
                    <div className="form__field filters__inputs">
                        <div className="form__input">
                            <label
                                htmlFor={locationInputID}
                                className="ada-screenreader-only"
                            >
                                {t`Location`}
                            </label>
                            <LocationInput
                                inputProps={{
                                    id: locationInputID,
                                    placeholder:
                                        "Enter address, city, state or zip",
                                }}
                                inputFormat="long"
                                onAfterChange={this.props.onSubmit}
                            />
                        </div>
                    </div>
                    <div className="filters__actions">
                        <button
                            className="button filters__button"
                            type="submit"
                            aria-label="Search"
                        ></button>
                    </div>
                </fieldset>
            </form>
        );
    }
}

const mapStateToProps: TStateMapper<"retail", IReduxProps, IOwnProps> = (
    _state,
    ownProps,
) => {
    return {
        ...ownProps,
    };
};

export const LocatorSearch = connect(mapStateToProps, null, null, {
    forwardRef: true,
})(LocatorSearchContainer);
