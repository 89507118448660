import React from "react";
import SVG from "react-inlinesvg";
import { BreakPoint } from "tsi-common-react/src/models/screen.interfaces";
import { t } from "ttag";

import iconCaretDown from "../../../svg/caret-down.svg";
import iconCaretUp from "../../../svg/caret-up.svg";
import { IMenuCategoryLink } from "../../menu.interface";

interface IProps {
    isMobile: boolean;
    linkID: number;
    linkObject: IMenuCategoryLink;
    openModal: (
        event: React.MouseEvent<HTMLElement>,
        linkObject: IMenuCategoryLink,
    ) => void;
    openedLink?: string;
}

export class CategoryLink extends React.Component<
    IProps,
    Record<string, never>
> {
    render() {
        if (this.props.isMobile) {
            // To make the mobile subnav not tab focusable so that trapFocus from helpers.ts works properly,
            // only render the mobile subnav at the mobile breakpoint.
            if (window.innerWidth > BreakPoint.MEDIUM) {
                return null;
            }
            return (
                <button
                    key={this.props.linkID}
                    aria-label={t`Toggle ${this.props.linkObject.value.title} Menu`}
                    aria-haspopup="dialog"
                    className="main-dropdown-menu__mobile main-dropdown-menu__mobile-link"
                    onClick={(e) => {
                        this.props.openModal(e, this.props.linkObject);
                    }}
                    data-subnav-link={this.props.linkID}
                    tabIndex={0}
                >
                    {this.props.linkObject.value.title}{" "}
                    <SVG
                        src={iconCaretDown}
                        className="main-dropdown-menu__mobile-shop-icon"
                        title={t`Show Menu Icon`}
                        aria-hidden="true"
                    />
                </button>
            );
        }
        return (
            <button
                key={this.props.linkID}
                title={t`Toggle ${this.props.linkObject.value.title} Menu`}
                aria-haspopup="dialog"
                onClick={(e) => {
                    this.props.openModal(e, this.props.linkObject);
                }}
                className="site-nav__main-menu-item"
                tabIndex={0}
            >
                {this.props.linkObject.value.title}{" "}
                {this.props.openedLink === this.props.linkObject.value.title ? (
                    <SVG
                        src={iconCaretUp}
                        className="site-nav__shop-icon"
                        title={t`Show Menu Icon`}
                        aria-hidden="true"
                    />
                ) : (
                    <SVG
                        src={iconCaretDown}
                        className="site-nav__shop-icon"
                        title={t`Show Menu Icon`}
                        aria-hidden="true"
                    />
                )}
            </button>
        );
    }
}
