import { NumeralThousandGroupStyles } from "cleave-zen";
import { produce } from "immer";

import { strings } from "../../../localization";
import {
    BackendType,
    FinancingApplication,
    FinancingApplicationFields,
} from "../../../models/financing";
import { IFieldSet } from "../../../models/formFields.interfaces";
import { FormStepFieldSet, FormStepType } from "../../../models/forms";
import { objectKeys } from "../../../utils/functional";
import { HousingStatus, STATE_CHOICES } from "../constants";

import styles from "../common.module.css";

const INCOME_NOTICE =
    "*You need not list alimony, child support, or separate maintenance payments unless you wish it considered as a basis for repaying this obligation. <b>You may include all sources of income that you earn or own, including funds regularly deposited into accounts you own. If you are age 21 or older, you may include accessible income which is not earned or owned by you but is regularly accessed or used to pay your expenses (which could include your spouse’s income, investment income, etc.).</b>";
const FORTIVA_INCOME_NOTICE =
    "*Alimony, child support or separate maintenance income need not be included if you do not wish to have it considered as a basis for repaying this obligation.<br/><br/><b><i>Wisconsin Married Residents:</i></b> Combine your and your spouse's information on this application.";
const EMPLOYER_PHONE_NOTICE =
    "If no employer phone number, please provide a phone number where you can be reached.";

const WISCONSIN_STATE_CODE = "WI";
const WISCONSIN_WARNING_TITLE = "MARRIED WISCONSIN RESIDENTS:";
const WISCONSIN_WARNING_TEXT =
    "If you are applying either individually or jointly with someone other than your spouse, please contact us immediately at: 1-855-412-2787 and provide " +
    "us with the name and address of your spouse. We are required by law to inform your spouse that you have opened an account with us.";

export const ADDRESS_TOOL_TIP_LINK = "what is this?";
export const ADDRESS_TOOL_TIP_TEXT =
    "To comply with the requirements of the USA Patriot Act, we cannot accept a PO Box as your home/permanent address (or business address, if applicable). " +
    "We use this information for verification purposes.";

export const EMAIL_TOOL_TIP_LINK = "Why do we need this?";
export const EMAIL_TOOL_TIP_TEXT =
    "Email address will only be used to contact you regarding your pre-qualification status.";

const SSN_TOOL_TIP_LINK = "why do you need this?";
const SSN_TOOL_TIP_TEXT =
    "We ask for your Social Security number to verify your identity and obtain credit history for processing your application.";

const housingStatusChoices = objectKeys(HousingStatus).map((key) => {
    return {
        label: HousingStatus[key],
        value: HousingStatus[key],
    };
});

const fields = {
    // application_source: {
    //     helpText:
    //         "Where/how is user applying? E.g. Website, Call Center, In-Store, etc.",
    //     label: "Application Source",
    //     maxLength: 25,
    //     readOnly: false,
    //     required: false,
    //     type: "string",
    // },
    // language_preference: {
    //     choices: [
    //         {
    //             label: "English",
    //             value: "E",
    //         },
    //         {
    //             label: "Spanish",
    //             value: "S",
    //         },
    //     ],
    //     helpText: "The main applicant’s language preference values",
    //     label: "Language Preference",
    //     readOnly: false,
    //     required: false,
    //     type: "choice",
    // },
    requested_credit_limit: {
        name: "requested_credit_limit",
        max: 99999,
        min: 0,
        readOnly: false,
        required: false,
        type: "integer",
        label: "Estimated Purchase Price",
        shortLabel: "Est. Price",
        helpText: strings.get("FINANCING_PURCHASE_PRICE_NOTICE") || "",
        displayFormat: "money",
    },
    // salesperson: {
    //     helpText: "Alphanumeric value associated with the salesperson.",
    //     label: "Sales Person ID",
    //     maxLength: 10,
    //     readOnly: false,
    //     required: false,
    //     type: "string",
    // },
    main_applicant__address__address_line_1: {
        name: "main_applicant__address__address_line_1",
        maxLength: 26,
        readOnly: false,
        required: true,
        type: "string",
        label: "Physical Street Address",
        shortLabel: "Address Line 1",
        tooltipLink: ADDRESS_TOOL_TIP_LINK,
        tooltipText: ADDRESS_TOOL_TIP_TEXT,
        tooltipClass: "tooltip-main-address",
        validation: "street-address-empty",
        autoComplete: "address-line1",
    },
    main_applicant__address__address_line_2: {
        name: "main_applicant__address__address_line_2",
        label: "Address Line 2",
        maxLength: 26,
        readOnly: false,
        required: false,
        type: "string",
    },
    main_applicant__address__city: {
        name: "main_applicant__address__city",
        label: "City",
        maxLength: 18,
        readOnly: false,
        required: true,
        type: "string",
        validation: ["city-empty"],
        autoComplete: "address-level2",
    },
    main_applicant__address__postal_code: {
        name: "main_applicant__address__postal_code",
        maxLength: 10,
        minLength: 5,
        readOnly: false,
        required: true,
        type: "string",
        label: "ZIP Code",
        validation: "zipcode",
        autoComplete: "postal-code",
    },
    main_applicant__address__state_code: {
        name: "main_applicant__address__state_code",
        choices: STATE_CHOICES,
        label: "State",
        readOnly: false,
        required: true,
        type: "choice",
        validation: ["state-empty"],
        autoComplete: "address-level1",
    },
    main_applicant__annual_income: {
        name: "main_applicant__annual_income",
        readOnly: false,
        required: true,
        label: "Net Annual Income*",
        shortLabel: "Income",
        helpText: INCOME_NOTICE,
        displayFormat: "money",
        type: "string",
        formatOptions: {
            type: "numeral",
            numeralThousandsGroupStyle: NumeralThousandGroupStyles.THOUSAND,
        },
        validation: "annual-income-empty",
    },
    main_applicant__date_of_birth: {
        name: "main_applicant__date_of_birth",
        readOnly: false,
        type: "date",
        required: true,
        label: "Date of Birth",
        placeholder: "MM/DD/YYYY",
        validation: "date",
        maxLength: 10,
        minLength: 10,
        formatOptions: {
            type: "date",
            datePattern: ["m", "d", "Y"],
        },
        autoComplete: "bday",
    },
    main_applicant__email_address: {
        name: "main_applicant__email_address",
        label: "Email",
        maxLength: 50,
        minLength: 7,
        readOnly: false,
        type: "email",
        required: true,
        placeholder: "myemail@email.com",
        validation: "email",
        autoComplete: "email",
        tooltipLink: EMAIL_TOOL_TIP_LINK,
        tooltipText: EMAIL_TOOL_TIP_TEXT,
        tooltipClass: styles.tooltipIcon,
    },
    main_applicant__employer_name: {
        name: "main_applicant__employer_name",
        label: "Employer Name",
        maxLength: 30,
        readOnly: false,
        required: false,
        type: "string",
    },
    main_applicant__first_name: {
        name: "main_applicant__first_name",
        label: "First Name",
        maxLength: 15,
        readOnly: false,
        required: true,
        type: "string",
        validation: "firstname-empty",
        autoComplete: "given-name",
    },
    main_applicant__home_phone: {
        name: "main_applicant__home_phone",
        type: "phone",
        label: "Phone Number",
        validation: "phone",
        autoComplete: "tel",
        readOnly: false,
        required: true,
    },
    main_applicant__housing_status: {
        name: "main_applicant__housing_status",
        choices: housingStatusChoices,
        label: "Housing Status",
        readOnly: false,
        required: false,
        type: "choice",
        shortLabel: "Housing",
    },
    main_applicant__last_name: {
        name: "main_applicant__last_name",
        label: "Last Name",
        maxLength: 20,
        readOnly: false,
        required: true,
        type: "string",
        validation: ["lastname", "lastname-empty"],
        autoComplete: "family-name",
    },
    main_applicant__middle_initial: {
        name: "main_applicant__middle_initial",
        maxLength: 1,
        readOnly: false,
        required: false,
        type: "string",
        label: "M.I.",
        validation: "middle-initial",
        autoComplete: "additional-name",
    },
    main_applicant__mobile_phone: {
        name: "main_applicant__mobile_phone",
        label: "Mobile Phone",
        maxLength: 128,
        readOnly: false,
        required: false,
        type: "string",
    },
    main_applicant__ssn: {
        name: "main_applicant__ssn",
        label: "Social Security Number/Individual Taxpayer Identification Number",
        maxLength: 11,
        readOnly: false,
        required: true,
        type: "string",
        shortLabel: "SSN",
        placeholder: "XXX-XX-XXXX",
        tooltipLink: SSN_TOOL_TIP_LINK,
        tooltipText: SSN_TOOL_TIP_TEXT,
        tooltipClass: "tooltip-main-ssn",
        validation: "ssn",
        displayFormat: "maskedSSN",
        formatOptions: {
            type: "general",
            delimiter: "-",
            blocks: [3, 2, 4],
        },
    },
    main_applicant__work_phone: {
        name: "main_applicant__work_phone",
        label: "Work Phone",
        readOnly: false,
        required: false,
        shortLabel: "Employer Phone",
        type: "phone",
        helpText: EMPLOYER_PHONE_NOTICE,
        validation: "phone",
        autoComplete: "tel",
    },

    joint_applicant__address__address_line_1: {
        name: "joint_applicant__address__address_line_1",
        maxLength: 26,
        readOnly: false,
        required: true,
        type: "string",
        label: "Physical Street Address",
        shortLabel: "Address Line 1",
        tooltipLink: ADDRESS_TOOL_TIP_LINK,
        tooltipText: ADDRESS_TOOL_TIP_TEXT,
        tooltipClass: "tooltip-joint-address",
        validation: "street-address-empty",
        autoComplete: "address-line1",
    },
    joint_applicant__address__address_line_2: {
        name: "joint_applicant__address__address_line_2",
        label: "Address Line 2",
        maxLength: 26,
        readOnly: false,
        required: false,
        type: "string",
    },
    joint_applicant__address__city: {
        name: "joint_applicant__address__city",
        label: "City",
        maxLength: 18,
        readOnly: false,
        required: true,
        type: "string",
        validation: ["city-empty"],
        autoComplete: "address-level2",
    },
    joint_applicant__address__postal_code: {
        name: "joint_applicant__address__postal_code",
        maxLength: 10,
        minLength: 5,
        readOnly: false,
        required: true,
        type: "string",
        label: "ZIP Code",
        validation: "zipcode",
        autoComplete: "postal-code",
    },
    joint_applicant__address__state_code: {
        name: "joint_applicant__address__state_code",
        choices: STATE_CHOICES,
        label: "State",
        readOnly: false,
        required: true,
        type: "choice",
        validation: ["state-empty"],
        autoComplete: "address-level1",
    },
    joint_applicant__annual_income: {
        name: "joint_applicant__annual_income",
        readOnly: false,
        required: true,
        label: "Net Annual Income*",
        shortLabel: "Income",
        helpText: INCOME_NOTICE,
        displayFormat: "money",
        type: "string",
        formatOptions: {
            type: "numeral",
            numeralThousandsGroupStyle: NumeralThousandGroupStyles.THOUSAND,
        },
        validation: "annual-income-empty",
    },
    joint_applicant__date_of_birth: {
        name: "joint_applicant__date_of_birth",
        readOnly: false,
        type: "date",
        required: true,
        label: "Date of Birth",
        placeholder: "MM/DD/YYYY",
        validation: "date",
        maxLength: 10,
        minLength: 10,
        formatOptions: {
            type: "date",
            datePattern: ["m", "d", "Y"],
        },
        autoComplete: "bday",
    },
    joint_applicant__email_address: {
        name: "joint_applicant__email_address",
        label: "Email",
        maxLength: 50,
        minLength: 7,
        readOnly: false,
        type: "email",
        required: true,
        placeholder: "myemail@email.com",
        validation: "email",
        autoComplete: "email",
    },
    joint_applicant__employer_name: {
        name: "joint_applicant__employer_name",
        label: "Employer Name",
        maxLength: 30,
        readOnly: false,
        required: false,
        type: "string",
    },
    joint_applicant__first_name: {
        name: "joint_applicant__first_name",
        label: "First Name",
        maxLength: 15,
        readOnly: false,
        required: true,
        type: "string",
        validation: "firstname-empty",
        autoComplete: "given-name",
    },
    joint_applicant__home_phone: {
        name: "joint_applicant__home_phone",
        readOnly: false,
        required: true,
        type: "phone",
        label: "Phone Number",
        validation: "phone",
        autoComplete: "tel",
    },
    joint_applicant__housing_status: {
        name: "joint_applicant__housing_status",
        choices: housingStatusChoices,
        label: "Housing Status",
        readOnly: false,
        required: false,
        type: "choice",
        shortLabel: "Housing",
    },
    joint_applicant__last_name: {
        name: "joint_applicant__last_name",
        label: "Last Name",
        maxLength: 20,
        readOnly: false,
        required: true,
        type: "string",
        validation: ["lastname", "lastname-empty"],
        autoComplete: "family-name",
    },
    joint_applicant__middle_initial: {
        name: "joint_applicant__middle_initial",
        maxLength: 1,
        readOnly: false,
        required: false,
        type: "string",
        label: "M.I.",
        validation: "middle-initial",
        autoComplete: "additional-name",
    },
    joint_applicant__mobile_phone: {
        name: "joint_applicant__mobile_phone",
        label: "Mobile Phone",
        maxLength: 128,
        readOnly: false,
        required: false,
        type: "string",
    },
    joint_applicant__ssn: {
        name: "joint_applicant__ssn",
        label: "Social Security Number",
        maxLength: 11,
        readOnly: false,
        required: true,
        type: "string",
        shortLabel: "SSN",
        placeholder: "XXX-XX-XXXX",
        tooltipLink: SSN_TOOL_TIP_LINK,
        tooltipText: SSN_TOOL_TIP_TEXT,
        tooltipClass: "tooltip-joint-ssn",
        validation: "ssn",
        displayFormat: "maskedSSN",
        formatOptions: {
            type: "general",
            delimiter: "-",
            blocks: [3, 2, 4],
        },
    },
    joint_applicant__work_phone: {
        name: "joint_applicant__work_phone",
        readOnly: false,
        required: false,
        shortLabel: "Employer Phone",
        type: "phone",
        helpText: EMPLOYER_PHONE_NOTICE,
        validation: "phone",
        autoComplete: "tel",
    },
} as const satisfies IFieldSet<FinancingApplicationFields>;

const stateFields = [
    "main_applicant__address__state_code",
    "joint_applicant__address__state_code",
] as const satisfies FinancingApplicationFields[];

const incomeField =
    "main_applicant__annual_income" as const satisfies FinancingApplicationFields;

export const getFields = (
    values: Readonly<FinancingApplication>,
    backendType?: BackendType,
) => {
    return produce(fields as IFieldSet<FinancingApplicationFields>, (draft) => {
        // Add Wisconsin disclosure for main and joint applicant
        for (const stateField of stateFields) {
            if (
                values[stateField] === WISCONSIN_STATE_CODE &&
                backendType === BackendType.WELLSFARGO
            ) {
                draft[stateField].warningTitle = WISCONSIN_WARNING_TITLE;
                draft[stateField].warningText = WISCONSIN_WARNING_TEXT;
            }
        }
        // Add Fortiva-specific income notice
        if (backendType === BackendType.FORTIVA) {
            draft[incomeField].helpText = FORTIVA_INCOME_NOTICE;
        }
    });
};

type ArrayMember<T extends string[]> = T[number];

const mainAppFieldsContactFields = [
    "main_applicant__first_name",
    "main_applicant__middle_initial",
    "main_applicant__last_name",
    "main_applicant__address__address_line_1",
    "main_applicant__address__address_line_2",
    "main_applicant__address__city",
    "main_applicant__address__state_code",
    "main_applicant__address__postal_code",
    "main_applicant__home_phone",
    "main_applicant__email_address",
] as const satisfies FinancingApplicationFields[];

export const mainAppFieldsContact: FormStepFieldSet<
    ArrayMember<typeof mainAppFieldsContactFields>
> = {
    stepType: FormStepType.FieldSet,
    groupTitle: "Contact Information",
    fields: mainAppFieldsContactFields,
};

const mainAppFieldsFinanceFields = [
    "main_applicant__annual_income",
    "main_applicant__housing_status",
    "main_applicant__work_phone",
    "requested_credit_limit",
] as const satisfies FinancingApplicationFields[];

export const mainAppFieldsFinance: FormStepFieldSet<
    ArrayMember<typeof mainAppFieldsFinanceFields>
> = {
    stepType: FormStepType.FieldSet,
    groupTitle: "Financial Information",
    fields: mainAppFieldsFinanceFields,
};

const mainAppFieldsIDFields = [
    "main_applicant__ssn",
    "main_applicant__date_of_birth",
] as const satisfies FinancingApplicationFields[];

export const mainAppFieldsID: FormStepFieldSet<
    ArrayMember<typeof mainAppFieldsIDFields>
> = {
    stepType: FormStepType.FieldSet,
    groupTitle: "Identification Information",
    fields: mainAppFieldsIDFields,
};

const jointAppFieldsContactFields = [
    "joint_applicant__first_name",
    "joint_applicant__middle_initial",
    "joint_applicant__last_name",
    "joint_applicant__address__address_line_1",
    "joint_applicant__address__address_line_2",
    "joint_applicant__address__city",
    "joint_applicant__address__state_code",
    "joint_applicant__address__postal_code",
    "joint_applicant__home_phone",
    "joint_applicant__email_address",
] as const satisfies FinancingApplicationFields[];

export const jointAppFieldsContact: FormStepFieldSet<
    ArrayMember<typeof jointAppFieldsContactFields>
> = {
    stepType: FormStepType.FieldSet,
    groupTitle: "Joint Contact Information",
    fields: jointAppFieldsContactFields,
};

const jointAppFieldsFinanceFields = [
    "joint_applicant__annual_income",
    "joint_applicant__housing_status",
    "joint_applicant__work_phone",
] as const satisfies FinancingApplicationFields[];
export const jointAppFieldsFinance: FormStepFieldSet<
    ArrayMember<typeof jointAppFieldsFinanceFields>
> = {
    stepType: FormStepType.FieldSet,
    groupTitle: "Joint Financial Information",
    fields: jointAppFieldsFinanceFields,
};

const jointAppFieldsIDFields = [
    "joint_applicant__ssn",
    "joint_applicant__date_of_birth",
] as const satisfies FinancingApplicationFields[];
export const jointAppFieldsID: FormStepFieldSet<
    ArrayMember<typeof jointAppFieldsIDFields>
> = {
    stepType: FormStepType.FieldSet,
    groupTitle: "Joint Identification Information",
    fields: jointAppFieldsIDFields,
};
