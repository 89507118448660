import memoize from "memoize-one";
import React from "react";
import { connect } from "react-redux";
import { preferredLocationSelector } from "tsi-common-react/src/apps/common/selectors";
import {
    TDispatchMapper,
    TStateMapper,
} from "tsi-common-react/src/apps/reducers.interfaces";
import { IStoreSearchFilters } from "tsi-common-react/src/apps/retail/models.interfaces";
import {
    filteredStoreSelector,
    focusedStoreIDSelector,
    storeSelector,
} from "tsi-common-react/src/apps/retail/selectors";
import {
    ILocation,
    IRetailStoreWithDistance,
} from "tsi-common-react/src/models/location.interfaces";
import { ISyncStoreID } from "tsi-common-react/src/models/nominals";
import { check } from "tsi-common-react/src/models/utils";

import { DistanceFilter } from "../components/LocatorFilters_Distance";
import { LocationFilter } from "../components/LocatorFilters_Location";
import { LocatorNearestStores as LocatorNearestStoresComponent } from "../components/LocatorNearestStores";
import { Dispatchers } from "../dispatchers";
import { FeaturedRetailerLocationsBlockConfig } from "../models";
import { IFeaturedRetailerLocationsBlockConfig } from "../models.interfaces";

interface IOwnProps {
    configJSON: string;
}

interface IReduxProps {
    filterValues: IStoreSearchFilters;
    currentLocation: ILocation | null;
    allStores: IRetailStoreWithDistance[];
    filteredStores: IRetailStoreWithDistance[];
    focusedStoreID: ISyncStoreID | null;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

type IProps = IReduxProps & IOwnProps & IDispatchProps;

interface IState {}

class FeaturedRetailerLocationsContainer extends React.Component<
    IProps,
    IState
> {
    private readonly parseConfigJSON = memoize(
        (configJSON: string): IFeaturedRetailerLocationsBlockConfig => {
            const data = check(
                FeaturedRetailerLocationsBlockConfig.decode(
                    JSON.parse(configJSON),
                ),
            );
            return data;
        },
    );

    componentDidMount() {
        const config = this.parseConfigJSON(this.props.configJSON);
        this.props.dispatchers.updateStoreFilters({
            retailerName: config.displayed_retailers,
        });
    }

    render() {
        const config = this.parseConfigJSON(this.props.configJSON);
        return (
            <div className="locator">
                <section className="default-form-container bh-sl-form-container filters l-capped-width">
                    <p className="filters__filters">
                        <span className="filters__results">
                            <strong className="bh-sl-total-results">
                                {this.props.filteredStores.length}
                            </strong>{" "}
                            results
                        </span>{" "}
                        for {config.retailer_name} within{" "}
                        <DistanceFilter
                            filterValues={this.props.filterValues}
                            onChange={this.props.dispatchers.updateStoreFilters}
                        />{" "}
                        of{" "}
                        <LocationFilter
                            filterValues={this.props.filterValues}
                            currentLocation={this.props.currentLocation}
                            onChange={this.props.dispatchers.updateStoreFilters}
                        />
                    </p>
                </section>
                <section className="locations l-capped-width">
                    <LocatorNearestStoresComponent
                        stores={this.props.filteredStores}
                        focusedStoreID={this.props.focusedStoreID}
                        onSelectStore={this.props.dispatchers.setFocusedStore}
                        showMore={false}
                        disableGrouping={true}
                        noResultsText={config.retailer_name}
                    />
                </section>
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"retail", IReduxProps, IOwnProps> = (
    state,
    ownProps,
) => {
    return {
        filterValues: state.retail.storeFilters,
        currentLocation: preferredLocationSelector(state),
        allStores: storeSelector(state),
        filteredStores: filteredStoreSelector(state),
        focusedStoreID: focusedStoreIDSelector(state),
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const FeaturedRetailerLocations = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FeaturedRetailerLocationsContainer);
