import React from "react";
import { FinancingModalTrigger } from "tsi-common-react/src/apps/financing/FinancingModalTrigger";
import { t } from "ttag";

interface IProps {
    classString: string;
}

interface IState {}

export class FinancingModalTriggerCheckNow extends React.Component<
    IProps,
    IState
> {
    render() {
        return (
            <span>
                <FinancingModalTrigger
                    modalType="prequal-app"
                    className={this.props.classString}
                >
                    {t`Check Now`}
                </FinancingModalTrigger>
            </span>
        );
    }
}
