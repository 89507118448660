import React from "react";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";
import { LocationInput } from "tsi-common-react/src/apps/common/containers/LocationInput";
import { preferredLocationSelector } from "tsi-common-react/src/apps/common/selectors";
import { TStateMapper } from "tsi-common-react/src/apps/reducers.interfaces";
import { ILocation } from "tsi-common-react/src/models/location.interfaces";
import { t } from "ttag";

import iconIconSearch from "../../svg/icon-search.svg";

interface IReduxProps {
    currentLocation: ILocation | null;
}

interface IOwnProps {
    classNames: string;
}

type IProps = IReduxProps & IOwnProps;

interface IState {}

class PDPModalLocationSearchContainer extends React.Component<IProps, IState> {
    state: IState = {};

    private readonly onSubmit = async (
        event: React.FormEvent<HTMLFormElement>,
    ) => {
        event.preventDefault();
    };

    render() {
        const locationInputID = "pdp-modal-location-search-input";
        return (
            <div>
                <form onSubmit={this.onSubmit}>
                    <label
                        className="pdp-modal__label ada-screenreader-only"
                        htmlFor={locationInputID}
                    >
                        {t`Location`}
                    </label>
                    <LocationInput
                        inputProps={{
                            id: locationInputID,
                            name: "location",
                            placeholder: "Your Location",
                            className: this.props.classNames,
                        }}
                        inputFormat="short"
                    />
                    <button
                        className="pdp-modal__search-button"
                        type="submit"
                        aria-label={t`search`}
                    >
                        <SVG
                            className="pdp-modal__search-icon"
                            title={t`Search Icon`}
                            aria-labelledby="title"
                            src={iconIconSearch}
                        />
                    </button>
                </form>
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"retail", IReduxProps, IOwnProps> = (
    state,
    ownProps,
) => {
    return {
        ...ownProps,
        currentLocation: preferredLocationSelector(state),
    };
};

export const PDPModalLocationSearch = connect(mapStateToProps)(
    PDPModalLocationSearchContainer,
);
