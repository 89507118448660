import React from "react";
import { Provider } from "react-redux";
import * as checkoutAPI from "tsi-common-react/src/api/checkout";
import { getChatConnector } from "tsi-common-react/src/apps/chat/index";
import { registerCheckoutCascades } from "tsi-common-react/src/apps/checkout/cascades-checkout";
import { registerCommonCascades } from "tsi-common-react/src/apps/checkout/cascades-main";
import { BasketLineVariant } from "tsi-common-react/src/apps/checkout/constants";
import { BasketMenuIcon } from "tsi-common-react/src/apps/checkout/containers/BasketMenuIcon";
import { Dispatchers as CheckoutDispatchers } from "tsi-common-react/src/apps/checkout/dispatchers";
import { Loaders as CheckoutLoaders } from "tsi-common-react/src/apps/checkout/loaders";
import {
    openApplyModal,
    openPreQualModal,
} from "tsi-common-react/src/apps/financing/reducers";
import * as signals from "tsi-common-react/src/apps/signals";
import { isoWebPageURL } from "tsi-common-react/src/models/nominals";
import { onReadyStateComplete } from "tsi-common-react/src/utils/events";
import { strToBool } from "tsi-common-react/src/utils/format";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";
import {
    getPageSetting,
    isPageInsideIFrame as isPageInsideIFrameCheck,
} from "tsi-common-react/src/utils/settings";
import { urls } from "tsi-common-react/src/utils/urls";

import { financingCCs } from "../../../../../libs/tsi-common-react/src/apps/checkout/components/FinancingModalTriggerFinance";
import {
    FinancingCardType,
    FinancingModalTriggerTheme,
} from "../../../../../libs/tsi-common-react/src/constants";
import { persistor, rehydratingStore, store } from "../store";
import { FinancingModalTriggerRetailModalFinance } from "./components/FinancingModalTriggerRetailModalFinance";
import { FinancingModalTriggerRetailModalPreapproval } from "./components/FinancingModalTriggerRetailModalPreapproval";

// ============================================================================
// Pop-up Chat Integrations
// ============================================================================
signals.financing.onApproval.on(() => {
    if (strToBool(getPageSetting("chat-enabled"))) {
        getChatConnector().triggerCampaign(
            getPageSetting("chat-wf-campaign-key"),
        );
    }
});

// Automatically send the basket ID to the chat agent when a user starts a chat session
store.subscribe(() => {
    const state = store.getState();
    const msg = state.checkout.data.basket
        ? `[Website] This customer's shopping cart ID is ${state.checkout.data.basket.encoded_basket_id}`
        : null;
    getChatConnector().registerChatAgentMessage("basket-id", msg);
});

// ============================================================================
// Setup Redux Store / Redux Event Dispatchers
// ============================================================================
export const dispatchers = new CheckoutDispatchers(store.dispatch);
export const loaders = new CheckoutLoaders(dispatchers);

// ============================================================================
// Component builder functions used by other applications
// ============================================================================
export const buildRetailModalPreapprovalTrigger = (
    onRequestParentClose: () => void,
) => {
    return (
        <Provider store={store}>
            <FinancingModalTriggerRetailModalPreapproval
                onRequestParentClose={onRequestParentClose}
            />
        </Provider>
    );
};

export const buildRetailModalFinancingTrigger = () => {
    return (
        <Provider store={store}>
            <FinancingModalTriggerRetailModalFinance />
        </Provider>
    );
};

// ============================================================================
// Render checkout app components
// ============================================================================
/* eslint-disable-next-line no-async-promise-executor */
const componentsRendering = new Promise<void>(async (resolve) => {
    // Wait for Redux store to finish loading
    await rehydratingStore;

    // Register store cascades
    registerCommonCascades(store);

    // Render Pre Approval Triggers for Homepage
    dynamicPlaceComponent(
        '[data-place-react="financing-modal-trigger--block"]',
        async (elem) => {
            const { FinancingModalTriggerBlock } = await import(
                "tsi-common-react/src/apps/financing/FinancingModalTriggerBlock"
            );
            const applicationSource =
                elem.dataset.applicationSource || "Financing Page";
            return (
                <Provider store={store}>
                    <FinancingModalTriggerBlock
                        cardType={FinancingCardType.TEMPUR_CC}
                        theme={
                            (elem.dataset
                                .theme as FinancingModalTriggerTheme) ||
                            FinancingModalTriggerTheme.DEFAULT
                        }
                        aprInfoID={"#apr-information"}
                        applicationSource={applicationSource}
                        financeLink={urls.pageURL("finance-link")}
                    />
                </Provider>
            );
        },
    );

    dynamicPlaceComponent(
        '[data-place-react="financing-modal-trigger--homepage"]',
        async (elem) => {
            const { FinancingModalTriggerHomepage } = await import(
                "./components/FinancingModalTriggerHomepage"
            );
            const applicationSource =
                elem.dataset.applicationSource || "Toolbox";
            return (
                <Provider store={store}>
                    <FinancingModalTriggerHomepage
                        applicationSource={applicationSource}
                    />
                </Provider>
            );
        },
    );

    // Render Pre Approval Trigger for Finance Page Header
    dynamicPlaceComponent(
        '[data-place-react="financing-modal-trigger--check-now"]',
        async (elem) => {
            const { FinancingModalTriggerCheckNow } = await import(
                "./components/FinancingModalTriggerCheckNow"
            );
            const classNames = elem.dataset.extraClasses as string;
            return (
                <Provider store={store}>
                    <FinancingModalTriggerCheckNow classString={classNames} />
                </Provider>
            );
        },
    );

    // Render Pre Approval Trigger for Finance Page
    dynamicPlaceComponent(
        '[data-place-react="financing-modal-trigger--finance-page"]',
        async () => {
            const { FinancingModalTriggerFinance } = await import(
                "tsi-common-react/src/apps/checkout/components/FinancingModalTriggerFinance"
            );
            return (
                <Provider store={store}>
                    <FinancingModalTriggerFinance
                        applicationSource="Financing Page"
                        ccSource={financingCCs.TEMPUR}
                    />
                </Provider>
            );
        },
    );

    // Render Calculator on Finance Page
    dynamicPlaceComponent(
        '[data-place-react="finance-calculator"]',
        async () => {
            const { FinanceCalculator } = await import(
                "./components/FinanceCalculator"
            );
            return (
                <Provider store={store}>
                    <FinanceCalculator />
                </Provider>
            );
        },
    );

    // Render Cart Reference Number on Chekout Page
    dynamicPlaceComponent(
        '[data-place-react="cart-reference-number"]',
        async () => {
            const { CartReferenceNumber } = await import(
                "./components/CartReferenceNumber"
            );
            return (
                <Provider store={store}>
                    <CartReferenceNumber />
                </Provider>
            );
        },
    );

    // Render basket menu icons
    dynamicPlaceComponent(
        '[data-place-react="basket-menu-icon"]',
        async (elem) => {
            const { FinancingModalTriggerMinicart } = await import(
                "./components/FinancingModalTriggerMinicart"
            );
            const className = elem.dataset.extraClasses;
            const enableBasketPrequalSDK = strToBool(
                getPageSetting("show-prequal-cart"),
            );
            const basketURL = urls.pageURL("basket-summary");
            const checkoutURL = urls.pageURL("checkout-index");
            const isPageInsideIFrame = isPageInsideIFrameCheck();
            return (
                <Provider store={store}>
                    <BasketMenuIcon
                        className={className}
                        basketURL={basketURL}
                        checkoutURL={checkoutURL}
                        isPageInsideIFrame={isPageInsideIFrame}
                        basketLineVariant={BasketLineVariant.MINIMAL_ENHANCED}
                        buildPromoComponents={(closeModal) => {
                            if (!enableBasketPrequalSDK) {
                                return null;
                            }
                            return (
                                <Provider store={store}>
                                    <FinancingModalTriggerMinicart
                                        closeModal={closeModal}
                                    />
                                </Provider>
                            );
                        }}
                    />
                </Provider>
            );
        },
    );

    // Render Basket Application
    dynamicPlaceComponent("#basket-app", async () => {
        const { Basket } = await import(
            "tsi-common-react/src/apps/checkout/containers/Basket"
        );
        const { getLineProductUpsell } = await import(
            "./components/BasketLineSuggestions"
        );
        const { BasketSidebarContent } = await import(
            "./components/BasketSidebarContent"
        );
        const { BasketFinePrint } = await import(
            "./components/BasketFinePrint"
        );
        const disableFreeShippingMessage = strToBool(
            getPageSetting("disable-basket-free-shipping-message"),
        );
        return (
            <Provider store={store}>
                <Basket
                    getLineProductUpsell={getLineProductUpsell}
                    showValueProps={true}
                    getExtraSummaryContent={() => {
                        return null;
                    }}
                    getExtraSidebarContent={(data, isMobile) => {
                        const cartID = data.basket
                            ? data.basket.encoded_basket_id
                            : "";
                        return (
                            <BasketSidebarContent
                                plans={data.financing_plans}
                                isMobile={isMobile}
                                cartID={cartID}
                            />
                        );
                    }}
                    getExtraMainColumnContent={(data, isMobile) => {
                        if (isMobile) {
                            return null;
                        }
                        return <BasketFinePrint plans={data.financing_plans} />;
                    }}
                    disableFreeShippingMessage={disableFreeShippingMessage}
                    predictedDeliveryDates={getPageSetting(
                        "predicted-delivery-date-display-type",
                    )}
                    preferredDeliveryDates={getPageSetting(
                        "preferred-delivery-date-display-type",
                    )}
                />
            </Provider>
        );
    });

    // Render Configure Gifts with Purchase Application
    dynamicPlaceComponent("#configure-gifts-with-purchase-app", async () => {
        const { ConfigureGiftsWithPurchase } = await import(
            "tsi-common-react/src/apps/checkout/containers/ConfigureGiftsWithPurchase"
        );
        return (
            <Provider store={store}>
                <ConfigureGiftsWithPurchase />
            </Provider>
        );
    });

    // Render Checkout Application
    dynamicPlaceComponent("#checkout-app", async () => {
        registerCheckoutCascades(store);
        const { Checkout } = await import(
            "tsi-common-react/src/apps/checkout/containers/Checkout"
        );
        const { PreScreenModal } = await import("./components/PreScreenModal");
        const { CheckoutSidebarContent } = await import(
            "./components/CheckoutSidebarContent"
        );
        const { FinancingUpsellBlock } = await import(
            "tsi-common-react/src/apps/checkout/containers/FinancingUpsellBlock"
        );
        const disableFreeShippingMessage = strToBool(
            getPageSetting("disable-basket-free-shipping-message"),
        );
        return (
            <Provider store={store}>
                <Checkout
                    showFinancingPreQual={strToBool(
                        getPageSetting("enable-financing"),
                    )}
                    enableSplitPay={strToBool(
                        getPageSetting("enable-split-pay"),
                    )}
                    buildPreScreenModal={(props) => {
                        return <PreScreenModal {...props} />;
                    }}
                    getExtraSummaryContent={() => {
                        return null;
                    }}
                    getExtraSidebarContent={() => {
                        return <CheckoutSidebarContent />;
                    }}
                    buildFinancingUpsell={(grandTotal) => {
                        return <FinancingUpsellBlock grandTotal={grandTotal} />;
                    }}
                    disableFreeShippingMessage={disableFreeShippingMessage}
                    predictedDeliveryDates={getPageSetting(
                        "predicted-delivery-date-display-type",
                    )}
                    preferredDeliveryDates={getPageSetting(
                        "preferred-delivery-date-display-type",
                    )}
                />
            </Provider>
        );
    });

    // Render Complete Deferred Payment Application
    dynamicPlaceComponent("#complete-deferred-payment-app", async (elem) => {
        registerCheckoutCascades(store);
        const { CompleteDeferredPayment } = await import(
            "tsi-common-react/src/apps/checkout/containers/CompleteDeferredPayment"
        );
        const { FinancingUpsellBlock } = await import(
            "tsi-common-react/src/apps/checkout/containers/FinancingUpsellBlock"
        );
        const disableFreeShippingMessage = strToBool(
            getPageSetting("disable-basket-free-shipping-message"),
        );
        return (
            <Provider store={store}>
                <CompleteDeferredPayment
                    initDataJSON={elem.dataset.initData || ""}
                    enableSplitPay={strToBool(
                        getPageSetting("enable-split-pay"),
                    )}
                    buildFinancingUpsell={(grandTotal) => {
                        return <FinancingUpsellBlock grandTotal={grandTotal} />;
                    }}
                    disableFreeShippingMessage={disableFreeShippingMessage}
                />
            </Provider>
        );
    });

    // Render Message Banner
    dynamicPlaceComponent(
        '[data-place-react="message-banner"]',
        async (elem) => {
            const { MessageBanner } = await import(
                "tsi-common-react/src/apps/checkout/containers/MessageBanner"
            );
            const tags = elem.dataset.tags || "";
            const message = elem.dataset.message || "";
            return (
                <Provider store={store}>
                    <MessageBanner tags={tags} message={message} />
                </Provider>
            );
        },
    );

    // Render Financing Form Application
    dynamicPlaceComponent(
        '[data-place-react="financing-form"]',
        async (elem) => {
            const { FinancingModalTrigger } = await import(
                "tsi-common-react/src/apps/financing/FinancingModalTrigger"
            );
            const linkClass =
                elem.dataset.linkClass ||
                "financing__offer__cta--apply al-financing-page__apply-now-button";
            const linkTitle = elem.dataset.linkTitle || "Apply Now";
            const linkText = elem.dataset.linkText || "Apply Now";
            const applicationSource =
                elem.dataset.applicationSource || "Financing Page";
            return (
                <Provider store={store}>
                    <FinancingModalTrigger
                        modalType="full-app"
                        className={linkClass}
                        title={linkTitle}
                        applicationSource={applicationSource}
                    >
                        {linkText}
                    </FinancingModalTrigger>
                </Provider>
            );
        },
    );

    // Render Offer images
    dynamicPlaceComponent('[data-place-react="order-offer"]', async (elem) => {
        const desktop_image = elem.dataset.desktop_image || "";
        const mobile_image = elem.dataset.mobile_image || "";
        const offer_freq = elem.dataset.offer_freq || "";
        const benefit_value = elem.dataset.benefit_value || "";
        const offer_name = elem.dataset.offer_name || "";

        const { OrderOfferImage } = await import(
            "./components/OrderOfferImage"
        );
        const { OrderOffer } = await import("./components/OrderOffer");
        const appSlugName = getPageSetting("app-slug");
        if (appSlugName !== "tempurpedic") {
            return null;
        }

        if (!!offer_freq && !!benefit_value) {
            return (
                <Provider store={store}>
                    <OrderOffer
                        offer_freq={offer_freq}
                        benefit_value={benefit_value}
                        desktop_image={desktop_image}
                        mobile_image={mobile_image}
                        offer_name={offer_name}
                    />
                </Provider>
            );
        }
        if (!!desktop_image && !!mobile_image) {
            return (
                <Provider store={store}>
                    <OrderOfferImage
                        desktop_image={desktop_image}
                        mobile_image={mobile_image}
                    />
                </Provider>
            );
        }
        return null;
    });

    // Render Create Account Widgets
    dynamicPlaceComponent(
        '[data-place-react="create-account"]',
        async (elem) => {
            const { CreateAccount } = await import(
                "tsi-common-react/src/apps/checkout/containers/CreateAccount"
            );
            const firstName = elem.dataset.firstName || "";
            const email = elem.dataset.email || "";
            const currentUserID = elem.dataset.currentUserId;
            const orderUserID = elem.dataset.orderUserId;
            const orderUserHasPassword =
                (elem.dataset.orderUserHasPassword || "").toLowerCase() ===
                "true";
            const orderHistoryUrl = isoWebPageURL.wrap(
                elem.dataset.orderHistoryUrl || "",
            );
            const isAuthenticatedAsOrderOwner = currentUserID === orderUserID;
            return (
                <CreateAccount
                    firstName={firstName}
                    email={email}
                    isAuthenticatedAsOrderOwner={isAuthenticatedAsOrderOwner}
                    orderOwnerHasPassword={orderUserHasPassword}
                    orderHistoryURL={orderHistoryUrl}
                />
            );
        },
    );

    // Render Financing Modal (this is what opens in response to the
    // FinancingModalTriggers scattered across the site.)
    dynamicPlaceComponent('[data-place-react="financing-modal"]', async () => {
        const { FinancingModal } = await import(
            "tsi-common-react/src/apps/financing/FinancingModal"
        );
        return (
            <Provider store={store}>
                <FinancingModal />
            </Provider>
        );
    });

    // Resolve promise to denote that all components are now rendered
    resolve();
});

// ============================================================================
// Open Prequal modal when links contain the magic query params (thus preventing needless page refreshes)
// ============================================================================
Array.from(document.querySelectorAll<HTMLAnchorElement>("a[href]"))
    .map((elem): [URL, HTMLAnchorElement] => {
        const url = new URL(elem.href);
        return [url, elem];
    })
    .filter(([url]) => {
        return strToBool(`${url.searchParams.get("resume-prequal") || "no"}`);
    })
    .forEach(([_url, elem]) => {
        elem.addEventListener("click", (event) => {
            event.preventDefault();
            store.dispatch(openPreQualModal({}));
        });
    });

// ============================================================================
// Special On-Page-Load Functionality
// ============================================================================
const wipeCheckoutData = () => {
    console.debug("Erasing transient checkout data...");
    persistor.purge();
    checkoutAPI.clearShippingAddress();
    checkoutAPI.clearBillingAddress();
    loaders.resetFormData();
};

onReadyStateComplete.on(async () => {
    const url = new URL(window.location.href);

    // Wait for redux store to finish loading
    await rehydratingStore;
    await componentsRendering;

    // Redirect from basket page to homepage after 60 min delay
    const basketApp = document.getElementById("basket-app");
    if (basketApp) {
        setTimeout(() => {
            urls.navigateToHome();
        }, 3600_000);
    }

    // Auto open PreQual modal?
    const openPreQualModel = strToBool(
        `${url.searchParams.get("resume-prequal") || "no"}`,
    );
    if (openPreQualModel) {
        store.dispatch(openPreQualModal({}));
    }

    // Auto open Financing Application modal?
    const openCreditAppModel = strToBool(
        `${url.searchParams.get("open-credit-app") || "no"}`,
    );
    if (openCreditAppModel) {
        const appSource = `${url.searchParams.get("credit-app-source")}`;
        store.dispatch(
            openApplyModal({
                applicationSource: appSource || "Financing Page",
            }),
        );
    }

    // Wipe checkout data?
    if (document.body.classList.contains("js-wipe-checkout-data")) {
        wipeCheckoutData();
    }
});

// ============================================================================
// CSR Toolbar Integration
// ============================================================================
const reloadCheckoutData = () => {
    wipeCheckoutData();
    console.log("Reloading basket data");
    loaders.loadBasket();
    console.log("Reloading shipping and billing address");
    loaders.loadShippingAndBillingAddresses();
};

signals.csr.onBeforeStartNewBasket.on(wipeCheckoutData);
signals.csr.onAfterStartAssistingCustomer.on(reloadCheckoutData);
signals.csr.onAfterStopAssistingCustomer.on(reloadCheckoutData);
