import React from "react";
import SVG from "react-inlinesvg";
import Modal from "react-modal";
import { t } from "ttag";

import iconIconEmail from "../../img/retail-locator/icon-email.svg";
import iconXClose from "../../svg/x-close.svg";
import { LocatorSendSingleStoreForm } from "./LocatorSendSingleStore_Form";

interface IProps {
    onSubmit: (recipient: string) => Promise<void>;
}

interface IState {
    isOpen: boolean;
    isSubmitting: boolean;
}

export class LocatorSendSingleStoreModal extends React.Component<
    IProps,
    IState
> {
    state: IState = {
        isOpen: false,
        isSubmitting: false,
    };

    private readonly onOpen = () => {
        this.setState({
            isOpen: true,
        });
    };

    private readonly onClose = () => {
        this.setState({
            isOpen: false,
        });
    };

    private readonly onSubmit = async (recipient: string) => {
        this.setState({
            isSubmitting: true,
        });
        try {
            await this.props.onSubmit(recipient);
        } catch (e) {
            this.setState({
                isSubmitting: false,
                isOpen: true,
            });
        } finally {
            this.setState({
                isSubmitting: false,
                isOpen: false,
            });
        }
    };

    render() {
        return (
            <>
                <button
                    onClick={this.onOpen}
                    className="info__actions-icon info__actions-icon--button"
                >
                    <img alt={t`Email Icon`} src={iconIconEmail} />
                </button>
                <span className="info__actions-label">{t`Email`}</span>
                <Modal
                    aria={{
                        modal: true,
                    }}
                    style={{
                        content: {
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            maxHeight: "95%",
                            maxWidth: "542px",
                            width: "96%",
                            overflow: "auto",
                            padding: "40px",
                        },
                    }}
                    contentLabel={t`Email Me Details About This Store.`}
                    portalClassName="locator"
                    className={`locations modal`}
                    isOpen={this.state.isOpen}
                    onRequestClose={this.onClose}
                    role="dialog"
                >
                    <button
                        aria-label={t`Close`}
                        className="modal-close button--ghost"
                        onClick={this.onClose}
                    >
                        <SVG
                            aria-hidden="true"
                            className="modal-close__icon"
                            src={iconXClose}
                            title={t`Close Icon`}
                        />
                    </button>
                    <LocatorSendSingleStoreForm
                        isSubmitting={this.state.isSubmitting}
                        onCancel={this.onClose}
                        onSubmit={this.onSubmit}
                    />
                </Modal>
            </>
        );
    }
}
