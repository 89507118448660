import React from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { t } from "ttag";

import { IFormUUID, IProductUUID } from "../../../models/nominals";
import {
    IReviewsProduct,
    IReviewsProductVariantChoices,
} from "../../../models/reviews.interfaces";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import {
    NOT_SURE_VARIANT_CHOICE_SLUG,
    WriteReviewFormMode,
} from "../constants";
import { defaultState } from "../defaults";
import { Dispatchers } from "../dispatchers";
import { IWriteReviewFormState } from "../reducers.interfaces";
import {
    getProductByUUID,
    getWriteReviewFormSelectedVariant,
    getWriteReviewFormState,
} from "../selectors";

interface IOwnProps {
    productUUID: IProductUUID;
    formUUID: IFormUUID;
    side?: string;
}

interface IReduxProps {
    product: IReviewsProduct | null;
    formState: IWriteReviewFormState | null;
    selectedVariant: IReviewsProductVariantChoices;
    idx?: number;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    isTooltipOpen: boolean;
    isKeyDown: boolean;
}

class MultiProductWriteReviewFormProductHeadingComponent extends React.Component<
    IProps,
    IState
> {
    public state: IState = {
        isKeyDown: false,
        isTooltipOpen: false,
    };

    private readonly onCheckboxChange = () => {
        const newMode = this.isSelected
            ? WriteReviewFormMode.CLOSED
            : WriteReviewFormMode.FORM_OPEN;
        this.props.dispatchers.setWriteReviewFormMode(
            this.props.formUUID,
            newMode,
        );
    };

    private get isSelected() {
        return this.props.formState?.mode !== WriteReviewFormMode.CLOSED;
    }

    private readonly updateTooltipStatus = () => {
        this.setState((state) => {
            return {
                ...state,
                isTooltipOpen: !state.isTooltipOpen,
            };
        });
    };

    private readonly keyDownHandler = (
        event: React.KeyboardEvent<HTMLElement>,
    ) => {
        switch (event.key) {
            case "Escape":
                ReactTooltip.hide();
                break;
            case "Enter":
                if (!this.state.isKeyDown) {
                    this.setState({
                        isKeyDown: true,
                    });
                    return;
                }
                ReactTooltip.hide();
                break;
        }
    };

    render() {
        if (!this.props.product || !this.props.formState) {
            return null;
        }
        if (this.props.formState?.mode === WriteReviewFormMode.THANK_YOU_OPEN) {
            return (
                <div key={`${this.props.product.uuid}`}>
                    <label className="wrf-rating__title">
                        <input
                            type="checkbox"
                            name="multi-product-write-review-form-selected-products"
                            value={`${this.props.product.uuid}`}
                            disabled={true}
                            checked={this.isSelected}
                        />
                        {this.props.product.name}
                        {this.props.selectedVariant &&
                        this.props.selectedVariant !==
                            NOT_SURE_VARIANT_CHOICE_SLUG
                            ? `: ${this.props.selectedVariant.display_name}`
                            : ""}
                    </label>
                </div>
            );
        }
        const tipId = "multi-product-write-review__side-tooltip";
        return (
            <div key={`${this.props.product.uuid}`}>
                <label className="wrf-rating__title">
                    <input
                        type="checkbox"
                        name="multi-product-write-review-form-selected-products"
                        value={`${this.props.product.uuid}`}
                        checked={this.isSelected}
                        onChange={this.onCheckboxChange}
                    />
                    {this.props.product.name}
                    {this.props.side && ` Split King - Side ${this.props.side}`}
                    {this.props.selectedVariant &&
                    this.props.selectedVariant !== NOT_SURE_VARIANT_CHOICE_SLUG
                        ? `: ${this.props.selectedVariant.display_name}`
                        : ""}
                </label>
                {this.props.side && (
                    <div>
                        <a
                            aria-describedby={tipId}
                            aria-expanded={this.state.isTooltipOpen}
                            className="multi-product-review__header-cart-ref-icon-container"
                            data-delay-update={100}
                            data-iscapture="true"
                            data-tip={true}
                            data-for={tipId}
                            data-delay-hide={200}
                            title={t`What does Side One and Side Two mean?`}
                            onKeyDown={(event) => {
                                this.keyDownHandler(event);
                            }}
                            onMouseOver={() => {
                                this.setState({ isKeyDown: false });
                            }}
                        >
                            {t`What does Side One and Side Two mean`}
                        </a>
                        <ReactTooltip
                            afterHide={this.updateTooltipStatus.bind(this)}
                            afterShow={this.updateTooltipStatus.bind(this)}
                            aria-label={t`What does Side One and Side Two mean?`}
                            id={tipId}
                            class="tooltip"
                            event={"click"}
                            eventOff="scroll mousewheel mouseleave mouseout blur"
                            role="tooltip"
                        >
                            {t`You purchased a Split King Mattress with two different mattress feels.`}
                            <br />
                            {t`Please review each side of the mattress individually.`}
                        </ReactTooltip>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    return {
        product: getProductByUUID(state, ownProps),
        formState: getWriteReviewFormState(state, ownProps),
        selectedVariant: getWriteReviewFormSelectedVariant(state, ownProps),
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const MultiProductWriteReviewFormProductHeading = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MultiProductWriteReviewFormProductHeadingComponent);
