import classNames from "classnames";
import React from "react";
import SVG from "react-inlinesvg";
import { t } from "ttag";

import iconArrowDownBlueCircle from "../../../../img/icons/arrow-down-blue-circle.svg";
import { ConfiguratorPrice } from "../../../common/ConfiguratorPrice";
import ErrorModal from "../../../common/ErrorModal";
import { RatingGraphic } from "../../../common/RatingGraphic";
import { RichText } from "../../../common/RichText";
import { ConfiguratorTypes, STR_NO, STR_YES } from "../../../constants";
import {
    IConcreteBundle,
    IOptionValues,
    IProduct,
    IProductCategory,
} from "../../../models/catalogue.interfaces";
import { IWebPageURL } from "../../../models/nominals";
import { IAPIPrice } from "../../../models/prices.interfaces";
import { DiscountDisplayMode } from "../constants";
import { ConfiguratorPriceLoading } from "../elements/ConfiguratorPriceLoading";
import { isPreorderProduct } from "../utils";

import styles from "./StickyConfigurator.module.scss";

interface IProps {
    isPLCVersion: boolean;

    // Basic Data
    financingLink?: IWebPageURL | null;
    rootProduct: IProduct | null;
    concreteBundles: IConcreteBundle[];
    baseVariant: IProduct | null;
    upgradedVariant: IProduct | null;
    price: IAPIPrice | null;

    // Option selector state
    optionValues: IOptionValues;
    quantity: number;

    // Add to basket button
    addToBasketCooldownActive: boolean;
    addToBasketButtonText: string;
    onAddToBasket: () => void;

    // Add to basket error modal
    addToBasketErrorOpen: boolean;
    addToBasketErrorReason: string;
    onCloseErrorModal: () => void;

    // Misc UI options
    isVisible: boolean;
    onEditClick: () => void;
    strikeThroughMSRP: boolean;
    actualPriceStyle: string;
    overrideFinancingCopy: string;
    selectedCategories?: IProductCategory[];

    setStickyConfiguratorHeight: (height: number) => void;
    stickyElemHeight: number;

    children?: React.ReactNode;
}

interface IState {
    areOptionsExpanded: boolean;
}

export class StickyConfigurator extends React.Component<IProps, IState> {
    public state: IState = {
        areOptionsExpanded: false,
    };

    private configuratorElement: HTMLDivElement | null = null;

    private readonly onCloseErrorModal = (
        e: React.FormEvent<Element> | React.KeyboardEvent<Element>,
    ) => {
        e.preventDefault();
        this.props.onCloseErrorModal();
    };

    private readonly onAddToBasketClick = (e: React.FormEvent<HTMLElement>) => {
        e.preventDefault();
        if (!this.props.rootProduct) {
            console.error(
                "[Configurator] Cannot check for upsell when no product is loaded.",
            );
            return;
        }
        this.props.onAddToBasket();
    };

    private readonly onExpandOptions = () => {
        this.setState((prevState) => ({
            areOptionsExpanded: !prevState.areOptionsExpanded,
        }));
    };

    componentDidMount() {
        if (!this.configuratorElement) {
            return;
        }

        this.props.setStickyConfiguratorHeight(
            this.configuratorElement.clientHeight,
        );
    }

    private buildCopy() {
        if (!this.props.rootProduct) {
            return;
        }
        // Build category text
        let categoryOptions;
        if (this.props.selectedCategories) {
            categoryOptions = this.props.selectedCategories.map((code) => {
                const prefixName = code.name
                    .split(" ")
                    .map((word) => {
                        return word[0].toUpperCase() + word.substring(1);
                    })
                    .join(" ");

                const prefixNameCapped = this.props.isPLCVersion && (
                    <span className="sticky-configurator__option-prefix">{`${prefixName}: `}</span>
                );

                return (
                    <div
                        className="sticky-configurator__option"
                        key={code.name}
                    >
                        {prefixNameCapped}
                        {code.value}
                    </div>
                );
            });
        }

        // Build option text
        const optionCodes =
            this.props.rootProduct.attributes.product_options?.value || [];
        const productOptions = optionCodes.map((code) => {
            let value = this.props.optionValues[code];
            if (value === STR_NO || typeof value === "undefined") {
                return;
            }
            if (value === STR_YES) {
                value = "Added";
            }
            const prefixName = code
                .substr(code.indexOf("_") + 1, code.length)
                .replace(/_/g, " ")
                .split(" ")
                .map((word) => {
                    return word[0].toUpperCase() + word.substring(1);
                })
                .join(" ");
            const prefixNameCapped = this.props.isPLCVersion && (
                <span className="sticky-configurator__option-prefix">{`${prefixName}: `}</span>
            );
            return (
                <div className="sticky-configurator__option" key={code}>
                    {prefixNameCapped}
                    {value}
                </div>
            );
        });

        // Build title
        // TODO: Using the product attribute from Oscar for the title
        // and adding replace('TEMPUR-', '') so the title reads someting like "ProAdapt" instead of "TEMPUR-ProAdapt"
        // There might be a better way but not feagured out yet.
        const richTextTitle = this.props.rootProduct.attributes.title_richtext;
        const displayTitle = richTextTitle
            ? `${richTextTitle.value?.toString()}`.replace("TEMPUR-", "")
            : this.props.rootProduct.title;
        const title: JSX.Element = richTextTitle ? (
            <RichText
                className={`${styles.title} sticky-configurator__title`}
                html={displayTitle}
            />
        ) : (
            <div className={`${styles.title} sticky-configurator__title`}>
                {displayTitle}
            </div>
        );
        const copyClasses = classNames({
            "sticky-configurator__copy": true,
            "sticky-configurator__copy--expanded":
                this.state.areOptionsExpanded,
        });
        const expandButtonClasses = classNames({
            "sticky-configurator__expand-button": true,
            "sticky-configurator__expand-button--expanded":
                this.state.areOptionsExpanded,
        });
        const copy: JSX.Element = this.props.isPLCVersion ? (
            <div>
                <div className={copyClasses}>
                    <div className="sticky-configurator__options">
                        {categoryOptions}
                        {productOptions}
                    </div>
                </div>
                <div className={expandButtonClasses}>
                    <button onClick={this.onExpandOptions}>
                        <SVG src={iconArrowDownBlueCircle} />
                    </button>
                </div>
            </div>
        ) : (
            <div className={copyClasses}>
                <div className="sticky-configurator__options">
                    {categoryOptions}
                    {productOptions}
                </div>
            </div>
        );

        // Build content
        return (
            <div
                className={`${styles.copyContainer} sticky-configurator__copy-container`}
            >
                <div>
                    <span>
                        {title}
                        {!this.props.isPLCVersion && (
                            <RatingGraphic
                                cardClass="sticky-configurator"
                                cardSize="large"
                                numReviews={this.props.rootProduct.num_reviews}
                                rating={this.props.rootProduct.rating}
                            />
                        )}
                    </span>
                    <span>{this.buildPrice()}</span>
                </div>
                {copy}
            </div>
        );
    }

    private buildPrice() {
        if (!this.props.price || !this.props.upgradedVariant) {
            return (
                <ConfiguratorPriceLoading
                    configuratorType={ConfiguratorTypes.PANEL}
                />
            );
        }

        return (
            <div
                className={`${styles.priceContainer} sticky-configurator__price-container`}
            >
                <ConfiguratorPrice
                    price={this.props.price}
                    variant={this.props.upgradedVariant}
                    selectedAddonPrice={null}
                    financingLink={
                        this.props.financingLink
                            ? this.props.financingLink
                            : null
                    }
                    strikeThroughMSRP={this.props.strikeThroughMSRP}
                    discountDisplayMode={DiscountDisplayMode.DISABLED}
                    actualPriceStyle={this.props.actualPriceStyle}
                    overrideFinancingCopy={this.props.overrideFinancingCopy}
                />
            </div>
        );
    }

    private buildErrorModal() {
        return (
            <ErrorModal
                name="configurator"
                isOpen={this.props.addToBasketErrorOpen}
                onRequestClose={this.onCloseErrorModal}
            >
                <p>
                    {t`Product could not be added to cart (${this.props.addToBasketErrorReason}).`}
                </p>
                <p>{t`Please adjust your selection and try again.`}</p>
            </ErrorModal>
        );
    }

    private buildAddToBasketButton(variantIsAvailableToBuy: boolean) {
        const btnText = this.props.addToBasketButtonText;
        const isPreorder =
            this.props.upgradedVariant &&
            isPreorderProduct(this.props.upgradedVariant);
        const btnClasses = classNames({
            "button": true,
            "button--secondary": isPreorder,
            "button--primary-congress-blue": !isPreorder,
            "sticky-configurator__add-button": true,
        });
        return (
            <div
                className={`${styles.ctaContainer} sticky-configurator__cta-container`}
            >
                <button
                    className={btnClasses}
                    disabled={
                        this.props.addToBasketCooldownActive ||
                        !variantIsAvailableToBuy
                    }
                    onClick={this.onAddToBasketClick}
                >
                    {btnText}
                </button>
            </div>
        );
    }

    private buildCta(variantIsAvailableToBuy: boolean) {
        return (
            <div className="sticky-configurator__cta">
                {this.buildAddToBasketButton(variantIsAvailableToBuy)}
                <button onClick={this.props.onEditClick}>
                    Change Selection
                </button>
            </div>
        );
    }

    render() {
        const variantIsAvailableToBuy = !!(
            this.props.baseVariant &&
            this.props.baseVariant.availability.is_available_to_buy
        );
        const stickyElemMinHeight = 300;
        const configuratorStyle = {
            top: !this.props.isVisible
                ? `-${this.props.stickyElemHeight + stickyElemMinHeight}px`
                : 0,
        };

        return (
            <div
                className={`${styles.root} sticky-configurator`}
                style={configuratorStyle}
                ref={(configuratorElement) => {
                    this.configuratorElement = configuratorElement;
                }}
            >
                <div className="sticky-configurator__content">
                    <div className="sticky-configurator__info-container">
                        {this.buildCopy()}
                    </div>
                    {this.buildCta(variantIsAvailableToBuy)}
                    {this.props.children}
                    {this.buildErrorModal()}
                </div>
            </div>
        );
    }
}
