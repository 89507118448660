import React from "react";

import { MasonryGrid } from "../../../common/MasonryGrid";
import { GridItem } from "../interfaces";
import { GridTile } from "./GridTile";
import { ReviewSnippetTile } from "./ReviewSnippetTile";
import { TempurLoveStoryPageTile } from "./TempurLoveStoryPageTile";

interface IProps {
    data: GridItem[];
    appendItems?: boolean;
}

interface IState {}

export class Grid extends React.Component<IProps, IState> {
    private readonly primaryLinkRefs: Map<
        number,
        React.RefObject<HTMLAnchorElement>
    > = new Map();

    private getOrCreateLinkRef(
        item: GridItem,
    ): React.RefObject<HTMLAnchorElement> {
        const idx = this.props.data.indexOf(item);
        let ref = this.primaryLinkRefs.get(idx);
        if (ref) {
            return ref;
        }
        ref = React.createRef<HTMLAnchorElement>();
        this.primaryLinkRefs.set(idx, ref);
        return ref;
    }

    render() {
        return (
            <div className="l-full-width">
                <div className="l-capped-width">
                    <article className="posts">
                        <MasonryGrid
                            appendItems={this.props.appendItems}
                            className={"posts__container"}
                            itemSelector={".post"}
                            entities={this.props.data}
                            getTileID={(item) => `${item.id}`}
                            buildTile={(item, tileRef) => {
                                const linkRef = this.getOrCreateLinkRef(item);
                                return (
                                    <GridTile
                                        key={item.id}
                                        forwardedRef={tileRef}
                                        primaryLinkRef={linkRef}
                                        tileUID={item.id}
                                    >
                                        {(item.type ===
                                            "cms.TempurLoveStoryPage" ||
                                            item.type ===
                                                "cms.CustomerContentHubStoryPage") && (
                                            <TempurLoveStoryPageTile
                                                data={item}
                                                ref={linkRef}
                                            />
                                        )}
                                        {(item.type ===
                                            "cms.TempurLoveReviewSnippetPage" ||
                                            item.type ===
                                                "cms.CustomerContentHubReviewSnippetPage") && (
                                            <ReviewSnippetTile
                                                data={item}
                                                ref={linkRef}
                                            />
                                        )}
                                    </GridTile>
                                );
                            }}
                            emptyState={null}
                            options={{
                                horizontalOrder: true,
                                gutter: 10,
                            }}
                        />
                    </article>
                </div>
            </div>
        );
    }
}
