import React from "react";

import { ProductOptionSelectorSet } from "../../../common/ProductOptionSelectorSet";
import {
    IOptionCode,
    IOptionSingleValue,
    IOptionValues,
    IProduct,
} from "../../../models/catalogue.interfaces";

interface IProps {
    giftID: string;
    rootProduct: IProduct | null;
    variant: IProduct | null;
    optionValues: IOptionValues;
    onOptionChange: (
        namespace: string,
        code: IOptionCode,
        index: number,
        totalNumValues: number,
        value: IOptionSingleValue,
    ) => void;
}

interface IState {}

export class GiftOptions extends React.PureComponent<IProps, IState> {
    render() {
        if (!this.props.rootProduct) {
            return null;
        }
        const optionCodes = [
            ...(this.props.rootProduct.attributes.product_options?.value || []),
        ];
        // If feel option exists, move it to the end (to sort correctly for the UI)
        // TODO: Find more elegant solution to coincide with different order in the grid card
        const hasFeel = optionCodes.indexOf("option_feel");
        if (hasFeel !== -1) {
            optionCodes.push(optionCodes.splice(hasFeel, 1)[0]);
        }
        return (
            <>
                {optionCodes.map((code) => (
                    <ProductOptionSelectorSet
                        key={code}
                        selectorID={this.props.giftID}
                        rootProduct={this.props.rootProduct}
                        variant={this.props.variant}
                        code={code}
                        values={this.props.optionValues}
                        onOptionChange={this.props.onOptionChange}
                    />
                ))}
            </>
        );
    }
}
