import classNames from "classnames";
import React from "react";
import SVG from "react-inlinesvg";
import { t } from "ttag";

import warrantyIcon from "../../../../img/icons/mattress-selector/10-year-warranty.svg";
import freeDeliveryIcon from "../../../../img/icons/mattress-selector/free-delivery.svg";
import moonIcon from "../../../../img/icons/mattress-selector/moon.svg";

import styles from "./ValueProps.module.scss";

interface IOwnProps {
    stacked?: boolean;
}

interface IProps extends IOwnProps {}

export const ValueProps = (props: IProps) => {
    function getWarrantyVP() {
        return (
            <li>
                <SVG aria-hidden="true" src={warrantyIcon} />
                {t`10-Year Warranty`}
            </li>
        );
    }
    function getTrialVP() {
        return (
            <li>
                <SVG aria-hidden="true" src={moonIcon} />
                {t`90-Night Trial`}
            </li>
        );
    }

    function getDeliveryVP() {
        return (
            <li>
                <SVG aria-hidden="true" src={freeDeliveryIcon} />
                {t`Free Delivery`}
            </li>
        );
    }

    const classes = classNames({
        [styles.root]: true,
        [styles.stacked]: props.stacked,
    });
    return (
        <ul className={classes}>
            {getTrialVP()}
            {getWarrantyVP()}
            {getDeliveryVP()}
        </ul>
    );
};
