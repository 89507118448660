import React, { useMemo } from "react";
import { getDefaultFinancingPlan } from "tsi-common-react/src/utils/financing";
import { formatFinancingPlan } from "tsi-common-react/src/utils/format";
import { t } from "ttag";

export const FinancingModalTriggerRetailModalFinance = () => {
    const plan = useMemo(
        () => formatFinancingPlan(getDefaultFinancingPlan()),
        [],
    );
    return (
        <span>
            <div className="pre-approval-ad pre-approval-ad--fallback u-flex-container">
                <div className="pre-approval-ad__see">
                    <div className="pre-approval-ad__see--bold">
                        {t`${plan.apr} APR for ${plan.length} Months`}
                        <sup>
                            <a href="/finance-your-purchase#terms_and_conditions">
                                {plan.superscript}
                            </a>
                        </sup>
                    </div>
                    <a
                        href="/finance-your-purchase"
                        className="pre-approval-ad__link pre-approval-ad__see--small al-more-ways-to-buy-financing_ad"
                    >
                        {t`See Financing Details`}
                    </a>
                </div>
            </div>
        </span>
    );
};
