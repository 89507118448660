import React from "react";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { ISyncStoreID } from "tsi-common-react/src/models/nominals";
import {
    CSRF_HEADER,
    ajax,
    getCSRFToken,
} from "tsi-common-react/src/utils/ajax";

import iconIconEmail from "../../img/retail-locator/icon-email.svg";
import { IEmailSendStorePostData } from "../models.interfaces";
import { buildStoreData } from "../utils";
import { LocatorSendResultsModal } from "./LocatorSendResults_Modal";
import { LocatorSurveyModal } from "./LocatorSurveyModal";

interface IProps {
    stores: IRetailStoreWithDistance[];
    getStoreNumber: (store: IRetailStoreWithDistance) => number;
}

interface IState {
    surveyOpen: boolean;
    surveyEmail: string;
}

export class LocatorSendResultsEmail extends React.Component<IProps, IState> {
    state: IState = {
        surveyOpen: false,
        surveyEmail: "",
    };

    private readonly onSubmit = async (
        recipient: string,
        selectedStores: Set<ISyncStoreID>,
    ) => {
        const data: IEmailSendStorePostData = {
            email: recipient,
            stores: buildStoreData(this.props.stores, selectedStores),
        };
        await ajax
            .post("/api/email-retail-locator-results/")
            .set("Accept", "application/json")
            .set(CSRF_HEADER, await getCSRFToken())
            .send(data);
        this.setState({
            surveyOpen: true,
            surveyEmail: recipient,
        });
    };

    render() {
        return (
            <>
                <LocatorSendResultsModal
                    modalClassName="modal-email"
                    buttonLabel="Email Results"
                    buttonImgSrc={iconIconEmail}
                    buttonImgAlt="Email Results"
                    modalTitle="Email Your Results"
                    modalDescription={
                        <>
                            Check up to <strong>10</strong> <span>results</span>{" "}
                            you would like emailed.
                        </>
                    }
                    inputType="email"
                    inputPlaceholder="Enter an Email Address"
                    submitButtonLabel="Send"
                    maxSelected={10}
                    stores={this.props.stores}
                    getStoreNumber={this.props.getStoreNumber}
                    onSubmit={this.onSubmit}
                />
                <LocatorSurveyModal
                    isOpen={this.state.surveyOpen}
                    email={this.state.surveyEmail}
                    onClose={() => {
                        this.setState({
                            surveyOpen: false,
                        });
                    }}
                />
            </>
        );
    }
}
