import React from "react";
import { connect } from "react-redux";
import Trans from "tsi-common-react/src/common/Trans";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { t } from "ttag";

import { IReduxState } from "../../reducers.interfaces";
import { CMSFlagshipStore } from "../components/CMSFlagshipStore";
import { FlagshipStore } from "../components/FlagshipStore";
import { ICMSFlagshipStorePage } from "../models.interfaces";
import {
    getFlagshipStoreByExternalID,
    nearestFlagshipStoreSelector,
} from "../selectors";
import { getTodayHoursKey } from "../utils";

interface IReduxProps {
    store: IRetailStoreWithDistance | null;
    storePage: ICMSFlagshipStorePage | null;
}

interface IOwnProps {
    productTitle: string | null;
    displayEmptyState: (state: boolean) => void;
}

type IProps = IReduxProps & IOwnProps;

interface IState {}

class NearbyFlagshipStoresContainer extends React.Component<IProps, IState> {
    componentDidUpdate() {
        this.props.displayEmptyState(!this.props.store);
    }

    render() {
        if (!this.props.store) {
            return (
                <p>
                    <span>
                        <Trans
                            fmtString={t`Please enter a ZIP Code above to find where you can test a <ProductTitle></ProductTitle>.`}
                            data={{
                                ProductTitle: () => (
                                    <strong key="1">
                                        {this.props.productTitle}
                                    </strong>
                                ),
                            }}
                        />
                    </span>
                </p>
            );
        }
        if (!this.props.storePage) {
            return <FlagshipStore store={this.props.store} />;
        }
        return (
            <CMSFlagshipStore
                store={this.props.store}
                cmsProps={this.props.storePage}
                hoursFieldName={getTodayHoursKey()}
            />
        );
    }
}

const mapStateToProps = (
    state: IReduxState,
    ownProps: IOwnProps,
): IReduxProps & IOwnProps => {
    const store = nearestFlagshipStoreSelector(state);
    return {
        ...ownProps,
        store: store || null,
        storePage: store
            ? getFlagshipStoreByExternalID(
                  state.retail.flagshipStorePages,
                  store.external_id,
              )
            : null,
    };
};

export const NearbyFlagshipStores = connect(mapStateToProps)(
    NearbyFlagshipStoresContainer,
);
