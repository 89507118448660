import React from "react";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";

import iconIconPrint from "../../img/retail-locator/icon-print.svg";
import { LocatorSendResultsModal } from "./LocatorSendResults_Modal";

interface IProps {
    stores: IRetailStoreWithDistance[];
    getStoreNumber: (store: IRetailStoreWithDistance) => number;
}

interface IState {}

export class LocatorSendResultsPrint extends React.Component<IProps, IState> {
    state: IState = {};

    private readonly onSubmit = async () => {
        window.print();
    };

    render() {
        return (
            <LocatorSendResultsModal
                modalClassName="modal-print"
                bodyOpenClassName="locator-print-modal-open"
                buttonLabel="Print"
                buttonImgSrc={iconIconPrint}
                buttonImgAlt="Print"
                modalTitle="Print Your Results"
                modalDescription={
                    <>
                        Check up to <strong>5</strong> <span>results</span> you
                        would like to print.
                    </>
                }
                submitButtonLabel="Print"
                maxSelected={5}
                stores={this.props.stores}
                getStoreNumber={this.props.getStoreNumber}
                onSubmit={this.onSubmit}
            />
        );
    }
}
