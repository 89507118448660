import React from "react";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";

import iconFilters from "../../../../img/icons/filters.svg";
import { IProduct } from "../../../models/catalogue.interfaces";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import { GridFilterConfig } from "../filters";
import {
    Action,
    IActionUpdateSelectManyFilterValue,
    IActionUpdateSortMethod,
    IReduxState,
} from "../reducers.interfaces";
import { GridSorterConfig } from "../sorters";
import { FiltersPanel } from "./FiltersPanel";
import { GridFilter, GridFilterVariants } from "./GridFilter";

import styles from "./FiltersBar.module.scss";

interface IOwnProps {
    filters: GridFilterConfig[];
    sorters?: GridSorterConfig[];
}

interface IReduxProps {
    products: IProduct[];
    filterState: IReduxState["filters"];
    sortMethod: string | null;
}

interface IDispatchProps {
    onButtonClick: (
        optionNamespace: string,
        filterID: string,
        optionID: string,
    ) => void;
    onChangeSortMethod: (sortMethod: string) => void;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    isPanelOpen: boolean;
}

export class FiltersBarComponent extends React.PureComponent<IProps, IState> {
    public state: IState = {
        isPanelOpen: false,
    };

    private readonly toggleFiltersPanel = () => {
        this.setState({
            isPanelOpen: !this.state.isPanelOpen,
        });
    };

    private readonly onButtonClick = (
        filterID: string,
        optionID: string,
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault();
        const optionNamespace =
            this.props.products[0]?.product_class_slug || "";
        this.props.onButtonClick(optionNamespace, filterID, optionID);
    };

    private readonly onButtonClear = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault();
        const optionNamespace =
            this.props.products[0]?.product_class_slug || "";
        for (const filterConfig of this.props.filters) {
            const options = filterConfig.listFilterOptions(this.props.products);
            const filterState = this.props.filterState[filterConfig.filterID];
            const selectedValues = new Set(
                filterState ? filterState.selectedValues : [],
            );
            for (const option of options) {
                if (selectedValues.has(option.id)) {
                    this.props.onButtonClick(
                        optionNamespace,
                        filterConfig.filterID,
                        option.id,
                    );
                }
            }
        }
    };

    private *buildButtons() {
        for (const filterConfig of this.props.filters) {
            const options = filterConfig.listFilterOptions(this.props.products);
            const filterState = this.props.filterState[filterConfig.filterID];
            const selectedValues = new Set(
                filterState ? filterState.selectedValues : [],
            );
            for (const option of options) {
                if (selectedValues.has(option.id)) {
                    yield (
                        <button
                            key={`${filterConfig.filterID}-${option.id}`}
                            className={styles.filterTag}
                            onClick={this.onButtonClick.bind(
                                this,
                                filterConfig.filterID,
                                option.id,
                            )}
                        >
                            <span className="ada-screenreader-only">
                                Remove filter
                            </span>
                            {option.label}
                        </button>
                    );
                }
            }
        }
    }

    private buildFilters() {
        const buttons = Array.from(this.buildButtons());
        return (
            <div className={styles.tagContainer}>
                {buttons.length > 1 && (
                    <button
                        className={styles.clearButton}
                        onClick={this.onButtonClear.bind(this)}
                    >
                        Clear Filters
                    </button>
                )}
                {buttons}
                {this.props.sortMethod && (
                    <span>
                        Sorted by:
                        <button
                            key={this.props.sortMethod}
                            className={styles.filterTag}
                            onClick={() => {
                                this.props.onChangeSortMethod("");
                            }}
                        >
                            <span className="ada-screenreader-only">
                                Remove filter
                            </span>
                            {this.props.sorters?.find(
                                (sorter) => sorter.id === this.props.sortMethod,
                            )?.label || this.props.sortMethod}
                        </button>
                    </span>
                )}
            </div>
        );
    }
    render() {
        return (
            <>
                <div>
                    <div className={styles.topBar}>
                        <div className={styles.filters}>
                            {this.props.filters.map((config) => (
                                <GridFilter
                                    key={config.filterID}
                                    config={config}
                                    variant={GridFilterVariants.DROPDOWN}
                                    showSelectedList={false}
                                />
                            ))}
                        </div>
                        <button
                            className={styles.panelTriggerButton}
                            onClick={this.toggleFiltersPanel}
                        >
                            Sort and Filter <SVG src={iconFilters} />
                        </button>
                    </div>
                    {this.buildFilters()}
                </div>
                <FiltersPanel
                    isOpen={this.state.isPanelOpen}
                    onClose={this.toggleFiltersPanel}
                    filters={this.props.filters}
                    sorters={this.props.sorters}
                />
            </>
        );
    }
}

const mapStateToProps: TStateMapper<"productgrid2", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.productgrid2 || defaults;
    return {
        ...ownProps,
        products: state.products,
        filterState: state.filters,
        sortMethod: state.sortMethod,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    return {
        onButtonClick: (optionNamespace, filterID, optionID) => {
            dispatch<IActionUpdateSelectManyFilterValue>({
                type: Action.UPDATE_SELECT_MANY_FILTER_VALUE,
                payload: {
                    optionNamespace: optionNamespace,
                    filterID: filterID,
                    optionID: optionID,
                    isSelected: false,
                },
            });
        },
        onChangeSortMethod: (sortMethod: string) => {
            dispatch<IActionUpdateSortMethod>({
                type: Action.UPDATE_SORT_METHOD,
                payload: sortMethod,
            });
        },
    };
};

export const FiltersBar = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FiltersBarComponent);
