import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { Key } from "ts-key-enum";

import { IProduct } from "../../../models/catalogue.interfaces";
import { BreakPoint } from "../../../models/screen.interfaces";
import { getViewportBreakpoint } from "../../../utils/detectMobile";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import { GridFilterConfig } from "../filters";
import { GridFilterSelectionMode } from "../models";
import { IFilterOption } from "../models.interfaces";
import {
    Action,
    IActionUpdateFilterVisibility,
    IActionUpdateSelectManyFilterValue,
    IActionUpdateSelectOneFilterValue,
    IFilterState,
} from "../reducers.interfaces";
import { GridFilterOption } from "./GridFilterOption";

import styles from "./GridFilter.module.scss";

export enum GridFilterVariants {
    ACCORDION = "accordion",
    DROPDOWN = "dropdown",
}

interface IOwnProps {
    variant: GridFilterVariants;
    config: GridFilterConfig;
    showSelectedList: boolean;
}

interface IReduxProps {
    products: IProduct[];
    isOpen: boolean;
    selectedValues: string[];
    selectedValuesLabels: string[];
}

interface IDispatchProps {
    onSetVisibility: (id: string, isOpen: boolean) => void;
    onSelectOneChange: (
        optionNamespace: string,
        filterID: string,
        optionID: string | null,
    ) => void;
    onSelectManyChange: (
        optionNamespace: string,
        filterID: string,
        optionID: string,
        isSelected: boolean,
    ) => void;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    visibilityID: string;
}

export class GridFilterComponent extends React.PureComponent<IProps, IState> {
    private readonly filterRef = React.createRef<HTMLDivElement>();
    private readonly bodyId = `filter-body-${this.props.config.filterID}`;

    private readonly isAccordion = () => {
        return this.props.variant === GridFilterVariants.ACCORDION;
    };

    private readonly isDropdown = () => {
        return this.props.variant === GridFilterVariants.DROPDOWN;
    };

    state: IState = {
        visibilityID: `${this.props.variant}-${this.props.config.filterID}`,
    };

    private readonly onTitleEvent = (
        event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    ) => {
        event.preventDefault();
        this.props.onSetVisibility(this.state.visibilityID, !this.props.isOpen);
    };

    private readonly onKeyPressHandler = (
        event: React.KeyboardEvent<HTMLElement>,
    ) => {
        if (event.key === Key.Enter) {
            this.onTitleEvent(event);
        }
    };

    componentDidMount() {
        const currentBreakpoint = getViewportBreakpoint();
        if (this.isAccordion()) {
            if (currentBreakpoint <= BreakPoint.MEDIUM) {
                this.props.onSetVisibility(this.state.visibilityID, false);
            }
        }
        if (this.isDropdown()) {
            this.props.onSetVisibility(this.state.visibilityID, false);
            document.addEventListener("click", (e) => {
                const target = e.target as HTMLElement;
                const isTriggerButton =
                    target.attributes.getNamedItem("aria-controls")?.value ===
                    this.bodyId;
                const isOutsideClick = !this.filterRef.current?.contains(
                    e.target as Node,
                );
                if (this.props.isOpen) {
                    if (!isTriggerButton && isOutsideClick) {
                        this.props.onSetVisibility(
                            this.state.visibilityID,
                            false,
                        );
                    }
                }
            });
        }
    }

    private buildSelectOneFilterBody(options: IFilterOption[]) {
        const selectID = `grid-filter-${this.props.config.filterID}`;
        return (
            <div className={styles.sizeSelector}>
                <select
                    id={selectID}
                    name={selectID}
                    className={styles.sizeSelectorSelect}
                    value={this.props.selectedValues[0] || ""}
                    onChange={(e) => {
                        this.props.onSelectOneChange(
                            this.props.products[0]?.product_class_slug,
                            this.props.config.filterID,
                            e.currentTarget.value,
                        );
                    }}
                >
                    <option value={""}>Select</option>
                    {options.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
        );
    }

    private buildSelectManyFilterBody(options: IFilterOption[]) {
        return (
            <>
                {options.map((option) => (
                    <GridFilterOption
                        key={option.id}
                        filterID={this.props.config.filterID}
                        option={option}
                        onChange={this.props.onSelectManyChange}
                    />
                ))}
            </>
        );
    }

    private buildFilterBody() {
        const options = this.props.config.listFilterOptions(
            this.props.products,
        );
        if (
            this.props.config.selectionMode ===
            GridFilterSelectionMode.SELECT_ONE
        ) {
            return this.buildSelectOneFilterBody(options);
        }
        return this.buildSelectManyFilterBody(options);
    }

    render() {
        const containerClasses = classNames({
            [styles.filterContainer]: this.isAccordion(),
            [styles.dropdownFilterContainer]: this.isDropdown(),
        });
        const titleClasses = classNames({
            [styles.filterTitle]: this.isAccordion(),
            [styles.dropdownFilterTitle]: this.isDropdown(),
            [styles.filterTitleOpen]: this.props.isOpen,
            [styles.filterTitleClose]: !this.props.isOpen,
            "product-grid-filter__header": true,
        });
        const bodyClasses = classNames({
            [styles.filterBody]: this.isAccordion(),
            [styles.dropdownFilterBody]: this.isDropdown(),
            "product-grid-filter__body--open": this.props.isOpen,
            [styles.filterBodyClose]: !this.props.isOpen,
            "product-grid-filter__body--select-many":
                this.props.config.selectionMode ===
                GridFilterSelectionMode.SELECT_MANY,
            "product-grid-filter__body--select-one":
                this.props.config.selectionMode ===
                GridFilterSelectionMode.SELECT_ONE,
        });
        return (
            <fieldset className={containerClasses}>
                <legend
                    className={titleClasses}
                    onClick={this.onTitleEvent}
                    role="button"
                    tabIndex={0}
                    onKeyPress={this.onKeyPressHandler}
                    aria-controls={this.bodyId}
                    aria-expanded={this.props.isOpen}
                >
                    {this.props.config.label}
                    {this.props.showSelectedList &&
                        this.props.variant === GridFilterVariants.ACCORDION && (
                            <span className={styles.selectedFiltersList}>
                                {this.props.selectedValuesLabels.join(", ")}
                            </span>
                        )}
                </legend>

                <div
                    className={bodyClasses}
                    id={this.bodyId}
                    ref={this.filterRef}
                >
                    {this.buildFilterBody()}
                </div>
            </fieldset>
        );
    }
}

const mapStateToProps: TStateMapper<"productgrid2", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const buildSelectedValuesLabels = (
        filterOptions: IFilterOption[],
        filterValueState: IFilterState,
    ) => {
        const labels = [];
        const selectedValues = new Set(
            filterValueState ? filterValueState.selectedValues : [],
        );
        for (const option of filterOptions) {
            if (selectedValues.has(option.id)) {
                labels.push(option.label);
            }
        }
        return labels;
    };
    const state = rootState.productgrid2 || defaults;
    const options = ownProps.config.listFilterOptions(state.products);
    const filterState =
        state.filters[`${ownProps.variant}-${ownProps.config.filterID}`];
    const valueState = state.filters[`${ownProps.config.filterID}`];
    const selectedValuesLabels = buildSelectedValuesLabels(options, valueState);
    return {
        ...ownProps,
        products: state.products,
        isOpen: filterState ? filterState.isOpen : true,
        selectedValues: valueState ? valueState.selectedValues : [],
        selectedValuesLabels: selectedValuesLabels ? selectedValuesLabels : [],
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    return {
        onSetVisibility: (id: string, isOpen: boolean) => {
            dispatch<IActionUpdateFilterVisibility>({
                type: Action.UPDATE_FILTER_VISIBILITY,
                payload: {
                    filterID: id,
                    isOpen: isOpen,
                },
            });
        },
        onSelectOneChange: (optionNamespace, filterID, optionID) => {
            dispatch<IActionUpdateSelectOneFilterValue>({
                type: Action.UPDATE_SELECT_ONE_FILTER_VALUE,
                payload: {
                    optionNamespace: optionNamespace,
                    filterID: filterID,
                    optionID: optionID,
                },
            });
        },
        onSelectManyChange: (
            optionNamespace,
            filterID,
            optionID,
            isSelected,
        ) => {
            dispatch<IActionUpdateSelectManyFilterValue>({
                type: Action.UPDATE_SELECT_MANY_FILTER_VALUE,
                payload: {
                    optionNamespace: optionNamespace,
                    filterID: filterID,
                    optionID: optionID,
                    isSelected: isSelected,
                },
            });
        },
    };
};

export const GridFilter = connect(
    mapStateToProps,
    mapDispatchToProps,
)(GridFilterComponent);
