import React from "react";

import { BackendType } from "../../../models/financing";
import { Props as FortivaProps, FortivaTerms } from "./FortivaTerms";
import { Props as WellsFargoProps, WellsFargoTerms } from "./WellsFargoTerms";

type Props = WellsFargoProps &
    FortivaProps & {
        backendType: BackendType | null;
    };

export const FinancingFullTerms = (props: Props) => {
    if (props.backendType === BackendType.WELLSFARGO) {
        return <WellsFargoTerms {...props} />;
    }
    if (props.backendType === BackendType.FORTIVA) {
        return <FortivaTerms {...props} />;
    }
    return null;
};
