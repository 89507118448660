import Flickity from "flickity-imagesloaded";
import React from "react";

interface IProps {
    promos: JSX.Element[];
}

export class MenuAdverts extends React.Component<
    IProps,
    Record<string, never>
> {
    private flickity: Flickity | null = null;
    private advertsElem: HTMLDivElement | null = null;

    componentDidMount() {
        this.initFlickity();
    }

    componentDidUpdate() {
        this.initFlickity();
    }

    private initFlickity() {
        if (!this.advertsElem) {
            return;
        }
        if (this.flickity) {
            this.flickity.destroy();
        }
        if (this.props.promos.length > 1) {
            this.flickity = new Flickity(this.advertsElem, {
                wrapAround: true,
                imagesLoaded: true,
                adaptiveHeight: true,
                arrowShape: {
                    x0: 30,
                    x1: 50,
                    y1: 25,
                    x2: 50,
                    y2: 15,
                    x3: 40,
                },
            });
        }
    }

    render() {
        return (
            <div
                className="main-dropdown-menu__adverts"
                ref={(ref) => {
                    this.advertsElem = ref;
                }}
            >
                {this.props.promos}
            </div>
        );
    }
}
