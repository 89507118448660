import {
    PersistentCounter,
    trackHomePageView,
    trackPageView,
    trackSearchPageView,
} from "tsi-common-react/src/utils/analytics";
import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";

onDOMContentLoaded.on(() => {
    // Track page view
    new PersistentCounter("pageCount").increment();

    // Track page type view
    const trackEvent = document.body.dataset.trackEvent;
    switch (trackEvent) {
        case "HomePageView":
            trackHomePageView();
            break;

        case "SearchPageView":
            if (document.body.dataset.trackEventData) {
                const trackEventData = JSON.parse(
                    document.body.dataset.trackEventData,
                );
                trackSearchPageView(trackEventData.PRODUCT_IDS);
            } else {
                console.debug("Missing event data fro SearchPageView");
            }
            break;

        case "ProductDisplayPageView":
        case "ProductLinePageView":
            new PersistentCounter("pdpCount").increment();
            break;
    }

    trackPageView();
});
