import classNames from "classnames";
import React from "react";
import { FormInput } from "tsi-common-react/src/forms/FormInput";
import { FormPhoneNumber } from "tsi-common-react/src/forms/FormPhoneNumber";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import {
    ISyncStoreID,
    isoSyncStoreID,
} from "tsi-common-react/src/models/nominals";
import { isEqualSet } from "tsi-common-react/src/utils/sets";

import { StoreRowLocation } from "./LocatorNearestStoresRow";

// 'Email Your Results'
// Check up to <strong>10</strong> <span>results</span> you would like emailed.

interface IProps {
    title: string;
    description: string | React.ReactNode;
    inputType?: "email" | "phone";
    inputPlaceholder?: string;
    submitButtonLabel: string;
    maxSelected: number;
    stores: IRetailStoreWithDistance[];
    isSubmitting: boolean;
    getStoreNumber: (store: IRetailStoreWithDistance) => number;
    onCancel: () => void;
    onSubmit: (recipient: string, selectedStoreIDs: Set<ISyncStoreID>) => void;
}

interface IState {
    recipient: string;
    selected: Set<ISyncStoreID>;
}

export class LocatorSendResults extends React.Component<IProps, IState> {
    state: IState = {
        recipient: "",
        selected: new Set(),
    };

    private readonly onRecipientChange = (
        e: React.FormEvent<HTMLInputElement>,
    ) => {
        this.setState({
            recipient: e.currentTarget.value,
        });
    };

    private readonly onSelectChange = (
        e: React.FormEvent<HTMLInputElement>,
    ) => {
        const storeID = isoSyncStoreID.wrap(
            parseInt(e.currentTarget.value, 10),
        );
        const checked = e.currentTarget.checked;
        this.setState((s) => {
            if (checked && s.selected.size < this.props.maxSelected) {
                s.selected.add(storeID);
            } else {
                s.selected.delete(storeID);
            }
            return {
                ...s,
                selected: new Set(s.selected),
            };
        });
    };

    private readonly onSelectFirstResults = (
        e: React.FormEvent<HTMLInputElement>,
    ) => {
        this.setState({
            selected: e.currentTarget.checked
                ? this.getFirstResultIDs()
                : new Set(),
        });
    };

    private readonly onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.props.onSubmit(this.state.recipient, this.state.selected);
    };

    private getFirstResultIDs(): Set<ISyncStoreID> {
        return new Set(
            this.props.stores.slice(0, this.props.maxSelected).map((s) => {
                return s.external_id;
            }),
        );
    }

    private firstResultsAreSelected(): boolean {
        const firstResultIDs = this.getFirstResultIDs();
        return isEqualSet(firstResultIDs, this.state.selected);
    }

    private buildStoreRow(store: IRetailStoreWithDistance) {
        const isChecked = this.state.selected.has(store.external_id);
        const uniqueLocationID = "loc" + "-" + store.external_id.toString();
        const rowClasses = classNames({
            selected: isChecked,
        });
        return (
            <li key={`${store.external_id}`} className={rowClasses}>
                <label htmlFor={uniqueLocationID}>
                    <div className="loc__checkbox-wrapper">
                        <input
                            className="loc__checkbox"
                            type="checkbox"
                            value={isoSyncStoreID.unwrap(store.external_id)}
                            checked={isChecked}
                            disabled={
                                (!isChecked &&
                                    this.state.selected.size >=
                                        this.props.maxSelected) ||
                                this.props.isSubmitting
                            }
                            onChange={this.onSelectChange}
                            id={uniqueLocationID}
                        />
                    </div>
                    <StoreRowLocation
                        store={store}
                        showWebsite={false}
                        getStoreNumber={this.props.getStoreNumber}
                    />
                </label>
            </li>
        );
    }

    private buildInput() {
        if (!this.props.inputType) {
            return null;
        }
        if (this.props.inputType === "phone") {
            return (
                <FormPhoneNumber
                    required={true}
                    placeholder={this.props.inputPlaceholder}
                    value={this.state.recipient}
                    onChange={this.onRecipientChange}
                    disabled={this.props.isSubmitting}
                    id={this.props.inputType}
                    name={this.props.inputType}
                />
            );
        }
        return (
            <FormInput
                required={true}
                placeholder={this.props.inputPlaceholder}
                type={this.props.inputType}
                value={this.state.recipient}
                onChange={this.onRecipientChange}
                disabled={this.props.isSubmitting}
                id={this.props.inputType}
                name={this.props.inputType}
            />
        );
    }

    render() {
        return (
            <div className="send__content survey__carousel">
                <h2>{this.props.title}</h2>
                <form onSubmit={this.onSubmit}>
                    <fieldset>
                        <legend className="ada-screenreader-only">
                            {this.props.title}
                        </legend>
                        {this.props.inputType && (
                            <div className="form__field send__inputs">
                                {this.buildInput()}
                            </div>
                        )}
                        <p className="survey__note">{this.props.description}</p>
                        <h3>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={this.firstResultsAreSelected()}
                                    onChange={this.onSelectFirstResults}
                                />
                                <span>
                                    Check First {this.props.maxSelected} Results
                                </span>
                            </label>
                        </h3>
                        <ul className="send__list">
                            {this.props.stores.map((store) => {
                                return this.buildStoreRow(store);
                            })}
                        </ul>
                        <div className="send__actions">
                            <button
                                className="button send__button retail-locator__share-button--text al-store-locator__share-button--text"
                                type="submit"
                                aria-label={this.props.submitButtonLabel}
                                disabled={
                                    this.state.selected.size <= 0 ||
                                    this.props.isSubmitting
                                }
                            >
                                {this.props.submitButtonLabel}
                            </button>
                            <a
                                title="Close"
                                className="send__cancel modal-cancel"
                                onClick={this.props.onCancel}
                                href="#"
                            >
                                Cancel
                            </a>
                        </div>
                    </fieldset>
                </form>
            </div>
        );
    }
}
