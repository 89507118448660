import { createSelector } from "@reduxjs/toolkit";
import { StoreCategory } from "tsi-common-react/src/api/retail";
import { storeSelector } from "tsi-common-react/src/apps/retail/selectors";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import {
    ISyncStoreID,
    isoProductUUID,
    isoSyncStoreID,
} from "tsi-common-react/src/models/nominals";

import { IReduxState } from "../reducers.interfaces";

/**
 * Return the currently loaded stores
 */
export const retailSelector = (state: Pick<IReduxState, "retail">) => {
    return state.retail;
};

/**
 * Return the currently selected product
 */
export const selectedProductSelector = createSelector(
    retailSelector,
    (state) => {
        return state.selectedProduct;
    },
);

/**
 * Return the currently selected product
 */
export const productAvailabilitySelector = createSelector(
    retailSelector,
    (state) => {
        return state.productAvailability;
    },
);

/**
 * Return the stores that have the currently selected product in stock
 */
export const storesWithSelectedProductInStockSelector = createSelector(
    selectedProductSelector,
    productAvailabilitySelector,
    storeSelector,
    (product, productAvailability, stores) => {
        if (!product) {
            return [];
        }
        const storeIDs = productAvailability[isoProductUUID.unwrap(product)];
        if (!storeIDs) {
            return [];
        }
        return stores.filter((store) => {
            return storeIDs.includes(store.external_id);
        });
    },
);

/**
 * Return the currently loaded flagship stores
 */
export const flagshipStoreSelector = createSelector(storeSelector, (stores) => {
    return stores.filter((store) => {
        return store.category === StoreCategory.FLAGSHIP;
    });
});

/**
 * Return the nearest currently loaded flagship store
 */
export const nearestFlagshipStoreSelector = createSelector(
    flagshipStoreSelector,
    (stores) => {
        return stores.reduce<IRetailStoreWithDistance | null>((memo, store) => {
            if (memo && memo.distance <= store.distance) {
                return memo;
            }
            return store;
        }, null);
    },
);

/**
 * Return the distance to the nearest currently loaded flagship store
 */
export const nearestFlagshipStoreDistanceSelector = createSelector(
    nearestFlagshipStoreSelector,
    (store) => {
        return store ? store.distance : null;
    },
);

/**
 * Get the flagship store by it's sync ID
 */
export const getFlagshipStoreByExternalID = (
    stores: IReduxState["retail"]["flagshipStorePages"],
    storeID: ISyncStoreID,
) => {
    return stores[isoSyncStoreID.unwrap(storeID)];
};
