import classNames from "classnames";
import React from "react";
import SVG from "react-inlinesvg";
import { t } from "ttag";

import iconXClose from "../../../../img/icons/x-close.svg";
import { Link } from "../../../common/Link";
import config from "../../../config";
import { IBasket } from "../../../models/catalogue.interfaces";
import { IWebPageURL } from "../../../models/nominals";
import { getFooterSettings, getPageSetting } from "../../../utils/settings";
import { urls } from "../../../utils/urls";
import { ChatLink } from "../../chat/ChatLink";

import styles from "./ContactUsModalContent.module.scss";

interface ICartIDProps {
    basket: IBasket | null;
}
const CartID = (props: ICartIDProps) => {
    if (
        !config.get("ENABLE_BASKET_ID") ||
        !props.basket ||
        props.basket.lines.length <= 0
    ) {
        return null;
    }
    return (
        <div className="service-footer-modal-phone__cart-id">
            {t`Reference Cart ID:`} {props.basket.encoded_basket_id}
        </div>
    );
};

interface ILinksData {
    title: string;
    url: IWebPageURL;
}
interface ILinksProps {
    links: ILinksData[];
}
const Links = (props: ILinksProps) => {
    if (!props.links) {
        return null;
    }
    const renderLinks = props.links.map((link) => {
        return (
            <Link
                key={`${link.url}`}
                className="service-footer-modal-phone__finance-link"
                href={link.url}
                target="_top"
            >
                {link.title}
            </Link>
        );
    });
    return <div className={styles.linkContainer}>{renderLinks}</div>;
};

interface IContentUsModalSectionProps {
    id: string;
    title: string;
    bodyHTML: string;
    phoneLink: IWebPageURL;
    phoneDisplay: string;
    hoursHTML: string;
    children?: React.ReactNode;
}
interface IContentUsModalSectionState {
    hoursExpanded: boolean;
}
class ContentUsModalSection extends React.Component<
    IContentUsModalSectionProps,
    IContentUsModalSectionState
> {
    state: IContentUsModalSectionState = {
        hoursExpanded: false,
    };

    private readonly onToggleHours = () => {
        this.setState((s) => {
            return {
                hoursExpanded: !s.hoursExpanded,
            };
        });
    };

    render() {
        const sectionID = `contact-us-modal-section-${this.props.id}`;
        const hoursTriggerClasses = classNames({
            [styles.hoursTrigger]: true,
            "service-footer-modal-phone__hours-trigger": true,
            [styles.hoursTriggerExpanded]: this.state.hoursExpanded,
            "service-footer-modal-phone__hours-trigger--active":
                this.state.hoursExpanded,
        });
        const hoursContainerClasses = classNames({
            [styles.hoursContainer]: true,
            [styles.hoursContainerExpanded]: this.state.hoursExpanded,
            "service-footer-modal-phone__hours-container--active":
                this.state.hoursExpanded,
        });
        return (
            <section className={styles.section}>
                <h5>{this.props.title}</h5>
                <div
                    className={`${styles.copy} service-footer-modal-phone__copy`}
                    dangerouslySetInnerHTML={{ __html: this.props.bodyHTML }}
                ></div>
                {this.props.children}
                <div>
                    <Link
                        href={this.props.phoneLink}
                        className={`${styles.phone} service-footer-modal-phone__phone`}
                        target="_top"
                    >
                        {this.props.phoneDisplay}
                    </Link>
                    <button
                        className={hoursTriggerClasses}
                        onClick={this.onToggleHours}
                        aria-controls={sectionID}
                        aria-expanded={this.state.hoursExpanded}
                    >
                        {t`Hours`}
                    </button>
                    <div id={sectionID} className={hoursContainerClasses}>
                        <h4>{t`Hours`}</h4>
                        <div
                            className="u-list-unstyled"
                            dangerouslySetInnerHTML={{
                                __html: this.props.hoursHTML,
                            }}
                        ></div>
                    </div>
                </div>
            </section>
        );
    }
}

interface IContactUsModalContentProps {
    basket: IBasket | null;
    onCloseModalContact: (
        event: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
    ) => void;
}
export const ContactUsModalContent = (props: IContactUsModalContentProps) => {
    const modalSettings = getFooterSettings();
    if (!modalSettings) {
        throw new Error("Could not needed find footer settings");
    }
    return (
        <>
            <button
                aria-label={t`close`}
                className={`${styles.close} service-footer-modal__close`}
                onClick={props.onCloseModalContact}
            >
                <SVG
                    aria-hidden={true}
                    className={`${styles.closeIcon} service-footer-modal__close-icon`}
                    src={iconXClose}
                    title={t`Close Icon`}
                />
            </button>
            <div className={styles.content}>
                <div
                    className={`${styles.infoContainer} service-footer-modal__info-container`}
                >
                    <h2 className="service-footer-modal-phone__title">
                        {t`How can we help you?`}
                    </h2>
                    <div>
                        <ContentUsModalSection
                            id="top"
                            title={modalSettings.talk_modal_title_top}
                            bodyHTML={modalSettings.talk_modal_text_top}
                            phoneLink={getPageSetting(
                                "pre-order-phone-number-link",
                            )}
                            phoneDisplay={getPageSetting(
                                "pre-order-phone-number-display",
                            )}
                            hoursHTML={modalSettings.talk_modal_hours_top}
                        />
                        <ContentUsModalSection
                            id="middle"
                            title={modalSettings.talk_modal_title_middle}
                            bodyHTML={modalSettings.talk_modal_text_middle}
                            phoneLink={getPageSetting(
                                "support-website-phone-number-link",
                            )}
                            phoneDisplay={getPageSetting(
                                "support-website-phone-number-display",
                            )}
                            hoursHTML={modalSettings.talk_modal_hours_middle}
                        >
                            <CartID basket={props.basket} />
                        </ContentUsModalSection>
                        <ContentUsModalSection
                            id="bottom"
                            title={modalSettings.talk_modal_title_bottom}
                            bodyHTML={modalSettings.talk_modal_text_bottom}
                            phoneLink={getPageSetting(
                                "service-website-phone-number-link",
                            )}
                            phoneDisplay={getPageSetting(
                                "service-website-phone-number-display",
                            )}
                            hoursHTML={modalSettings.talk_modal_hours_bottom}
                        />
                    </div>
                    <div className={styles.chatContainer}>
                        <ChatLink
                            className="button button--inverse service-footer-modal-phone__email-link"
                            chatOfflineLink={urls.pageURL("contact-link")}
                        />
                    </div>
                    <Links links={modalSettings.talk_modal_links} />
                </div>
            </div>
        </>
    );
};
