import Masonry from "masonry-layout";
import React from "react";

import { focusElement, getFocusableElems } from "../utils/keyboardFocus";

interface IProps<Entity> {
    appendItems?: boolean;
    className?: string;
    itemSelector: string;
    entities: Entity[];
    getTileID: (entity: Entity) => string;
    buildTile: (
        entity: Entity,
        ref: React.RefObject<HTMLDivElement>,
    ) => React.ReactNode;
    emptyState: React.ReactNode;
    options?: Masonry.Options;
    forceEqualHeight?: boolean;
}

interface IState {}

export class MasonryGrid<Entity> extends React.Component<
    IProps<Entity>,
    IState
> {
    private readonly tileRefs: Map<string, React.RefObject<HTMLDivElement>> =
        new Map();
    private readonly containerRef = React.createRef<HTMLDivElement>();

    private masonryGrid: Masonry | null = null;

    private readonly layout: NonNullable<Masonry["layout"]> = () => {
        // Set tile heights (if requested)
        if (this.props.forceEqualHeight) {
            this.setTileHeights();
        }
        // Relayout grid
        if (this.masonryGrid?.layout) {
            this.masonryGrid.layout();
        }
    };

    private readonly reloadItems: NonNullable<Masonry["reloadItems"]> = () => {
        if (this.masonryGrid?.reloadItems) {
            this.masonryGrid.reloadItems();
        }
    };

    private readonly addItems: NonNullable<Masonry["addItems"]> = (...args) => {
        if (this.masonryGrid?.addItems) {
            this.masonryGrid.addItems(...args);
        }
    };

    private readonly appended: NonNullable<Masonry["appended"]> = (...args) => {
        if (this.masonryGrid?.appended) {
            this.masonryGrid.appended(...args);
        }
    };

    componentDidMount() {
        if (!this.containerRef.current) {
            return;
        }
        this.masonryGrid = new Masonry(this.containerRef.current, {
            itemSelector: this.props.itemSelector,
            ...this.props.options,
        });
        // Set tile heights (if requested)
        if (this.props.forceEqualHeight) {
            this.setTileHeights();
        }
        this.attachEventHandlers();
    }

    componentWillUnmount() {
        // Destroy the grid layout calculator before unloading
        if (this.masonryGrid && this.masonryGrid.destroy) {
            this.masonryGrid.destroy();
        }
    }

    componentDidUpdate(prevProps: IProps<Entity>) {
        // Update the grid layout in case theres new items
        this.updateGridLayout();
        // If there's no tiles currently shown, there's nothing more to do here.
        if (this.props.entities.length <= 0) {
            return;
        }
        // Set tile heights (if requested)
        if (this.props.forceEqualHeight) {
            this.setTileHeights();
        }
        // Try to focus on first tile of new content (if we just loaded a new page of tiles).
        this.focusFirstNewTile(prevProps);
    }

    private focusFirstNewTile(prevProps: IProps<Entity>) {
        const newEntityArrayIsSuperset = prevProps.entities.reduce(
            (memo, oldEntity) => {
                return memo && this.props.entities.indexOf(oldEntity) !== -1;
            },
            true,
        );
        if (!newEntityArrayIsSuperset) {
            return;
        }
        const newEntities = this.props.entities.filter((newEntity) => {
            return prevProps.entities.indexOf(newEntity) === -1;
        });
        const entityToFocus: Entity | undefined = newEntities[0];
        if (!entityToFocus) {
            return;
        }
        const tileID = this.props.getTileID(entityToFocus);
        const tileRef = this.tileRefs.get(tileID);
        // Within the chosen tile, find the first focusable element and focus on it.
        if (tileRef && tileRef.current) {
            const firstFocusableElem = getFocusableElems(tileRef.current)[0];
            if (firstFocusableElem) {
                focusElement(firstFocusableElem);
            }
        }
    }

    private getAllTiles() {
        if (!this.containerRef.current) {
            return [];
        }
        const elems = this.containerRef.current.querySelectorAll<HTMLElement>(
            this.props.itemSelector,
        );
        return Array.from(elems);
    }

    private getMaxTileHeight(tiles: HTMLElement[]) {
        return tiles.reduce((memo, elem) => {
            return Math.max(memo, elem.offsetHeight);
        }, 0);
    }

    private setTileHeights() {
        const tiles = this.getAllTiles();
        // Clear set heights so that we can measure the intrinsic height
        tiles.forEach((elem) => {
            elem.style.height = "";
        });
        // Measure the intrinsic height of each tile, then set each tile to the max.
        const height = this.getMaxTileHeight(tiles);
        tiles.forEach((elem) => {
            elem.style.height = `${height}px`;
        });
    }

    private attachEventHandlers() {
        if (!this.containerRef.current) {
            return;
        }
        // When images load, posts change size, so we need to recalculate the grid layout.
        this.containerRef.current.querySelectorAll("img").forEach((img) => {
            img.addEventListener("load", () => {
                this.layout();
            });
        });
    }

    private getOrCreateRef(tileID: string): React.RefObject<HTMLDivElement> {
        let ref = this.tileRefs.get(tileID);
        if (ref) {
            return ref;
        }
        ref = React.createRef<HTMLDivElement>();
        this.tileRefs.set(tileID, ref);
        return ref;
    }

    private updateGridLayout() {
        const oldNodes: HTMLDivElement[] | null = this.masonryGrid
            ?.getItemElements
            ? this.masonryGrid.getItemElements()
            : null;
        if (!this.containerRef.current || !oldNodes) {
            return;
        }
        const currentNodes =
            this.containerRef.current.querySelectorAll<HTMLDivElement>(
                this.props.itemSelector,
            );
        const newNodes = Array.from(currentNodes).filter((node) => {
            return !oldNodes.includes(node);
        });

        if (this.props.appendItems) {
            // Append new items to the end of layout
            this.appended(newNodes);
        } else {
            this.addItems(newNodes);
        }
        this.attachEventHandlers();
        this.reloadItems();
        this.layout();
    }

    render() {
        return (
            <div
                className={`masonry-grid ${this.props.className}`}
                ref={this.containerRef}
            >
                {this.props.entities.map((entity) => {
                    const tileID = this.props.getTileID(entity);
                    const ref = this.getOrCreateRef(tileID);
                    return this.props.buildTile(entity, ref);
                })}
                {this.props.entities.length <= 0 && this.props.emptyState}
            </div>
        );
    }
}
