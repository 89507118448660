import React, { useMemo } from "react";
import { t } from "ttag";

import sealyCC from "../../../../img/finance/sealy-cc-updated.png";
import stearnsCC from "../../../../img/finance/stearns-cc.png";
import tempurCC from "../../../../img/finance/tempur-cc-updated.png";
import { BreakPoint } from "../../../models/screen.interfaces";
import { getViewportBreakpoint } from "../../../utils/detectMobile";
import { getDefaultFinancingPlan } from "../../../utils/financing";
import { formatFinancingPlan } from "../../../utils/format";
import { FinancingModalTrigger } from "../../financing/FinancingModalTrigger";

import styles from "./FinancingModalTriggerFinance.module.scss";

export enum financingCCs {
    TEMPUR = "tempur",
    SEALY = "sealy",
    STEARNS = "stearns",
}

interface IProps {
    ccSource: financingCCs;
    applicationSource?: string;
}

export const FinancingModalTriggerFinance = (props: IProps) => {
    const isMobileWidth = () => {
        const currentBreakpoint = getViewportBreakpoint();
        return currentBreakpoint < BreakPoint.MEDIUM;
    };

    const plan = useMemo(
        () => formatFinancingPlan(getDefaultFinancingPlan()),
        [],
    );

    const getCCImg = () => {
        switch (props.ccSource) {
            case financingCCs.TEMPUR:
                return tempurCC;
            case financingCCs.SEALY:
                return sealyCC;
            case financingCCs.STEARNS:
                return stearnsCC;
            default:
                return tempurCC;
        }
    };

    if (isMobileWidth()) {
        return (
            <span>
                <div className={styles.mobile}>
                    <div>
                        <img
                            alt={`${String(props.ccSource)} Credit Card`}
                            src={getCCImg()}
                            className={styles.mobileImg}
                        />
                        <div>
                            <div>
                                {t`${plan.apr} APR`}
                                <br />
                                {t`for`}
                                <br />
                                {t`${plan.length} Months`}
                                <sup>
                                    <a href="#terms_and_conditions">
                                        {plan.superscript}
                                        <span className="ada-screenreader-only">
                                            {t`Additional information about Terms and Conditions`}
                                        </span>
                                    </a>
                                </sup>
                            </div>
                        </div>
                    </div>
                    <div
                        className={styles.mobileMinPurchase}
                    >{t`with minimum purchase of ${plan.threshold}`}</div>
                    <FinancingModalTrigger
                        modalType="full-app"
                        applicationSource={props.applicationSource}
                        className={`button`}
                    >
                        {t`Apply Now`}
                    </FinancingModalTrigger>
                    <div className={styles.mobileFooterCopy}>
                        or check if you{" "}
                        <FinancingModalTrigger
                            modalType="prequal-app"
                            applicationSource={props.applicationSource}
                            className={styles.prequalifyButton}
                        >
                            <strong>Pre-Qualify</strong>
                        </FinancingModalTrigger>
                        <br />
                        without affecting your credit score.
                    </div>
                </div>
            </span>
        );
    }

    return (
        <span>
            <div className={styles.root}>
                <img
                    alt={`${String(props.ccSource)} Credit Card`}
                    src={getCCImg()}
                    className={styles.desktopImg}
                />
                <div className={styles.copy}>
                    <div>
                        {t`${plan.apr} APR for ${plan.length} Months`}
                        <sup>
                            <a href="#terms_and_conditions">
                                {plan.superscript}
                                <span className="ada-screenreader-only">
                                    {t`Additional information about Terms and Conditions`}
                                </span>
                            </a>
                        </sup>
                    </div>
                    <div>{t`with minimum purchase of ${plan.threshold}`}</div>
                    <div className={styles.buttonCopy}>
                        <FinancingModalTrigger
                            modalType="full-app"
                            applicationSource={props.applicationSource}
                            className={`button`}
                        >
                            {t`Apply Now`}
                        </FinancingModalTrigger>
                        <div>
                            <div>
                                or check if you{" "}
                                <FinancingModalTrigger
                                    modalType="prequal-app"
                                    applicationSource={props.applicationSource}
                                    className={styles.prequalifyButton}
                                >
                                    Pre-Qualify
                                </FinancingModalTrigger>
                            </div>
                            <div>without affecting your credit score.</div>
                        </div>
                    </div>
                </div>
            </div>
        </span>
    );
};
