import classNames from "classnames";
import React from "react";
import { t } from "ttag";

import { FinancingModalTriggerTheme } from "../../../constants";
import { FinancingPlanMeta } from "../../../utils/financing";
import { FinancingModalTrigger } from "../FinancingModalTrigger";
import { Copy } from "./Copy";

export const Content = (props: {
    plan: FinancingPlanMeta;
    theme?: FinancingModalTriggerTheme;
    aprInfoID?: string | null;
    applicationSource?: string;
}) => {
    if (props.theme === FinancingModalTriggerTheme.SPECIAL) {
        return (
            <>
                <Copy
                    theme={props.theme}
                    plan={props.plan}
                    aprInfoID={props.aprInfoID}
                />
                <a href="/pre-qualification-and-financing" className="button">
                    {t`Learn More`}
                </a>
            </>
        );
    }

    const checkNowClassesDesktop = classNames({
        "button": true,
        "pre-approval-ad__button": true,
        "pre-approval-ad__button--block": true,
        "pre-approval-ad__button--check": true,
        "al-homepage__pre-approval-ad--check-now": true,
        "al-homepage__pre-approval-ad--check-now-desktop": true,
    });
    const buttonCopy =
        props.theme === FinancingModalTriggerTheme.MINI_FINANCING ||
        props.theme === FinancingModalTriggerTheme.CARD_OVERLAP
            ? t`Pre-Qualify`
            : t`Check Now`;

    return (
        <>
            <Copy
                theme={props.theme}
                plan={props.plan}
                aprInfoID={props.aprInfoID}
            />
            {
                <FinancingModalTrigger
                    modalType={"prequal-app"}
                    className={checkNowClassesDesktop}
                    applicationSource={props.applicationSource}
                >
                    {buttonCopy}
                </FinancingModalTrigger>
            }
        </>
    );
};
