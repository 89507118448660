import classNames from "classnames";
import React, { useMemo } from "react";
import { FinancingModalTrigger } from "tsi-common-react/src/apps/financing/FinancingModalTrigger";
import Trans from "tsi-common-react/src/common/Trans";
import { getDefaultFinancingPlan } from "tsi-common-react/src/utils/financing";
import { formatFinancingPlan } from "tsi-common-react/src/utils/format";
import { t } from "ttag";

import tempurCcSmall from "../../img/finance/tempur-cc-small.png";

interface IProps {
    onRequestParentClose?: () => void;
}

const iconClasses = classNames({
    "pre-approval-ad__icon": true,
    "pre-approval-ad__icon--finance": true,
    "pre-approval-ad__icon--card": true,
});

export const FinancingModalTriggerRetailModalPreapproval = (props: IProps) => {
    const plan = useMemo(
        () => formatFinancingPlan(getDefaultFinancingPlan()),
        [],
    );
    const onOpen = () => {
        if (props.onRequestParentClose) {
            props.onRequestParentClose();
        }
    };
    return (
        <span>
            <div className="pre-approval-ad u-flex-container">
                <img
                    className={iconClasses}
                    alt="Tempur-Pedic Credit Card"
                    src={tempurCcSmall}
                />
                <div className="pre-approval-ad__terms">
                    <div className="pre-approval-ad__terms-line pre-approval-ad__terms-line--1">
                        {t`${plan.apr} APR`}
                    </div>
                    <div className="pre-approval-ad__terms-line pre-approval-ad__terms-line--2">
                        {t`for `}
                        <div className="pre-approval-ad__line-rule"></div>
                    </div>
                    <div className="pre-approval-ad__terms-line pre-approval-ad__terms-line--3">
                        {t`${plan.length} Months`}
                        <sup>
                            <a href="/finance-your-purchase#terms_and_conditions">
                                {plan.superscript}
                            </a>
                        </sup>
                    </div>
                </div>
                <div className="pre-approval-ad__see">
                    <div className="pre-approval-ad__see--bold">
                        {t`Check if you're pre-qualified`}
                    </div>
                    <div className="pre-approval-ad__see--small">
                        {t`without affecting your credit score.`}
                    </div>
                </div>
                <FinancingModalTrigger
                    modalType="prequal-app"
                    className="
                            button
                            pre-approval-ad__button
                            pre-approval-ad__apply
                            al-financing-page__pre-approval-ad--apply-now
                            al-financing-page__pre-approval-ad--apply-now-desktop
                        "
                    title={t`Check Now`}
                    onClick={onOpen}
                >
                    {t`Check Now`}
                </FinancingModalTrigger>
            </div>
            <div className="pre-approval-ad pre-approval-ad--mobile u-flex-container">
                <div className="pre-approval-ad__terms">
                    <div className="pre-approval-ad__terms-line pre-approval-ad__terms-line--1">
                        {t`${plan.apr} APR for ${plan.length} Months`}
                        <sup>
                            <a href="/finance-your-purchase#terms_and_conditions">
                                {plan.superscript}
                            </a>
                        </sup>
                    </div>
                </div>
                <div className="pre-approval-ad__see">
                    <div className="pre-approval-ad__see--small">
                        <Trans
                            fmtString={t`Check if you <PrequalCopy>pre-qualify</PrequalCopy> without affecting your credit score.`}
                            data={{
                                PrequalCopy: (content) => (
                                    <FinancingModalTrigger
                                        key="1"
                                        modalType="prequal-app"
                                        title={t`Check Now`}
                                        onClick={onOpen}
                                    >
                                        {content}
                                    </FinancingModalTrigger>
                                ),
                            }}
                        />
                    </div>
                </div>
            </div>
        </span>
    );
};
