import React from "react";

import { NearbyStoresSearch } from "../../RetailLocator/containers/NearbyStoresSearch";
import { SiteSearchBar } from "./SiteSearchBar";

interface IProps {
    enableStoreSearch: boolean;
}

interface IState {
    isSiteSearchOpen: boolean;
}

export class SearchNav extends React.Component<IProps, IState> {
    public state: IState = {
        isSiteSearchOpen: false,
    };

    static defaultProps: IProps = {
        enableStoreSearch: true,
    };

    private readonly onOpenSearch = () => {
        this.setState({
            isSiteSearchOpen: true,
        });
    };

    private readonly onCloseSearch = () => {
        this.setState({
            isSiteSearchOpen: false,
        });
    };

    render() {
        return (
            <>
                {this.props.enableStoreSearch && (
                    <NearbyStoresSearch
                        isSiteSearchOpen={this.state.isSiteSearchOpen}
                    />
                )}
                <SiteSearchBar
                    isSearchOpen={this.state.isSiteSearchOpen}
                    onOpenSearch={this.onOpenSearch}
                    onCloseSearch={this.onCloseSearch}
                />
            </>
        );
    }
}
