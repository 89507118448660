import {
    IMattressMatchResults,
    IProduct,
} from "../../models/catalogue.interfaces";
import { ICustomerMatchPoint } from "./models.interfaces";
import { IReduxState } from "./reducers.interfaces";

export const userMatchPoint = (
    state: IReduxState,
): ICustomerMatchPoint | undefined => {
    return {
        optionValues: {
            budget_rank: state.ui.selections.selectedBudget || 1,
            firmness: state.ui.selections.selectedFirmness || 0,
        },
    };
};

export const matchEnabledProducts = (rootProducts: IProduct[]): IProduct[] => {
    return rootProducts.filter((p) => {
        return (
            !p.category_names.includes("Discontinued") &&
            !p.category_names.includes("Closeout") &&
            (p.attributes.firmness || true) &&
            (p.attributes.budget_rank || true)
        );
    });
};

export const mostSimilarProducts = (
    state: IReduxState,
): IMattressMatchResults | null => {
    if (!state.ui.loadedResults) {
        return null;
    }
    return state.ui.loadedResults;
};
