import React from "react";

import LoadingSpinner from "../../../common/LoadingSpinner";
import { IMattressMatchResult } from "../../../models/catalogue.interfaces";
import { useAppSelector } from "../../reducers";
import { mostSimilarProducts } from "../selectors";
import { Result } from "./Result";

import styles from "./Results.module.scss";

export const MattressMatchResults = () => {
    const mattressMatches = useAppSelector((state) =>
        mostSimilarProducts(state.mattressmatch),
    );
    if (!mattressMatches) {
        return (
            <div className={styles.loading}>
                <LoadingSpinner />
            </div>
        );
    }
    return (
        <>
            <div className={styles.results}>
                {mattressMatches.map(
                    (mattressMatchResult: IMattressMatchResult, idx) => {
                        return (
                            <Result
                                key={idx}
                                product={mattressMatchResult.product}
                                productImg={mattressMatchResult.image_url}
                                productCopy={mattressMatchResult.copy}
                                isBestMatch={idx === 0}
                            />
                        );
                    },
                )}
            </div>
        </>
    );
};
