import { IReduxState as IBaseRetailReduxState } from "tsi-common-react/src/apps/retail/reducers.interfaces";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import {
    IProductUUID,
    ISyncStoreID,
} from "tsi-common-react/src/models/nominals";

import { ICMSFlagshipStorePage } from "./models.interfaces";

export enum Action {
    SET_PRODUCT_AVAILABILITY = "RetailLocator/SET_PRODUCT_AVAILABILITY",
    SET_SELECTED_PRODUCT = "RetailLocator/SET_SELECTED_PRODUCT",
    ADD_FLAGSHIP_STORE_PAGES = "RetailLocator/ADD_FLAGSHIP_STORE_PAGES",
}

export interface IReduxState extends IBaseRetailReduxState {
    // Map Product UUIDs to sets of Store External IDs that have that product in stock
    productAvailability: {
        [productUUID: string]: ISyncStoreID[];
    };

    // UUID of selected product
    selectedProduct: IProductUUID | null;

    // Map of Flagship Store External ID to it's CMS pages
    flagshipStorePages: {
        [externalID: number]: ICMSFlagshipStorePage;
    };
}

interface IBaseAction {
    type: Action;
}

export interface IActionSetProductAvailability extends IBaseAction {
    type: Action.SET_PRODUCT_AVAILABILITY;
    payload: {
        product: IProductUUID;
        stores: IRetailStoreWithDistance[];
    };
}

export interface IActionSetSelectedProduct extends IBaseAction {
    type: Action.SET_SELECTED_PRODUCT;
    payload: IProductUUID | null;
}

export interface IActionAddFlagshipStorePages extends IBaseAction {
    type: Action.ADD_FLAGSHIP_STORE_PAGES;
    payload: ICMSFlagshipStorePage[];
}

export type IAction =
    | IActionSetProductAvailability
    | IActionSetSelectedProduct
    | IActionAddFlagshipStorePages;
