import React from "react";
import { connect } from "react-redux";
import { preferredLocationSelector } from "tsi-common-react/src/apps/common/selectors";
import {
    TDispatchMapper,
    TStateMapper,
} from "tsi-common-react/src/apps/reducers.interfaces";
import { IStoreSearchFilters } from "tsi-common-react/src/apps/retail/models.interfaces";
import {
    filteredStoreSelector,
    storeSelector,
} from "tsi-common-react/src/apps/retail/selectors";
import {
    ILocation,
    IRetailStoreWithDistance,
} from "tsi-common-react/src/models/location.interfaces";

import { LocatorFilters as LocatorFiltersComponent } from "../components/LocatorFilters";
import { Dispatchers } from "../dispatchers";

interface IReduxProps {
    filterValues: IStoreSearchFilters;
    currentLocation: ILocation | null;
    allStores: IRetailStoreWithDistance[];
    filteredStores: IRetailStoreWithDistance[];
}

interface IOwnProps {}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

type IProps = IReduxProps & IOwnProps & IDispatchProps;

interface IState {}

class LocatorFiltersContainer extends React.Component<IProps, IState> {
    private readonly onChange = (filters: Partial<IStoreSearchFilters>) => {
        this.props.dispatchers.updateStoreFilters(filters);
    };

    render() {
        return (
            <LocatorFiltersComponent
                filterValues={this.props.filterValues}
                currentLocation={this.props.currentLocation}
                allStores={this.props.allStores}
                filteredStores={this.props.filteredStores}
                onChange={this.onChange}
            />
        );
    }
}

const mapStateToProps: TStateMapper<"retail", IReduxProps, IOwnProps> = (
    state,
    ownProps,
) => {
    return {
        ...ownProps,
        filterValues: state.retail.storeFilters,
        currentLocation: preferredLocationSelector(state),
        allStores: storeSelector(state),
        filteredStores: filteredStoreSelector(state),
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const LocatorFilters = connect(
    mapStateToProps,
    mapDispatchToProps,
)(LocatorFiltersContainer);
