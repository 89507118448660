import React from "react";
import { connect } from "react-redux";
import { t } from "ttag";

import {
    TDispatchMapper,
    TStateMapper,
} from "../../../apps/reducers.interfaces";
import { ILocation } from "../../../models/location.interfaces";
import { isoLatitude, isoLongitude } from "../../../models/nominals";
import { geocode, loadGMaps, newAutocomplete } from "../../../utils/maps";
import { Dispatchers } from "../dispatchers";
import {
    getFormattedAddressForPlace,
    getPostCodeForPlace,
    preferredLocationSelector,
} from "../selectors";

interface IOwnProps {
    inputProps: React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    >;
    inputFormat: "short" | "long";
    country?: string;
    onAfterChange?: (searchText: string) => void;
}

interface IReduxProps {
    currentLocation: ILocation | null;
}

interface IDispatchProps {
    onUpdateEnteredLocation: (location: ILocation) => void;
}

type IProps = IReduxProps & IOwnProps & IDispatchProps;

interface IState {
    searchText: string;
}

class LocationInputContainer extends React.Component<IProps, IState> {
    state: IState = {
        searchText: this.props.currentLocation
            ? this.props.inputFormat === "long" &&
              this.props.currentLocation.formatted_address_long
                ? this.props.currentLocation.formatted_address_long
                : this.props.currentLocation.formatted_address
            : "",
    };

    public autocompleteElem = React.createRef<HTMLInputElement>();
    private autocompleteObj: google.maps.places.Autocomplete | undefined;
    private _searchTextAtTimeOfLastLocationUpdate = "";

    private readonly onTextChange = (
        event: React.FormEvent<HTMLInputElement>,
    ) => {
        this.setState({
            searchText: event.currentTarget.value,
        });
    };

    private readonly onBlur = () => {
        // If the field loses focus, but the search text has changed since we
        // last updated the location, update the location using the search text.
        if (
            this._searchTextAtTimeOfLastLocationUpdate !== this.state.searchText
        ) {
            this.updateLocationWithSearchText(this.state.searchText);
        }
    };

    private readonly onAutocompletePlaceChange = async () => {
        if (!this.autocompleteObj) {
            return;
        }
        const place = this.autocompleteObj.getPlace();
        const searchText = getFormattedAddressForPlace(
            place,
            this.props.inputFormat === "long",
        );
        this.setState({
            searchText: searchText,
        });
        const success = await this.updateLocationWithPlaceData(place);
        if (success && this.props.onAfterChange) {
            this.props.onAfterChange(searchText);
        }
    };

    async componentDidMount() {
        await loadGMaps();
        if (!this.autocompleteElem.current) {
            return;
        }
        let countries = [];
        switch (this.props.country) {
            case "MX":
                countries = ["mx"];
                break;
            default:
                countries = ["us", "bs", "ky", "pr", "bm"];
        }
        this.autocompleteObj = newAutocomplete(this.autocompleteElem.current, {
            componentRestrictions: {
                country: countries,
            },
        });
        this.autocompleteObj.addListener(
            "place_changed",
            this.onAutocompletePlaceChange,
        );
    }

    private async updateLocationWithPlaceData(
        place: google.maps.places.PlaceResult | google.maps.GeocoderResult,
    ): Promise<boolean> {
        if (!place.geometry || !place.geometry.location) {
            console.log(
                "Can not submit form with place data because place has no geometry info",
            );
            const placeName = (place as google.maps.places.PlaceResult).name;
            if (placeName) {
                return this.updateLocationWithSearchText(placeName);
            }
            return false;
        }
        this._searchTextAtTimeOfLastLocationUpdate = this.state.searchText;
        this.props.onUpdateEnteredLocation({
            formatted_address: getFormattedAddressForPlace(place),
            formatted_address_long: getFormattedAddressForPlace(place, true),
            lat: isoLatitude.wrap(place.geometry.location.lat()),
            lng: isoLongitude.wrap(place.geometry.location.lng()),
            zip: getPostCodeForPlace(place) || undefined,
        });
        return true;
    }

    private async updateLocationWithSearchText(
        searchText: string,
    ): Promise<boolean> {
        const results = await geocode({
            address: searchText,
        });
        if (results.length <= 0) {
            console.log("Geocoding request returned no results");
            return false;
        }
        return this.updateLocationWithPlaceData(results[0]);
    }

    render() {
        return (
            <>
                <input
                    {...this.props.inputProps}
                    aria-label="enter your location"
                    type="text"
                    autoComplete="off"
                    ref={this.autocompleteElem}
                    onChange={this.onTextChange}
                    onBlur={this.onBlur}
                    value={this.state.searchText}
                    aria-describedby="retail-locator-location_describedby"
                />
                <span
                    className="ada-screenreader-only"
                    id="retail-locator-location_describedby"
                >
                    {t`Begin typing to search, use arrow keys to navigate, Enter to select`}
                </span>
            </>
        );
    }
}

const mapStateToProps: TStateMapper<"common", IReduxProps, IOwnProps> = (
    state,
    ownProps,
) => {
    return {
        currentLocation: preferredLocationSelector(state),
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        onUpdateEnteredLocation: dispatchers.updateEnteredLocation,
    };
};

export const LocationInput = connect(
    mapStateToProps,
    mapDispatchToProps,
)(LocationInputContainer);
