import React from "react";
import { connect } from "react-redux";

import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { Actions } from "../actions";
import { ICustomerSummary } from "../models.interfaces";
import { CustomerDetails } from "./CustomerDetails";
import { FormView } from "./abstract/FormView";

interface IOwnProps {}

interface IReduxProps {}

interface IDispatchProps {
    actions: Actions;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

const generateRandomString = (len: number) => {
    const s = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    return Array(len)
        .join()
        .split(",")
        .map(() => {
            return s.charAt(Math.floor(Math.random() * s.length));
        })
        .join("");
};

const CustomerCreateComponent = (props: IProps) => {
    return (
        <FormView
            baseURL={"/api/csr/users/"}
            actions={props.actions}
            fields={[
                {
                    label: "Username",
                    name: "username",
                    type: "text",
                    isRequired: true,
                },
                {
                    label: "First Name",
                    name: "first_name",
                    type: "text",
                },
                {
                    label: "Last Name",
                    name: "last_name",
                    type: "text",
                },
                {
                    label: "Email Address",
                    name: "email",
                    type: "text",
                },
            ]}
            initialValues={{
                username: generateRandomString(30),
            }}
            onPostSave={async (customer: ICustomerSummary) => {
                const component = <CustomerDetails url={customer.url} />;
                props.actions.pushViewStack("Customer Details", component);
            }}
        />
    );
};

const mapStateToProps: TStateMapper<"csr", IReduxProps, IOwnProps> = (
    _rootState,
    ownProps,
) => {
    return {
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const actions = new Actions(dispatch);
    return {
        actions: actions,
    };
};

export const CustomerCreate = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CustomerCreateComponent);
