import React from "react";
import { connect } from "react-redux";

import {
    IFormUUID,
    IReviewsBrandID,
    IWebPageURL,
    isoProductUUID,
    isoReviewsProductID,
    isoReviewsProductVariantID,
} from "../../../models/nominals";
import { IReviewQuery } from "../../../models/reviews.interfaces";
import { getInteger } from "../../../utils/numbers";
import { parseFacetValues } from "../../../utils/reviews";
import { TStateMapper } from "../../reducers.interfaces";
import { defaultState } from "../defaults";
import { HeterogenousProductReviewForm } from "./HeterogenousProductReviewForm";
import { SingleProductWriteReviewForm } from "./SingleProductWriteReviewForm";

interface IOwnProps {
    formUUID: IFormUUID;
    brandID: IReviewsBrandID;
    brand?: string;
    returnURL?: IWebPageURL;
}

interface IReduxProps {
    facetValues: IReviewQuery;
}

interface IProps extends IReduxProps, IOwnProps {}

interface IState {}

class SingleProductReviewSwitchContainer extends React.Component<
    IProps,
    IState
> {
    render() {
        const productID = isoReviewsProductID.wrap(
            getInteger(this.props.facetValues.product_id, 0),
        );
        const variantIDs = parseFacetValues(
            this.props.facetValues.product_variant_id,
        ).map((v) => isoReviewsProductVariantID.wrap(getInteger(v, 0)));
        return variantIDs.length > 1 ? (
            <HeterogenousProductReviewForm
                parentUUID={isoProductUUID.wrap(String(this.props.formUUID))}
                productID={productID}
                variantIDs={variantIDs}
                returnURL={this.props.returnURL}
            />
        ) : (
            <SingleProductWriteReviewForm
                formUUID={this.props.formUUID}
                brandID={this.props.brandID}
                returnURL={this.props.returnURL}
            />
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IState> = (
    rootState,
) => {
    const state = rootState.reviews || defaultState;
    return {
        facetValues: state.ui.facetValues,
    };
};

export const SingleProductReviewSwitch = connect(mapStateToProps)(
    SingleProductReviewSwitchContainer,
);
