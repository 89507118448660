import React from "react";
import { isoOrderID } from "tsi-common-react/src/models/nominals";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";

dynamicPlaceComponent(
    '[data-place-react="order-cancel-button"]',
    async (elem) => {
        const { OrderCancelButton } = await import(
            "tsi-common-react/src/apps/orders/components/OrderCancelButton"
        );
        const orderID = parseInt(elem.dataset.order || "", 10);
        if (!orderID) {
            return null;
        }
        return <OrderCancelButton orderID={isoOrderID.wrap(orderID)} />;
    },
);

// Render Order Details Tracker Modal
dynamicPlaceComponent(
    '[data-place-react="order-detail-tracker-modal"]',
    async (elem) => {
        const { OrderDetailsTrackerModal } = await import(
            "tsi-common-react/src/apps/orders/components/OrderDetailsTrackerModal"
        );
        const orderTrackerModalData = JSON.parse(elem.dataset.cms || "null");
        const currentStep = elem.dataset.currentStep;
        const shippingMethod = elem.dataset.shippingMethod;
        const deliverySteps =
            shippingMethod === "White Glove Delivery"
                ? orderTrackerModalData.freight_delivery_steps
                : orderTrackerModalData.small_package_delivery_steps;
        const modalHead =
            shippingMethod === "White Glove Delivery"
                ? shippingMethod
                : "UPS Delivery";
        return (
            <OrderDetailsTrackerModal
                deliverySteps={deliverySteps}
                modalHead={modalHead}
                modalSubHead={
                    orderTrackerModalData.order_detail_tracker_modal_delivery_explanation
                }
                currentStep={currentStep}
            />
        );
    },
);
