import React from "react";
import { connect } from "react-redux";
import { t } from "ttag";

import { RichText } from "../../../common/RichText";
import {
    IOptionCode,
    IOptionValues,
    IProduct,
} from "../../../models/catalogue.interfaces";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { Dispatchers } from "../dispatchers";
import { tabFeatureAttributeBlock } from "../elementIDs";
import {
    ITabFeatureAttributeBlock_AttributeTab,
    ITabFeatureAttributeBlock_ModelTab,
} from "../models.interfaces";
import { rootProductsSelector } from "../selectors";
import { getOptionValueSet } from "../utils";

import styles from "./TabFeatureAttributeBlockModelTabContent.module.scss";

interface IOwnProps {
    attribute: IOptionCode;
    attrIdx: number;
    attrTab: ITabFeatureAttributeBlock_AttributeTab;
    modelIdx: number;
    modelTab: ITabFeatureAttributeBlock_ModelTab;
    isSelected: boolean;
}

interface IReduxProps {
    allRootProducts: IProduct[];
    selectedRootProducts: IProduct[];
    optionValues: IOptionValues;
}

interface IDispatchProps {
    setSelectedRootProduct: Dispatchers["setSelectedRootProduct"];
    setOptionValue: Dispatchers["setOptionValue"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class TabFeatureAttributeBlockModelTabContentComponent extends React.Component<
    IProps,
    IState
> {
    private readonly onSelect = () => {
        // First select the root product
        const rootProduct = this.props.allRootProducts.find((rp) => {
            return rp.id === this.props.modelTab.model;
        });
        if (!rootProduct) {
            console.error(
                `Cannot select root product ${this.props.modelTab.model} because it has not been loaded.`,
            );
            return;
        }
        this.props.setSelectedRootProduct(rootProduct);
        // Second, select the attribute
        this.props.setOptionValue(
            rootProduct.product_class_slug,
            this.props.attribute,
            0,
            0,
            this.props.attrTab.attribute_value,
        );
    };

    private get isProductSelected() {
        const selectedPIDs = new Set(
            this.props.selectedRootProducts.map((p) => p.id),
        );
        const productMatches = selectedPIDs.has(this.props.modelTab.model);
        const selectedReduxValues = getOptionValueSet(
            this.props.optionValues[this.props.attribute],
        );
        const valueMatches = selectedReduxValues.has(
            this.props.attrTab.attribute_value,
        );
        return productMatches && valueMatches;
    }

    render() {
        const buttonText = this.isProductSelected ? t`Selected` : t`Select`;
        return (
            <article
                className={styles.root}
                id={tabFeatureAttributeBlock.modelTabContent(
                    this.props.attrTab,
                    this.props.attrIdx,
                    this.props.modelTab,
                    this.props.modelIdx,
                )}
                role="tabpanel"
                tabIndex={0}
                aria-labelledby={tabFeatureAttributeBlock.modelTabButton(
                    this.props.attrTab,
                    this.props.attrIdx,
                    this.props.modelTab,
                    this.props.modelIdx,
                )}
                hidden={!this.props.isSelected}
            >
                <div className={styles.text}>
                    <RichText html={this.props.modelTab.content} />
                    <button
                        className="button"
                        onClick={this.onSelect}
                        disabled={this.isProductSelected}
                    >
                        {buttonText}
                    </button>
                </div>
            </article>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        allRootProducts: rootState.configurator.entities.rootProducts,
        selectedRootProducts: rootProductsSelector(rootState.configurator),
        optionValues: rootState.configurator.ui.optionValues,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        setSelectedRootProduct: dispatchers.setSelectedRootProduct,
        setOptionValue: dispatchers.setOptionValue,
    };
};

export const TabFeatureAttributeBlockModelTabContent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(TabFeatureAttributeBlockModelTabContentComponent);
