import { useAppDispatch, useAppSelector } from "../../reducers";
import { MattressSizeOptions } from "../constants";
import { onSelectSize } from "../reducers";

const sizeArray = Object.values(MattressSizeOptions);

const sizeClasses = {
    [MattressSizeOptions.TWIN]: "mattress-match-twin",
    [MattressSizeOptions.TWIN_XL]: "mattress-match-twin-xl",
    [MattressSizeOptions.FULL]: "mattress-match-full",
    [MattressSizeOptions.QUEEN]: "mattress-match-queen",
    [MattressSizeOptions.KING]: "mattress-match-king",
    [MattressSizeOptions.CAL_KING]: "mattress-match-cal_king",
    [MattressSizeOptions.SPLIT_KING]: "mattress-match-split_king",
    [MattressSizeOptions.CAL_SPLIT_KING]: "mattress-match-cal_split_king",
};

export const getMattressSizeData = (size: MattressSizeOptions) => {
    switch (size) {
        case MattressSizeOptions.TWIN:
            return { width: "38", height: "74" };
        case MattressSizeOptions.TWIN_XL:
            return { width: "38", height: "80" };
        case MattressSizeOptions.FULL:
            return { width: "53", height: "74" };
        case MattressSizeOptions.QUEEN:
            return {
                width: "60",
                height: "80",
            };
        case MattressSizeOptions.KING:
            return {
                width: "76",
                height: "80",
            };
        case MattressSizeOptions.CAL_KING:
            return {
                width: "72",
                height: "84",
            };
        case MattressSizeOptions.SPLIT_KING:
            return {
                width: "76",
                height: "80",
            };
        case MattressSizeOptions.CAL_SPLIT_KING:
            return {
                width: "72",
                height: "84",
            };
    }
};

export const useMattressSizeState = () => {
    const { selections } = useAppSelector((state) => state.mattressmatch.ui);
    const dispatch = useAppDispatch();

    const currentPos = sizeArray.indexOf(selections.selectedSize);
    const isNextDisabled = currentPos === sizeArray.length - 1;
    const isPrevDisabled = currentPos === 0;
    const onPrevSize = () => {
        if (isPrevDisabled) {
            return;
        }
        dispatch(
            onSelectSize({
                selectedSize: sizeArray[currentPos - 1],
            }),
        );
    };
    const onNextSize = () => {
        if (isNextDisabled) {
            return;
        }
        dispatch(
            onSelectSize({
                selectedSize: sizeArray[currentPos + 1],
            }),
        );
    };
    return {
        onPrevSize,
        onNextSize,
        selectedSize: selections.selectedSize,
        isNextDisabled,
        isPrevDisabled,
        sizeClass: sizeClasses[selections.selectedSize],
        ...getMattressSizeData(selections.selectedSize),
    };
};
