import React, { useId, useState } from "react";
import { t } from "ttag";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Trans } from "../../../common/Trans";
import {
    Form,
    FormCheckbox,
    FormInput,
    FormPhoneNumber,
    FormSelect,
    NonFieldErrors,
} from "../../../forms";
import { strings } from "../../../localization";
import { FinancingPreQualRequest } from "../../../models/financing";
import { useAppDispatch, useAppSelector } from "../../reducers";
import {
    ADDRESS_TOOL_TIP_LINK,
    ADDRESS_TOOL_TIP_TEXT,
    EMAIL_TOOL_TIP_LINK,
    EMAIL_TOOL_TIP_TEXT,
} from "../FinancingFullApplyForm/fields";
import { FinancingPreQualHero } from "../FinancingPreQualHero";
import { ModalStateType, STATE_CHOICES_WITH_SELECT_LABEL } from "../constants";
import { checkPreQualStatus, openApplyModal } from "../reducers";

import styles from "./index.module.scss";

type CommonFieldProps<ValueType> = Pick<
    React.ComponentProps<typeof FormInput> &
        React.ComponentProps<typeof FormSelect>,
    "disabled" | "errors" | "showErrorMessages" | "onChange"
> & {
    value: ValueType;
};

export const FinancingPreQualForm = () => {
    const dispatch = useAppDispatch();
    const prefillData = useAppSelector((state) => state.financing.prefillData);
    const modalState = useAppSelector((state) => state.financing.modal);
    const isSubmitting = modalState._tag === ModalStateType.PREQUAL_SUBMITTING;

    const hasAgreedID = useId();
    const [hasAgreed, setHasAgreed] = useState(false);
    const [values, setValues] = useState<FinancingPreQualRequest>({
        first_name: "",
        last_name: "",
        line1: "",
        city: "",
        state: "",
        postcode: "",
        email: "",
        phone: "",
        last_four_ssn: "",
        customer_initiated: true,
    });

    const submitErrors =
        modalState._tag === ModalStateType.PREQUAL_ERROR
            ? modalState.errors
            : null;

    const connectProps = <T extends keyof FinancingPreQualRequest>(
        field: T,
    ): CommonFieldProps<FinancingPreQualRequest[T]> => {
        const fieldErrs = submitErrors ? submitErrors[field] : [];
        return {
            disabled: isSubmitting,
            value: values[field],
            errors: typeof fieldErrs === "string" ? [fieldErrs] : fieldErrs,
            showErrorMessages: !!submitErrors,
            onChange: (e) => {
                const value = e.currentTarget.value;
                setValues((s) => ({
                    ...s,
                    [field]: value,
                }));
            },
        };
    };

    const onPrefill = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setValues((s) => ({
            ...s,
            first_name: prefillData.first_name || "",
            last_name: prefillData.last_name || "",
            line1: prefillData.address_line_1 || "",
            city: prefillData.city || "",
            state: prefillData.state_code || "",
            postcode: prefillData.postal_code || "",
            email: prefillData.email || "",
            phone:
                prefillData.home_phone ||
                prefillData.mobile_phone ||
                prefillData.work_phone ||
                "",
        }));
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(checkPreQualStatus(values));
    };

    return (
        <section>
            <FinancingPreQualHero />
            <div className={styles.wrapper}>
                <p>
                    <strong>
                        {t`It only takes a few minutes to find out if you
                            pre-qualify.`}
                    </strong>
                </p>
                <p>
                    {t`If you currently have a freeze on your credit file through a
                        credit reporting agency, please contact them directly to
                        remove the credit freeze before going through the
                        pre-qualification process.`}
                </p>
                {Object.keys(prefillData).length > 0 &&
                    prefillData.first_name && (
                        <button
                            className={concatClassNames([
                                "button",
                                "button--secondary",
                                styles.prefillButton,
                            ])}
                            onClick={onPrefill}
                        >
                            {t`Prefill Account Info`}
                        </button>
                    )}
                <Form
                    className={styles.form}
                    onSubmit={onSubmit}
                    noValidate={true}
                >
                    {submitErrors?.detail && (
                        <NonFieldErrors
                            errors={[submitErrors.detail]}
                            showErrorMessages={true}
                        />
                    )}
                    {submitErrors?.non_field_errors && (
                        <NonFieldErrors
                            errors={submitErrors.non_field_errors}
                            showErrorMessages={true}
                        />
                    )}
                    <FormInput
                        className={styles.formField}
                        labelCSSClass={styles.formLabel}
                        wrapperCSSClass={styles.formWrapper}
                        type="text"
                        id="first_name"
                        name="first_name"
                        label={t`First Name`}
                        validation={["required", "firstname-empty"]}
                        maxLength={25}
                        autoComplete="given-name"
                        {...connectProps("first_name")}
                    />
                    <FormInput
                        className={styles.formField}
                        labelCSSClass={styles.optionalFormLabel}
                        wrapperCSSClass={styles.formWrapper}
                        type="text"
                        id="middle_initial"
                        name="middle_initial"
                        label={t`M.I.`}
                        maxLength={2}
                        autoComplete="additional-name"
                        {...connectProps("middle_initial")}
                    />
                    <FormInput
                        className={styles.formField}
                        labelCSSClass={styles.formLabel}
                        wrapperCSSClass={styles.formWrapper}
                        type="text"
                        id="last_name"
                        name="last_name"
                        label={t`Last Name`}
                        validation={["required", "lastname-empty"]}
                        maxLength={25}
                        autoComplete="family-name"
                        {...connectProps("last_name")}
                    />
                    <FormInput
                        className={styles.formField}
                        labelCSSClass={styles.formLabel}
                        wrapperCSSClass={styles.formWrapper}
                        type="text"
                        id="physical_street_address"
                        name="physical_street_address"
                        label={t`Physical Street Address`}
                        validation={["required", "street-address-empty"]}
                        tooltipLink={ADDRESS_TOOL_TIP_LINK}
                        tooltipText={ADDRESS_TOOL_TIP_TEXT}
                        maxLength={25}
                        maxBytes={240}
                        autoComplete="address-line1"
                        {...connectProps("line1")}
                    />
                    <FormInput
                        className={styles.formField}
                        labelCSSClass={styles.optionalFormLabel}
                        wrapperCSSClass={styles.formWrapper}
                        type="text"
                        id="address_line_2"
                        name="address_line_2"
                        label={t`Address Line 2`}
                        maxLength={25}
                        maxBytes={240}
                        autoComplete="address-line2"
                        {...connectProps("line2")}
                    />
                    <FormInput
                        className={styles.formField}
                        labelCSSClass={styles.formLabel}
                        wrapperCSSClass={styles.formWrapper}
                        type="text"
                        id="city"
                        name="city"
                        label={t`City`}
                        validation={["required", "city-empty"]}
                        maxLength={60}
                        maxBytes={60}
                        autoComplete="address-level2"
                        {...connectProps("city")}
                    />
                    <FormSelect
                        className={styles.formSelect}
                        labelCSSClass={styles.formLabel}
                        wrapperCSSClass={styles.formSelectWrapper}
                        id="state"
                        name="state"
                        label={t`State`}
                        validation={["required", "state-empty"]}
                        choices={STATE_CHOICES_WITH_SELECT_LABEL}
                        autoComplete="address-level1"
                        {...connectProps("state")}
                    />
                    <FormInput
                        className={styles.formField}
                        labelCSSClass={styles.formLabel}
                        wrapperCSSClass={styles.formWrapper}
                        type="text"
                        id="zip_code"
                        name="zip_code"
                        label={t`ZIP Code`}
                        validation={["required", "zipcode"]}
                        maxLength={10}
                        maxBytes={60}
                        autoComplete="postal-code"
                        {...connectProps("postcode")}
                    />
                    <FormPhoneNumber
                        className={styles.formField}
                        labelCSSClass={styles.formLabel}
                        wrapperCSSClass={styles.formWrapper}
                        type="phone"
                        id="phone_number"
                        name="phone_number"
                        label={t`Phone Number`}
                        validation={["required", "phone"]}
                        autoComplete="tel"
                        {...connectProps("phone")}
                    />
                    <FormInput
                        className={styles.formField}
                        labelCSSClass={styles.formLabel}
                        wrapperCSSClass={styles.formWrapper}
                        label={t`Email Address`}
                        name="email"
                        id="email"
                        validation={["required", "email"]}
                        autoComplete="email"
                        tooltipLink={EMAIL_TOOL_TIP_LINK}
                        tooltipText={EMAIL_TOOL_TIP_TEXT}
                        tooltipClass={styles.tooltipIcon}
                        {...connectProps("email")}
                    />
                    <FormInput
                        className={styles.formField}
                        labelCSSClass={styles.formLabel}
                        wrapperCSSClass={styles.formWrapper}
                        type="text"
                        id="last_four_ssn"
                        name="last_four_ssn"
                        label={t`Last 4 digits of Social Security Number`}
                        tooltipLink={"Why do we need this?"}
                        tooltipText={
                            "We ask for the last four of your Social Security number to help verify your identity."
                        }
                        tooltipClass={styles.tooltipIcon}
                        validation={["required", "ssn_last_four"]}
                        pattern="[0-9]*"
                        maxLength={4}
                        {...connectProps("last_four_ssn")}
                    />
                    <div className={styles.notice}>
                        <Trans
                            fmtString={t`We are unable to pre-qualify customers without a Social Security number. If you have an Individual Tax Identification Number (ITIN), please skip the pre-qualification process and <A>complete a credit application using your ITIN.</A>`}
                            data={{
                                A: (content) => (
                                    <a
                                        className={styles.link}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            dispatch(openApplyModal({}));
                                        }}
                                    >
                                        {content}
                                    </a>
                                ),
                            }}
                        />
                    </div>
                    <FormCheckbox
                        id={hasAgreedID}
                        className={styles.checkbox}
                        labelCSSClass={styles.checkboxLabel}
                        wrapperCSSClass={styles.checkboxWrapper}
                        label={
                            strings.get("FINANCING_PREQUAL_CONSENT_MSG") || ""
                        }
                        name="has_agreed"
                        checked={hasAgreed}
                        onChange={(e) => {
                            setHasAgreed(e.currentTarget.checked);
                        }}
                    />
                    <p className={styles.legalCopy}>
                        {t`I also understand that if Wells Fargo, N.A has no available offers, the above information will be sent to secondary lenders, and those secondary lenders may conduct a soft pull of my credit that will not affect my credit score.`}
                    </p>
                    <button
                        type="submit"
                        className={concatClassNames([
                            "button",
                            styles.submitButton,
                        ])}
                        disabled={!hasAgreed}
                    >
                        {t`Submit`}
                    </button>
                </Form>
            </div>
        </section>
    );
};
