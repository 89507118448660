import classNames from "classnames";
import React from "react";

import { IQuickSearchItem } from "../models.interfaces";

const QuickSearchItem = (props: IQuickSearchItem) => {
    const navClasses = classNames({
        "quicksearch__nav-item": true,
        "al-store-locator__nav-item--quicksearch": true,
        "quicksearch__nav-item--selected": props.selected,
    });

    return (
        <div className={navClasses}>
            <a
                className={`quicksearch__nav-button ${props.labelClassName}`}
                href={props.href}
            >
                <div className="quicksearch__nav-img-wrapper">
                    <img
                        className="quicksearch__nav-img"
                        alt={props.imgAlt}
                        src={props.imgSrc}
                    />
                </div>
                <h4 className="quicksearch__nav-title">{props.title}</h4>
            </a>
            <div className="quicksearch__nav-descript">{props.descr}</div>
        </div>
    );
};

interface ILocatorQuickSearchComponentProps {
    items: IQuickSearchItem[];
    selectedItem: string | undefined;
}

export const LocatorQuickSearch = (
    props: ILocatorQuickSearchComponentProps,
) => {
    return (
        <nav className="quicksearch__nav">
            {props.items.map((item) => {
                return (
                    <QuickSearchItem
                        key={item.id}
                        {...item}
                        selected={props.selectedItem === item.id}
                    />
                );
            })}
        </nav>
    );
};
