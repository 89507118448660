import React from "react";
import { connect } from "react-redux";

import { IFormUUID } from "../../../models/nominals";
import { IReviewsProduct } from "../../../models/reviews.interfaces";
import { TStateMapper } from "../../reducers.interfaces";
import { WriteReviewFormMode } from "../constants";
import { IWriteReviewFormState } from "../reducers.interfaces";
import {
    getWriteReviewFormHTMLID,
    getWriteReviewFormState,
} from "../selectors";
import { MultiProductWriteReviewFormProductHeading } from "./MultiProductWriteReviewFormProductHeading";
import { WriteReviewFormFields } from "./WriteReviewFormFields";

interface IOwnProps {
    formUUID: IFormUUID;
    brand?: string;
    product: IReviewsProduct;
    isMultiReview?: boolean;
    side?: string;
}

interface IReduxProps {
    formState: IWriteReviewFormState;
}

interface IProps extends IOwnProps, IReduxProps {}

interface IState {}

class MultiProductWriteReviewFormSectionComponent extends React.Component<
    IProps,
    IState
> {
    render() {
        if (!this.props.product.uuid) {
            return null;
        }
        const isOpen =
            this.props.formState.mode === WriteReviewFormMode.FORM_OPEN;
        return (
            <div
                id={getWriteReviewFormHTMLID(this.props.formUUID)}
                className="write-review-form"
            >
                <MultiProductWriteReviewFormProductHeading
                    formUUID={this.props.formUUID}
                    productUUID={this.props.product.uuid}
                    side={this.props.side}
                />
                {isOpen && (
                    <WriteReviewFormFields
                        formUUID={this.props.formUUID}
                        brand={this.props.brand}
                        isMultiReview={this.props.isMultiReview}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        ...ownProps,
        formState: getWriteReviewFormState(rootState.reviews, ownProps),
    };
};

export const MultiProductWriteReviewFormSection = connect(mapStateToProps)(
    MultiProductWriteReviewFormSectionComponent,
);
