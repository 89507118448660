import React from "react";
import SVG from "react-inlinesvg";
import Modal from "react-modal";
import { focusElement } from "tsi-common-react/src/utils/keyboardFocus";
import { t } from "ttag";

import iconXClose from "../../svg/x-close.svg";

interface IOwnProps {
    mapSmallSrc: string;
    mapLargeSrc: string;
    mapImageAlt: string;
}

type IProps = IOwnProps;

interface IState {
    modalIsOpen: boolean;
}

export class FlagshipMapModal extends React.Component<IProps, IState> {
    public state: IState = {
        modalIsOpen: false,
    };

    private readonly onOpenModal = (event: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
        }
        this.setState({
            modalIsOpen: true,
        });
    };

    private readonly onCloseModal = (event: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
        }
        this.setState({
            modalIsOpen: false,
        });
    };

    render() {
        return (
            <div className="modal">
                <button
                    type="button"
                    className="modal-trigger"
                    id="modal-trigger"
                    onClick={this.onOpenModal}
                >
                    <img
                        alt={this.props.mapImageAlt}
                        src={this.props.mapSmallSrc}
                    />
                </button>
                <Modal
                    aria={{
                        modal: true,
                    }}
                    contentLabel={t`Flagship store information`}
                    className="modal-inner"
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={() => {
                        focusElement(".modal-close button--ghost");
                    }}
                    onRequestClose={this.onCloseModal}
                    role="dialog"
                >
                    <button
                        aria-label={t`Close`}
                        className="modal-close button--ghost"
                        onClick={this.onCloseModal}
                    >
                        <SVG
                            aria-hidden="true"
                            className="modal-close__icon"
                            src={iconXClose}
                            title={t`Close Icon`}
                        />
                    </button>
                    <img
                        alt={this.props.mapImageAlt}
                        src={this.props.mapLargeSrc}
                    />
                </Modal>
            </div>
        );
    }
}
