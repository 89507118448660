import React from "react";

import {
    BackendType,
    FinancingPreQualResponse,
} from "../../../models/financing";
import { FormStepOtherJSX, FormStepType } from "../../../models/forms";
import { FinancingFullTerms } from "../FinancingFullTerms";
import { FinancingAppType } from "../constants";
import {
    AgreeState,
    ClientErrors,
    IndividualSteps,
    JointSteps,
} from "../models";
import {
    jointAppFieldsContact,
    jointAppFieldsFinance,
    jointAppFieldsID,
    mainAppFieldsContact,
    mainAppFieldsFinance,
    mainAppFieldsID,
} from "./fields";

export type AgreeProps = {
    backendType: BackendType | null;
    offer: FinancingPreQualResponse | null;
    onValidStateChange: (fieldName: string, errorMessages: string[]) => void;
    showErrorMessages: boolean;
    clientErrors: ClientErrors;
    onAgree: (event: React.FormEvent<HTMLInputElement>) => void;
    onESign: (event: React.FormEvent<HTMLInputElement>) => void;
};

type Applicant = "main" | "joint";

const buildAgreeTermsStepContent = (
    stepIndex: number,
    applicant: Applicant,
    agreeState: AgreeState,
    props: AgreeProps,
) => {
    const isMain = applicant === "main";
    const hasAgreed = isMain
        ? agreeState.agree_disclosure
        : agreeState.agree_disclosure_joint;
    const hasESigned = isMain ? agreeState.esign : agreeState.esign_joint;
    return (
        <FinancingFullTerms
            key={stepIndex}
            backendType={props.backendType}
            offer={props.offer}
            isMain={isMain}
            hasAgreed={hasAgreed}
            hasESigned={hasESigned}
            errors={props.clientErrors}
            showErrorMessages={props.showErrorMessages}
            onAgree={props.onAgree}
            onESign={props.onESign}
            onValidStateChange={props.onValidStateChange}
        />
    );
};

const buildAgreeTermsStep = (
    applicant: Applicant,
    agreeState: AgreeState,
    props: AgreeProps,
): FormStepOtherJSX => {
    return {
        stepType: FormStepType.OtherJSX,
        buildContent: (index) => {
            return buildAgreeTermsStepContent(
                index,
                applicant,
                agreeState,
                props,
            );
        },
    };
};

const getIndividualFormSteps = (
    agreeState: AgreeState,
    props: AgreeProps,
): IndividualSteps => {
    const terms = buildAgreeTermsStep("main", agreeState, props);
    return [
        mainAppFieldsContact,
        mainAppFieldsFinance,
        mainAppFieldsID,
        terms,
    ] as const;
};

const getJointFormSteps = (
    agreeState: AgreeState,
    props: AgreeProps,
): JointSteps => {
    const terms = buildAgreeTermsStep("joint", agreeState, props);
    return [
        ...getIndividualFormSteps(agreeState, props),
        jointAppFieldsContact,
        jointAppFieldsFinance,
        jointAppFieldsID,
        terms,
    ] as const;
};

export const getFormSteps = (
    appType: FinancingAppType,
    agreeState: AgreeState,
    props: AgreeProps,
) => {
    return appType === FinancingAppType.JOINT
        ? getJointFormSteps(agreeState, props)
        : getIndividualFormSteps(agreeState, props);
};
