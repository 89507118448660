import React from "react";
import { FormInput } from "tsi-common-react/src/forms/FormInput";
import { t } from "ttag";

interface IProps {
    isSubmitting: boolean;
    onCancel: () => void;
    onSubmit: (recipient: string) => void;
}

interface IState {
    recipient: string;
}

export class LocatorSendSingleStoreForm extends React.Component<
    IProps,
    IState
> {
    state: IState = {
        recipient: "",
    };

    private readonly onRecipientChange = (
        e: React.FormEvent<HTMLInputElement>,
    ) => {
        this.setState({
            recipient: e.currentTarget.value,
        });
    };

    private readonly onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.props.onSubmit(this.state.recipient);
    };

    render() {
        return (
            <div className="send__content survey__carousel">
                <h2>{t`Email Me Details About This Store.`}</h2>
                <form onSubmit={this.onSubmit}>
                    <fieldset>
                        <legend className="ada-screenreader-only">
                            {t`Email Me Details About This Store.`}
                        </legend>
                        <div className="form__field send__inputs">
                            <FormInput
                                id="send-store-recipient"
                                name="send-store-recipient"
                                required={true}
                                placeholder={t`Enter an Email Address`}
                                type={"email"}
                                value={this.state.recipient}
                                onChange={this.onRecipientChange}
                                disabled={this.props.isSubmitting}
                            />
                        </div>
                        <div className="send__actions">
                            <button
                                className="button send__button"
                                type="submit"
                                aria-label={t`Send`}
                                disabled={this.props.isSubmitting}
                            >
                                {t`Send`}
                            </button>
                            <a
                                title="Close"
                                className="send__cancel modal-cancel"
                                onClick={this.props.onCancel}
                                href="#"
                            >
                                {t`Cancel`}
                            </a>
                        </div>
                    </fieldset>
                </form>
            </div>
        );
    }
}
