import React from "react";

import { IMenuFooter } from "../../menu.interface";

interface IProps {
    navFooterEl: IMenuFooter;
}

export const NavFooter = (props: IProps) => {
    return (
        <div className="main-dropdown-menu__footer l-full-width">
            <div
                className="l-capped-width"
                dangerouslySetInnerHTML={{
                    __html: props.navFooterEl.value.html,
                }}
            ></div>
        </div>
    );
};
