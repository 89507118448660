import React from "react";
import { connect } from "react-redux";

import { IAddressCountry } from "../../../../models/address.interfaces";
import { TStateMapper } from "../../../reducers.interfaces";

interface IOwnProps {
    title: string;
    first_name: string;
    last_name: string;
    line1: string;
    line2: string;
    line4: string;
    state: string;
    postcode: string;
    country: string;
}

interface IReduxProps {
    countries: IAddressCountry[];
}

interface IProps extends IOwnProps, IReduxProps {}

interface IState {}

class AddressComponent extends React.Component<IProps, IState> {
    render() {
        const country = this.props.countries.find((c) =>
            c.url.includes(this.props.country),
        );
        return (
            <p className="csr-address">
                {this.props.title || "-"}. {this.props.first_name || "-"}{" "}
                {this.props.last_name || "-"}
                <br />
                {this.props.line1 || "-"}
                <br />
                {this.props.line2 || "-"}
                <br />
                {this.props.line4 || "-"}, {this.props.state || "-"}{" "}
                {this.props.postcode || "-"}
                <br />
                {country ? country.printable_name : "-"}
            </p>
        );
    }
}

const mapStateToProps: TStateMapper<"csr", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        countries: rootState.csr.countries,
        ...ownProps,
    };
};

export const Address = connect(mapStateToProps)(AddressComponent);
