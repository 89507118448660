import React from "react";
import { t } from "ttag";

import { UGCListSubBlock, UGCPost } from "../models/ugc";
import { UGCGrid } from "./UGCGrid";

interface IProps {
    pageSize: number;
    random: boolean;
}

interface IState {
    posts: UGCListSubBlock[];
    page: number;
    limit?: number;
}

export class UGCApiGrid extends React.Component<IProps, IState> {
    readonly state: IState = {
        posts: [],
        page: 0,
    };

    static defaultProps: Partial<IProps> = {
        random: false,
    };

    componentDidMount() {
        this.getNextPage();
    }

    private readonly getNextPage = async () => {
        let url = `/api/cms/v2/social/?limit=${this.props.pageSize}`;
        if (this.props.random) {
            url += "&order=random";
        } else {
            url += `&offset=${this.props.pageSize * this.state.page}`;
        }
        const response: Response = await fetch(url).then((r) => r.json());
        const items = response.items.map((post) => ({
            post: post,
        }));
        const limit = response.meta.total_count;
        this.setState((state) => ({
            posts: [...state.posts, ...items],
            page: state.page + 1,
            limit: limit,
        }));
    };

    render() {
        return (
            <>
                <UGCGrid tiles={this.state.posts} />
                {!this.props.random &&
                this.state.limit &&
                this.state.posts.length < this.state.limit ? (
                    <button
                        className="button button--full-width button--alabaster load-more-posts"
                        onClick={this.getNextPage}
                    >
                        {t`Load More Posts`}
                    </button>
                ) : null}
            </>
        );
    }
}

interface Response {
    meta: {
        total_count: number;
    };
    items: UGCPost[];
}
