import classNames from "classnames";
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { t } from "ttag";

import { FormCheckbox } from "../../../forms";
import { strings } from "../../../localization";
import { useAppDispatch, useAppSelector } from "../../reducers";
import { BodyTypeData } from "../constants";
import { SVGLoader } from "../elements/SVGLoader";
import { IMattressBodyType, IMattressOption } from "../models.interfaces";
import { onSelectBodyType } from "../reducers";

import styles from "./MattressBodyType.module.scss";

const LARGE_WIDTH_THRESHOLD = 800;

export const MattressSelectorBodyType = () => {
    const { selections } = useAppSelector((state) => state.mattressmatch.ui);
    const [isLargeScreen, setIsLargeScreen] = useState<boolean>(
        window.innerWidth > LARGE_WIDTH_THRESHOLD,
    );
    const dispatch = useAppDispatch();
    const HEIGHT_OPTIONS = BodyTypeData.height || [];
    const WEIGHT_OPTIONS = BodyTypeData.weight || [];

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth > LARGE_WIDTH_THRESHOLD);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const onClick = async (
        optionType: string,
        option: IMattressOption | IMattressBodyType,
    ) => {
        const isHeight = optionType === "height";
        if (isHeight) {
            dispatch(
                onSelectBodyType({
                    selectedHeight: option.level,
                }),
            );
        } else {
            dispatch(
                onSelectBodyType({
                    selectedWeight: option.level,
                }),
            );
        }
    };

    function buildOptions(
        optionType: string,
        option: IMattressOption | IMattressBodyType,
    ) {
        let isChecked = false;
        if (optionType === "height") {
            isChecked = selections.selectedHeight === option.level;
        }
        if (optionType === "weight") {
            isChecked = selections.selectedWeight === option.level;
        }
        const classes = classNames({
            [styles.listItem]: true,
            [styles.checked]: isChecked,
        });
        const icon = option.icon ?? null;
        return (
            <li key={option.level} className={classes}>
                <FormCheckbox
                    className="radio"
                    checked={isChecked}
                    id={option.icon}
                    name={optionType}
                    value={option.label}
                    onChange={() => {
                        onClick(optionType, option);
                    }}
                    required={true}
                    readOnly
                />

                {option.icon && (
                    <label htmlFor={option.icon} aria-selected={false}>
                        {icon && (
                            <SVG
                                aria-hidden="true"
                                src={icon}
                                title={option.label}
                                loader={<SVGLoader />}
                            />
                        )}

                        <div>{option.label}</div>
                    </label>
                )}
            </li>
        );
    }
    function buildOptionDescription() {
        const description =
            selections.selectedHeight || selections.selectedWeight
                ? strings.get("MATTRESS_SELECTOR_BODY_TYPE_OPTION_DESCRIPTION")
                : strings.get(
                      "MATTRESS_SELECTOR_BODY_TYPE_DEFAULT_DESCRIPTION",
                  );

        return (
            <section
                className={styles.optionDescription}
                dangerouslySetInnerHTML={{
                    __html: `<p>${description}</ p>`,
                }}
            ></section>
        );
    }
    return (
        <>
            <div className={styles.container}>
                <div className={styles.selectionsContainer}>
                    <fieldset>
                        <legend>
                            <span>{t`How tall are you?`}</span>
                        </legend>
                        <ul className={styles.list}>
                            {HEIGHT_OPTIONS.map((height) => {
                                return buildOptions("height", height);
                            })}
                        </ul>
                    </fieldset>
                    <fieldset className={styles.weightContainer}>
                        <legend>
                            <span>{t`What is your average weight?`}</span>
                        </legend>
                        <ul className={styles.list}>
                            {WEIGHT_OPTIONS.map((weight) => {
                                return buildOptions("weight", weight);
                            })}
                        </ul>
                    </fieldset>
                </div>
                {isLargeScreen && buildOptionDescription()}
            </div>
            {!isLargeScreen && buildOptionDescription()}
        </>
    );
};
