import { defaults as baseDefaults } from "tsi-common-react/src/apps/retail/defaults";
import { ILocationLatLon } from "tsi-common-react/src/models/location.interfaces";
import {
    isoLatitude,
    isoLongitude,
    isoMiles,
} from "tsi-common-react/src/models/nominals";

import { IReduxState } from "./reducers.interfaces";

export const defaults: IReduxState = {
    ...baseDefaults,
    productAvailability: {},
    selectedProduct: null,
    flagshipStorePages: {},
    storeFilters: {
        limit: 5,
        distance: isoMiles.wrap(20),
        category: null,
        retailerName: [],
    },
};

export const defaultLocation: ILocationLatLon = {
    formatted_address: "Lexington, KY",
    lat: isoLatitude.wrap(38.029722),
    lng: isoLongitude.wrap(-84.494722),
};
