import React from "react";

import { GenericErrorSet } from "../models/formFields";
import { IFieldSet, IValueSet } from "../models/formFields.interfaces";
import { FormStep, FormStepType } from "../models/forms";
import { AutoFields } from "./AutoFields";
import { FieldsSummary } from "./FieldsSummary";

interface BaseProps<T extends string> {
    className: string;

    fields: IFieldSet<T>;

    onChange: (name: T, value: string) => void;
    onValidStateChange: (fieldName: T, errorMessages: string[]) => void;
    onSummaryEdit: (step: number) => void;

    values: IValueSet<T>;

    disabled: boolean;

    showErrorMessages: boolean;
    errors: GenericErrorSet<T>;
}

interface SingleStepProps<FieldNames extends string> {
    step: FormStep<FieldNames>;
    index: number;
}

const StepForm = <T extends string>(
    props: BaseProps<T> & SingleStepProps<T>,
) => {
    switch (props.step.stepType) {
        case FormStepType.FieldSet:
            return (
                <AutoFields
                    heading={props.step.groupTitle}
                    fieldOrder={props.step.fields}
                    fields={props.fields}
                    values={props.values}
                    errors={props.errors}
                    disabled={props.disabled}
                    onChange={props.onChange}
                    onValidStateChange={props.onValidStateChange}
                    showErrorMessages={props.showErrorMessages}
                />
            );

        case FormStepType.OtherJSX:
            return props.step.buildContent(props.index);
    }
};

const StepSummary = <T extends string>(
    props: BaseProps<T> & SingleStepProps<T>,
) => {
    switch (props.step.stepType) {
        case FormStepType.FieldSet:
            return (
                <FieldsSummary
                    key={props.index}
                    step={props.index}
                    heading={props.step.groupTitle}
                    fieldOrder={props.step.fields}
                    fields={props.fields}
                    values={props.values}
                    onSummaryEdit={props.onSummaryEdit}
                />
            );

        case FormStepType.OtherJSX:
            return props.step.summary ? props.step.summary : null;
    }
};

export const AutoFieldsMultistep = <T extends string>(
    props: BaseProps<T> & {
        steps: Readonly<FormStep<T>[]>;
        currStepIndex: number;
        disabledSteps: number[];
    },
) => {
    const currentStep = props.steps.find(
        (_step, index) => index === props.currStepIndex,
    );
    if (!currentStep) {
        return null;
    }
    const pastSteps = props.steps.filter(
        (_step, index) => index < props.currStepIndex,
    );
    return (
        <div className={props.className}>
            {pastSteps.map((step, i) => (
                <StepSummary
                    key={i}
                    {...props}
                    step={step}
                    index={props.steps.indexOf(step)}
                />
            ))}
            <StepForm
                {...props}
                step={currentStep}
                index={props.steps.indexOf(currentStep)}
                disabled={
                    props.disabled ||
                    props.disabledSteps.includes(props.currStepIndex)
                }
            />
        </div>
    );
};
