import React from "react";
import { t } from "ttag";

import { Trans } from "../../../common/Trans";
import { strings } from "../../../localization";
import { useAppDispatch } from "../../reducers";
import { closeModal } from "../reducers";

import styles from "./index.module.scss";

export const FinancingPreQualExistingCardHolder = () => {
    const dispatch = useAppDispatch();
    return (
        <div className={styles.root}>
            <h2 className={styles.heading}>{t`Thank You for Your Request`}</h2>
            <p className={styles.copy}>
                {t`Our systems indicate that you already have an existing credit card account.`}
            </p>
            <p className={styles.copy}>
                <Trans
                    fmtString={t`Please contact Wells Fargo at <PhoneNumber></PhoneNumber> to obtain your account information (press 0 through the prompts to speak with a representative). Customer Service hours are Monday - Friday, 8:00 am - 6:00 pm Central Time.`}
                    data={{
                        PhoneNumber: () => (
                            <a href="tel:1-800-459-8451" key="1">
                                1-800-459-8451
                            </a>
                        ),
                    }}
                />
            </p>
            <button
                className="button"
                onClick={(e) => {
                    e.preventDefault();
                    dispatch(closeModal());
                }}
            >
                {strings.get("FINANCING_RETURN_TO_SITE") || t`Return`}
            </button>
        </div>
    );
};
