import React from "react";
import { connect } from "react-redux";
import { t } from "ttag";

import { LoadingSpinner } from "../../../common/LoadingSpinner";
import {
    IBasket,
    IProduct,
    IUserConfigurableBundle,
} from "../../../models/catalogue.interfaces";
import { PriceType, getProductPrice } from "../../../utils/catalogue";
import { notEmpty } from "../../../utils/functional";
import { ZERO } from "../../../utils/money";
import { urls } from "../../../utils/urls";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { getSelectedGift } from "../../selectors";
import { Actions } from "../actions";
import { ConfigureGiftsWithPurchase as Component } from "../components/ConfigureGiftsWithPurchase";
import { ConfigureGiftStatus } from "../constants";
import { defaults } from "../defaults";
import { Dispatchers } from "../dispatchers";
import { Loaders } from "../loaders";
import { IAddToBasketError, IGiftSelection } from "../reducers.interfaces";

interface IOwnProps {}

interface IReduxProps {
    basket: IBasket | null;
    status: ConfigureGiftStatus;
    bundles: IUserConfigurableBundle[];
    addToBasketError: IAddToBasketError;
    gifts: {
        [giftID: string]: IGiftSelection;
    };
}

interface IDispatchProps {
    dispatchers: Dispatchers;
    loaders: Loaders;
    actions: Actions;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class ConfigureGiftsWithPurchaseContainer extends React.Component<
    IProps,
    IState
> {
    private readonly onCloseErrorModal = () => {
        this.props.dispatchers.setAddToBasketErrorModalState(false, "");
    };

    private readonly onAddToBasket = async (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        event.preventDefault();
        // Set new status in Redux
        this.props.dispatchers.setConfigureGiftStatus(
            ConfigureGiftStatus.ADDING_TO_BASKET,
        );

        // Figure out all the product variants we need to add to the basket
        let variants: IProduct[] = [];
        try {
            variants = this.getSelectedProductVariants();
        } catch (e) {
            // Message to the user that they have a configuration error
            this.props.dispatchers.setConfigureGiftStatus(
                ConfigureGiftStatus.SELECTION_ERROR,
            );
            return;
        }

        // Actually add everything to the basket
        const lines = variants.map((variant) => {
            return {
                productURL: variant.url,
                qty: 1,
            };
        });

        try {
            await this.props.actions.addBasketLines(lines);

            // Update redux to show that the basket mutation is complete
            this.props.dispatchers.setConfigureGiftStatus(
                ConfigureGiftStatus.COMPLETE,
            );

            // Forward the user to their basket
            urls.navigateTo("basket-summary");
        } catch (e) {
            // Display the error to the user
            let reason = t`An unexpected error occurred. Please try again.`;
            try {
                reason = e.response.body.reason;
            } catch (e2) {
                console.error(e2);
            }
            this.props.dispatchers.setAddToBasketErrorModalState(true, reason);
        }
    };

    componentDidMount() {
        this.loadBundleData();
    }

    componentDidUpdate(prevProps: IProps) {
        // Check Availability and update ConfigureGiftStatus to remove the configuration error message when a user
        // changes their selection to a product that is available.
        const giftsChanged = this.props.gifts !== prevProps.gifts;
        const bundlesChanged = this.props.bundles !== prevProps.bundles;
        const currentlyHasSelectionErr =
            this.props.status === ConfigureGiftStatus.SELECTION_ERROR;
        if (currentlyHasSelectionErr && (giftsChanged || bundlesChanged)) {
            const selectedVariants = this.getSelectedProductVariants(true);
            const allVariantsAvailable = selectedVariants.reduce<boolean>(
                (memo, p) => {
                    return memo && p.availability.is_available_to_buy;
                },
                true,
            );
            if (allVariantsAvailable) {
                this.props.dispatchers.setConfigureGiftStatus(
                    ConfigureGiftStatus.LOADED,
                );
            }
        }
    }

    private async loadBundleData() {
        // Mark that loading has started
        this.props.dispatchers.setConfigureGiftStatus(
            ConfigureGiftStatus.LOADING,
        );
        // Load the basket
        const { basket } = await this.props.loaders.loadBasketIfOutdated(
            this.props.basket,
        );
        // Load user configurable bundles for each line in the basket
        await this.props.loaders.loadUserConfigurableBundles(basket);
    }

    private getSelectedProductVariants(ignoreUnavailable = false): IProduct[] {
        // Figure out all the product variants we need to add to the basket
        return Object.values(this.props.gifts)
            .map((giftConfig) => {
                try {
                    return getSelectedGift(this.props.bundles, giftConfig);
                } catch (e) {
                    if (ignoreUnavailable) {
                        console.error(e);
                        return null;
                    }
                    throw e;
                }
            })
            .filter(notEmpty);
    }

    private getTotalValue() {
        const selectedVariants = this.getSelectedProductVariants(true);
        const totalValue = selectedVariants
            .map((p) => {
                return p
                    ? getProductPrice(p.price, {
                          priceType: PriceType.RETAIL,
                          includePostDiscountAddons: true,
                          quantity: 1,
                      })
                    : ZERO;
            })
            .reduce((memo, price) => {
                return memo.add(price);
            }, ZERO);
        return totalValue;
    }

    private renderLoadingSpinner() {
        return <LoadingSpinner />;
    }

    private renderContent() {
        return (
            <Component
                status={this.props.status}
                bundles={this.props.bundles}
                onAddToBasket={this.onAddToBasket}
                addToBasketErrorOpen={
                    this.props.addToBasketError.addToBasketErrorOpen
                }
                addToBasketErrorReason={
                    this.props.addToBasketError.addToBasketErrorReason
                }
                onCloseErrorModal={this.onCloseErrorModal}
                totalValue={this.getTotalValue()}
            />
        );
    }

    render() {
        if (this.props.status === ConfigureGiftStatus.LOADING) {
            return this.renderLoadingSpinner();
        }
        return this.renderContent();
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.checkout || defaults;
    return {
        basket: state.data.basket,
        status: state.gifts.status,
        bundles: state.gifts.userConfigurableBundles,
        addToBasketError: state.gifts.addToBasketError,
        gifts: state.gifts.gifts,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    const loaders = new Loaders(dispatchers);
    const actions = new Actions(loaders, dispatchers);
    return {
        dispatchers: dispatchers,
        loaders: loaders,
        actions: actions,
    };
};

export const ConfigureGiftsWithPurchase = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConfigureGiftsWithPurchaseContainer);
