import type { AdaEmbedAPI, StartOptions } from "@ada-support/embed-types";
import localForage from "localforage";

import { IUser } from "../../models/user.interfaces";
import { strToBool } from "../../utils/format";
import { getPageSetting, isPageInsideIFrame } from "../../utils/settings";
import {
    ChatConnector,
    IChatCampaignContext,
    IChatCampaignData,
    IChatInitReturn,
} from "./connector-base";

const CHAT_IS_OPEN_KEY = "adachat-chat-is-open";

type IScriptLoadData = IChatInitReturn & {
    adaEmbed: AdaEmbedAPI;
};

/**
 * Module-level variable used as a mutex to prevent loading chat script multiple
 * times on a single page.
 */
let _loading: Promise<IScriptLoadData> | undefined;

/**
 * Settings accepted by Ada at start time.
 * See docs: https://adasupport.github.io/documentation/#settings
 */
export type AdaSettings = StartOptions;

/**
 * Ada Chat Connector Implementation
 */
export class AdaChatConnector extends ChatConnector {
    public readonly settings: AdaSettings;

    constructor({ settings }: { settings: AdaSettings }) {
        super();
        this.settings = settings;
    }

    /**
     * Ensure that the chat connector code is loaded on the page. This is a
     * no-op if it was already loaded / loading. Returns a boolean with the
     * agent-online status.
     */

    public hide() {
        const chatFrame = document.getElementById("ada-button-frame");
        if (chatFrame) {
            chatFrame.classList.add("ada-button-frame--hidden");
        }
    }

    public show() {
        const chatFrame = document.getElementById("ada-button-frame");
        if (chatFrame) {
            chatFrame.classList.remove("hidden");
        }
    }

    public async init(
        { hideChat } = { hideChat: false },
    ): Promise<IChatInitReturn> {
        // If we're in the nav / footer iframe, never load chat.
        if (isPageInsideIFrame()) {
            return {
                isOnline: false,
            };
        }
        // hide the ADA-chat on the prosmart-guide path (ticket #25111)
        const prosmartGuidePath = "/prosmart-guide/";
        const thisPagePath = window.location.pathname;

        if (thisPagePath === prosmartGuidePath) {
            return {
                isOnline: false,
            };
        }
        // If chat isn't already loading, start loading it now.
        if (!_loading) {
            _loading = this.startAda();
        }
        // Wait for script to load
        try {
            const status = await _loading;
            const chatWasOpen = await localForage.getItem(CHAT_IS_OPEN_KEY);
            if (hideChat && !chatWasOpen) {
                this.hide();
            }
            status.adaEmbed.subscribeEvent(
                "ada:campaigns",
                (data: IChatCampaignData, context: IChatCampaignContext) => {
                    const { campaignKey } = data;
                    const { eventKey } = context;
                    console.log(
                        `Message with campaign ${campaignKey} received`,
                    );
                    if (hideChat && eventKey === "ada:campaigns:opened") {
                        this.show();
                        status.adaEmbed.subscribeEvent(
                            "ada:end_conversation",
                            () => {
                                this.hide();
                            },
                        );
                    }
                },
            );
            return status;
        } catch (err) {
            return {
                isOnline: false,
            };
        }
    }

    /**
     * Make the user's email and name available to the chat agent.
     */
    public async setUserData(user: IUser): Promise<void> {
        // Only identify user if we have info
        if (!user.email || typeof window === "undefined" || !window.adaEmbed) {
            return;
        }
        window.adaEmbed.setMetaFields({
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
        });
    }

    /**
     * Open a chat window
     */
    public async openChat(): Promise<void> {
        if (typeof window === "undefined" || !window.adaEmbed) {
            return;
        }
        const adaInfo = await window.adaEmbed.getInfo();
        localForage.setItem(CHAT_IS_OPEN_KEY, true);
        this.show();
        if (!adaInfo.isChatOpen) {
            window.adaEmbed.toggle();
        }
    }

    /**
     * Open a proactive chat window
     */
    public async openProactiveChat(message: string): Promise<void> {
        if (typeof window === "undefined" || !window.adaEmbed) {
            return;
        }
        await window.adaEmbed.createProactive(message);
        const hideChat = strToBool(getPageSetting("chat-hide-widget", "false"));
        const adaInfo = await window.adaEmbed.getInfo();
        if (hideChat && adaInfo.isChatOpen) {
            this.show();
            window.adaEmbed.subscribeEvent("ada:end_conversation", () => {
                this.hide();
            });
        }
    }

    /**
     * Trigger an Ada campaign
     */
    public async triggerCampaign(campaignKey: string): Promise<void> {
        if (typeof window === "undefined" || !window.adaEmbed) {
            return;
        }
        await window.adaEmbed.triggerCampaign(campaignKey, {
            ignoreStatus: false,
            ignoreFrequency: false,
        });
        const hideChat = strToBool(getPageSetting("chat-hide-widget", "false"));
        const adaInfo = await window.adaEmbed.getInfo();
        if (hideChat && adaInfo.isChatOpen) {
            this.show();
            window.adaEmbed.subscribeEvent("ada:end_conversation", () => {
                this.hide();
            });
        }
    }

    /**
     * Tell Ada to load and start
     */
    private startAda(): Promise<IScriptLoadData> {
        return new Promise<IScriptLoadData>((resolve, reject) => {
            if (typeof window === "undefined") {
                reject(new Error("Script failed to load."));
                return;
            }

            if (window.adaEmbed) {
                resolve({
                    isOnline: true,
                    adaEmbed: window.adaEmbed,
                });
                return;
            }

            const opts = this.getStartOptions(resolve);
            const script = document.createElement("script");
            script.id = "__ada";
            script.type = "text/javascript";
            script.async = true;
            script.dataset.handle = opts.handle;
            script.dataset.lazy = "true";
            script.src = `https://static.ada.support/embed2.js`;

            // Setup script load error handler
            script.onerror = (err) => {
                reject(err);
            };

            // Setup script load handler
            script.onload = () => {
                const adaEmbed = window.adaEmbed!;
                adaEmbed.start(opts);
            };

            // Inject script into DOM
            const scriptSibling = document.getElementsByTagName("script")[0];
            scriptSibling.parentNode?.insertBefore(script, scriptSibling);
        });
    }

    /**
     * Get the settings required to start Ada
     */
    private getStartOptions(
        resolve: (obj: IScriptLoadData) => void,
    ): AdaSettings {
        return {
            lazy: true,
            crossWindowPersistence: true,
            ...this.settings,
            adaReadyCallback: ({ isRolledOut }) => {
                resolve({
                    isOnline: isRolledOut,
                    adaEmbed: window.adaEmbed!,
                });
            },
        };
    }
}
