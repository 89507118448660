import memoize from "memoize-one";
import React from "react";
import { connect } from "react-redux";
import { t } from "ttag";

import {
    IConcreteBundle,
    IOptionValues,
    IProduct,
    IProductCategory,
} from "../../../models/catalogue.interfaces";
import { IWebPageURL } from "../../../models/nominals";
import { IAPIPrice } from "../../../models/prices.interfaces";
import { BreakPoint } from "../../../models/screen.interfaces";
import { check } from "../../../models/utils";
import { trackAddToBasketEvent } from "../../../utils/analytics";
import { getViewportBreakpoint } from "../../../utils/detectMobile";
import { urls } from "../../../utils/urls";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { addProductToBasket } from "../actions";
import { StickyConfigurator as Component } from "../components/StickyConfigurator";
import { defaults } from "../defaults";
import { Dispatchers } from "../dispatchers";
import { getSelectedUpgradeIDFromURL } from "../history";
import { Loaders } from "../loaders";
import { PLCProductCategorySelectors } from "../models";
import {
    IPLCProductCategorySelector,
    IPLCProductCategorySelectorOption,
    IUpsellModalComponent,
    IUpsellModalComponentClass,
} from "../models.interfaces";
import { ISelectedCategories } from "../reducers.interfaces";
import {
    baseVariantSelector,
    rootProductSelector,
    selectedCategoriesSelector,
    upgradedVariantPriceSelector,
    upgradedVariantSelector,
} from "../selectors";
import { isPreorderProduct } from "../utils";

interface IOwnProps {
    // Site Page URLs for links and redirects
    financingLink?: IWebPageURL;
    basketLink: IWebPageURL;
    configureGiftsLink: IWebPageURL;

    // Optional: Up Sell Modal functionality
    showUpsellModal?: (product: IProduct | null) => boolean;
    getUpsellModalComponentClass?: (
        product: IProduct,
    ) => IUpsellModalComponentClass | null;
    upsellModal?: IUpsellModalComponentClass;

    // Callback Hooks
    onBaseVariantChanged?: (baseVariant: IProduct | null) => void;
    onEditClick?: () => void;

    // Pricing UI options
    strikeThroughMSRP?: boolean;
    actualPriceStyle?: string;
    overrideFinancingCopy?: string;

    // Threshold for sticky appearance
    stickyTrigger?: HTMLElement | null;
    isPLCVersion: boolean;

    categorySelectors?: string;
}

interface IReduxProps {
    // Basic Data
    rootProduct: IProduct | null;
    concreteBundles: IConcreteBundle[];

    // Derived data
    baseVariant: IProduct | null;
    upgradedVariant: IProduct | null;
    price: IAPIPrice | null;

    // Category selector state
    selectedCategories: ISelectedCategories;

    // Option selector state
    optionValues: IOptionValues;
    quantity: number;

    // Add to basket button
    addToBasketCooldownActive: boolean;
    addToBasketButtonText: string;

    // Add to basket error modal
    addToBasketErrorOpen: boolean;
    addToBasketErrorReason: string;
}

interface IDispatchProps {
    loaders: Loaders;
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    isVisible: boolean;
    stickyElemHeight: number;
}

class StickyConfiguratorContainer extends React.Component<IProps, IState> {
    private upsellModal: IUpsellModalComponent | undefined;

    public state: IState = {
        isVisible: false,
        stickyElemHeight: document.documentElement.offsetHeight,
    };

    private readonly onScroll = () => {
        if (!this.props.baseVariant) {
            this.setIsVisible(false);
        }

        const top =
            window.scrollY ||
            window.pageYOffset ||
            (document.documentElement
                ? document.documentElement.scrollTop
                : null) ||
            document.body.scrollTop;
        const stickyExit =
            (document.documentElement
                ? document.documentElement.scrollHeight
                : null) || document.body.scrollHeight;
        const stickyEnter = this.stickyThreshold();
        if (top >= stickyEnter && top <= stickyExit) {
            this.setIsVisible(true);
        } else {
            this.setIsVisible(false);
        }
    };

    private readonly onEditClick = () => {
        if (this.props.onEditClick) {
            this.props.onEditClick();
        }
    };

    private readonly onBeforeAddToBasket = () => {
        // Disable buttons
        this.props.dispatchers.setAddToBasketBtnState(true, t`Adding`);
    };

    private readonly onAfterAddToBasket = () => {
        // Re-enable buttons
        this.props.dispatchers.setAddToBasketBtnState(false, t`Added`);

        // Send an `add_to_cart` event to the data layer
        trackAddToBasketEvent(this.props.upgradedVariant, this.props.quantity);

        // Either show the upsell modal or redirect customer to the basket page
        if (
            this.upsellModal &&
            this.props.concreteBundles.length > 0 &&
            this.props.showUpsellModal &&
            this.props.showUpsellModal(this.props.upgradedVariant)
        ) {
            this.upsellModal.openModal();
        } else {
            this.redirectToNextPage();
        }
    };

    private readonly onAddToBasketError = (reason: string) => {
        // Re-enable buttons
        const isPreorder =
            this.props.upgradedVariant &&
            isPreorderProduct(this.props.upgradedVariant);
        const addToBasketButtonText = isPreorder
            ? t`Pre-Order`
            : t`Add to Cart`;
        this.props.dispatchers.setAddToBasketBtnState(
            false,
            addToBasketButtonText,
        );
        this.props.dispatchers.setAddToBasketErrorModalState(true, reason);
    };

    private readonly onAddToBasket = async () => {
        if (!this.props.upgradedVariant) {
            return;
        }
        this.onBeforeAddToBasket();
        try {
            await addProductToBasket(
                this.props.upgradedVariant.url,
                this.props.quantity,
            );
            this.onAfterAddToBasket();
        } catch (e) {
            // Display the error to the user
            let reason = t`An unexpected error occurred. Please try again.`;
            try {
                reason = e.response.body.reason;
            } catch (e2) {
                console.error(e2);
            }
            this.onAddToBasketError(reason);
        }
    };

    private readonly onCloseErrorModal = () => {
        this.props.dispatchers.setAddToBasketErrorModalState(false, "");
    };

    private readonly setStickyConfiguratorHeight = (height: number) => {
        this.setState({
            stickyElemHeight: height,
        });
    };

    private readonly onUpsellProceed = () => {
        this.redirectToNextPage();
    };

    private readonly updateAddToBasketText = () => {
        // Change add to cart label to preorder where necessary
        const isPreorder =
            this.props.upgradedVariant &&
            isPreorderProduct(this.props.upgradedVariant);
        const addToBasketButtonText = isPreorder
            ? t`Pre-Order`
            : t`Add to Cart`;
        if (this.props.addToBasketButtonText !== addToBasketButtonText) {
            this.props.dispatchers.setAddToBasketBtnState(
                false,
                addToBasketButtonText,
            );
        }
    };

    private readonly parseCategorySelectors = memoize(
        (categorySelectorsJSON: string): IPLCProductCategorySelector[] => {
            const selectors: IPLCProductCategorySelector[] = check(
                PLCProductCategorySelectors.decode(
                    JSON.parse(categorySelectorsJSON),
                ),
            );
            return selectors;
        },
    );

    private get selectedCategories() {
        // Load selected categories (if they exist)
        if (Object.keys(this.props.selectedCategories).length === 0) {
            return [];
        }

        const selectedCategoryObject: IProductCategory[] = [];
        const categorySelectors = this.loadCategorySelectors();

        // Match the currently selected categories from the configurator to their values
        categorySelectors.forEach((obj: IPLCProductCategorySelector) => {
            const selectedCategoryOption:
                | IPLCProductCategorySelectorOption
                | undefined = obj.value.options.find(
                (elem: IPLCProductCategorySelectorOption) => {
                    return (
                        elem.category.id ===
                        this.props.selectedCategories[obj.id]
                    );
                },
            );

            if (selectedCategoryOption && selectedCategoryOption.category) {
                selectedCategoryObject.push({
                    name: obj.value.name,
                    value: selectedCategoryOption.category.name,
                });
            }
        });

        return selectedCategoryObject;
    }

    componentDidMount() {
        // Add scroll listener to append sticky class
        document.addEventListener("scroll", this.onScroll);
    }

    componentDidUpdate(prevProps: IProps) {
        const prevBaseVariantID =
            prevProps.baseVariant && prevProps.baseVariant.id
                ? prevProps.baseVariant.id
                : null;
        const nextBaseVariantID =
            this.props.baseVariant && this.props.baseVariant.id
                ? this.props.baseVariant.id
                : null;
        if (prevBaseVariantID !== nextBaseVariantID) {
            // Load bundle data for the new variant
            if (nextBaseVariantID) {
                const selectedUpgradeID = getSelectedUpgradeIDFromURL();
                this.props.loaders.loadConcreteBundles(
                    nextBaseVariantID,
                    selectedUpgradeID,
                );
            }

            // Trigger the onBaseVariantChanged callback
            if (this.props.onBaseVariantChanged) {
                this.props.onBaseVariantChanged(this.props.baseVariant);
            }
        }

        const prevBaseUpgradedID =
            prevProps.upgradedVariant && prevProps.upgradedVariant.id
                ? prevProps.upgradedVariant.id
                : null;
        const nextBaseUpgradedID =
            this.props.upgradedVariant && this.props.upgradedVariant.id
                ? this.props.upgradedVariant.id
                : null;
        const upgradedVariantChanged =
            prevBaseUpgradedID !== nextBaseUpgradedID;
        const prevQty = prevProps.quantity;
        const nextQty = this.props.quantity;
        const qtyChanged = prevQty !== nextQty;
        if (
            nextBaseUpgradedID &&
            (upgradedVariantChanged || qtyChanged) &&
            this.props.quantity !== 1
        ) {
            // Load price data for the new variant
            this.props.loaders.loadPrice(
                nextBaseUpgradedID,
                this.props.quantity,
            );
        }

        if (upgradedVariantChanged && this.props.upgradedVariant) {
            this.updateAddToBasketText();
        }
    }

    private loadCategorySelectors() {
        if (!this.props.categorySelectors) {
            return [];
        }

        return this.parseCategorySelectors(this.props.categorySelectors);
    }

    private setIsVisible(isVisible: boolean) {
        this.setState(() => {
            return {
                isVisible: isVisible,
            };
        });
    }

    private stickyThreshold() {
        if (!this.props.stickyTrigger) {
            return 0;
        }
        // Use "pdp-hero__content" for stickyTrigger
        // That would be always present in the DOM
        // Take its bottom for triggering visibility
        return (
            this.props.stickyTrigger.getBoundingClientRect().bottom -
            document.body.getBoundingClientRect().top +
            this.props.stickyTrigger.offsetHeight
        );
    }

    private isMobileWidth() {
        return getViewportBreakpoint() <= BreakPoint.MEDIUM;
    }

    private async redirectToNextPage() {
        // Check if the user has any gifts to configure
        const bundles = await this.props.loaders.loadUserConfigurableBundles();
        const nextURL =
            bundles.length <= 0
                ? this.props.basketLink
                : this.props.configureGiftsLink;
        setTimeout(() => {
            urls.navigateToURL(nextURL);
        }, 0);
    }

    private buildUpsellModal() {
        if (
            !this.props.getUpsellModalComponentClass ||
            !this.props.upgradedVariant
        ) {
            return null;
        }
        const UpsellModal = this.props.getUpsellModalComponentClass(
            this.props.upgradedVariant,
        );
        if (!UpsellModal) {
            return null;
        }
        return (
            <UpsellModal
                ref={(ref: IUpsellModalComponent) => {
                    this.upsellModal = ref;
                }}
                bundles={this.props.concreteBundles}
                onProceed={this.onUpsellProceed}
            />
        );
    }

    render() {
        if (!this.props.rootProduct) {
            return null;
        }

        if (!this.props.isPLCVersion) {
            if (!this.isMobileWidth()) {
                return null;
            }
        }

        return (
            <Component
                isPLCVersion={this.props.isPLCVersion}
                financingLink={this.props.financingLink || null}
                rootProduct={this.props.rootProduct}
                baseVariant={this.props.baseVariant}
                upgradedVariant={this.props.upgradedVariant}
                price={this.props.price}
                concreteBundles={this.props.concreteBundles}
                optionValues={this.props.optionValues}
                quantity={this.props.quantity}
                addToBasketCooldownActive={this.props.addToBasketCooldownActive}
                addToBasketButtonText={this.props.addToBasketButtonText}
                onAddToBasket={this.onAddToBasket}
                addToBasketErrorOpen={this.props.addToBasketErrorOpen}
                addToBasketErrorReason={this.props.addToBasketErrorReason}
                onCloseErrorModal={this.onCloseErrorModal}
                setStickyConfiguratorHeight={this.setStickyConfiguratorHeight}
                stickyElemHeight={this.state.stickyElemHeight}
                isVisible={this.state.isVisible}
                onEditClick={this.onEditClick}
                strikeThroughMSRP={this.props.strikeThroughMSRP || false}
                actualPriceStyle={this.props.actualPriceStyle || ""}
                overrideFinancingCopy={this.props.overrideFinancingCopy || ""}
                selectedCategories={this.selectedCategories}
            >
                {this.buildUpsellModal()}
            </Component>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    const selectedCategories = selectedCategoriesSelector(state);
    return {
        // Basic Data
        rootProduct: rootProductSelector(state),
        concreteBundles: state.entities.concreteBundles,

        // Data derived from API data combined with user selected options
        baseVariant: baseVariantSelector(state),
        upgradedVariant: upgradedVariantSelector(state),
        price: upgradedVariantPriceSelector(state),

        // Category selector state
        selectedCategories: selectedCategories,

        // Option selector state
        optionValues: state.ui.optionValues,
        quantity: state.ui.quantity,

        // Add to basket button
        addToBasketCooldownActive: state.ui.addToBasketCooldownActive,
        addToBasketButtonText: state.ui.addToBasketButtonText,

        // Add to basket error modal
        addToBasketErrorOpen: state.ui.addToBasketErrorOpen,
        addToBasketErrorReason: state.ui.addToBasketErrorReason,

        // Direct Props
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    const loaders = new Loaders(dispatchers);
    return {
        dispatchers: dispatchers,
        loaders: loaders,
    };
};

export const StickyConfigurator = connect(
    mapStateToProps,
    mapDispatchToProps,
)(StickyConfiguratorContainer);
