import React from "react";

import { useAppSelector } from "../../../apps/reducers";
import { MattressSelectorBodyType } from "../components/MattressBodyType";
import { MattressSelectorSize } from "../components/MattressSize";
import { MattressOption } from "../components/Option";
import { MattressMatchResults } from "../components/Results";
import { Step, stepOrder } from "../constants";
import { NextButton } from "../elements/NextButton";
import { OptionStepsType } from "../models.interfaces";
import { MattressMatchNav } from "./MattressMatchNav";

import styles from "./MattressMatch.module.scss";

export const MattressMatchContainer = () => {
    const mattressMatchUiState = useAppSelector(
        (state) => state.mattressmatch.ui,
    );
    const { currentScreen } = mattressMatchUiState;

    function buildScreen(step: Step) {
        switch (step) {
            case Step.SIZE:
                return <MattressSelectorSize />;
            case Step.FEEL:
            case Step.COOLING:
            case Step.BUDGET:
                return (
                    <MattressOption
                        step={step as keyof OptionStepsType}
                        key={step}
                    />
                );
            case Step.BODY_TYPE:
                return <MattressSelectorBodyType />;
            case Step.RESULTS:
                return <MattressMatchResults />;
        }
        return;
    }

    return (
        <>
            <div className={styles.container} data-screen={currentScreen}>
                {buildScreen(stepOrder[currentScreen])}
                {stepOrder[currentScreen] !== Step.RESULTS && <NextButton />}
            </div>
            {currentScreen > stepOrder.indexOf(Step.SIZE) && (
                <MattressMatchNav />
            )}
        </>
    );
};
