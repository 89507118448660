import { connect } from "react-redux";
import {
    baseVariantSelector,
    rootProductSelector,
} from "tsi-common-react/src/apps/configurator/selectors";

import { IReduxState } from "../../reducers.interfaces";
import {
    IProps,
    OutOfContextBundleCollection as OutOfContextBundleCollectionComponent,
} from "../components/OutOfContextBundleCollection";

const mapStateToProps = (state: IReduxState): IProps => {
    return {
        rootProduct: rootProductSelector(state.configurator),
        baseVariant: baseVariantSelector(state.configurator),
        bundles: state.configurator.entities.concreteBundles,
    };
};

export const OutOfContextBundleCollection = connect(mapStateToProps)(
    OutOfContextBundleCollectionComponent,
);
