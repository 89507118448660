import React from "react";
import { connect } from "react-redux";

import { IOptionCode, IProduct } from "../../../models/catalogue.interfaces";
import { IProductID } from "../../../models/nominals";
import { intersection } from "../../../utils/sets";
import { TStateMapper } from "../../reducers.interfaces";
import { tabFeatureAttributeBlock } from "../elementIDs";
import { ITabFeatureAttributeBlock_AttributeTab } from "../models.interfaces";
import { rootProductsSelector } from "../selectors";
import { TabFeatureAttributeBlockModelTabButton } from "./TabFeatureAttributeBlockModelTabButton";
import { TabFeatureAttributeBlockModelTabContent } from "./TabFeatureAttributeBlockModelTabContent";
import { TabFeatureAttributeBlockModelTabImage } from "./TabFeatureAttributeBlockModelTabImage";

import styles from "./TabFeatureAttributeBlockAttributeTabContent.module.scss";

interface IOwnProps {
    attribute: IOptionCode;
    idx: number;
    tab: ITabFeatureAttributeBlock_AttributeTab;
    isSelected: boolean;
}

interface IReduxProps {
    rootProducts: IProduct[];
}

type IProps = IOwnProps & IReduxProps;

interface IState {
    selectedModel: IProductID | null;
}

class TabFeatureAttributeBlockAttributeTabContentComponent extends React.Component<
    IProps,
    IState
> {
    state: IState = {
        selectedModel: null,
    };

    private selectModelTab(selectedModel: IProductID) {
        this.setState({
            selectedModel: selectedModel,
        });
    }

    private get selectedModel() {
        if (this.props.tab.tabs.length <= 0) {
            return null;
        }
        return this.state.selectedModel || this.props.tab.tabs[0].model;
    }

    componentDidMount() {
        const selectedReduxValue = this.getSelectedRootProductFromRedux(
            this.props.rootProducts,
        );
        this.setState({
            selectedModel: selectedReduxValue || null,
        });
    }

    componentDidUpdate(prevProps: IProps) {
        // We track the selected tab in local component state so that the user can
        // change the tab WITHOUT affecting the configurator immediately. However,
        // when the user updates the selected root product in the configurator, we
        // update the current tab to match.
        const valueNew = this.getSelectedRootProductFromRedux(
            this.props.rootProducts,
        );
        const valueOld = this.getSelectedRootProductFromRedux(
            prevProps.rootProducts,
        );
        if (valueNew && valueOld !== valueNew) {
            this.setState({
                selectedModel: valueNew,
            });
        }
    }

    private getSelectedRootProductFromRedux(rootProducts: IProduct[]) {
        if (rootProducts.length <= 0) {
            return null;
        }
        const tabPIDs = new Set(this.props.tab.tabs.map((tab) => tab.model));
        const selectedPIDs = new Set(rootProducts.map((p) => p.id));
        const selectedPIDsWithTabs = intersection(tabPIDs, selectedPIDs);
        if (selectedPIDsWithTabs.size <= 0) {
            return null;
        }
        return [...selectedPIDsWithTabs][0];
    }

    render() {
        return (
            <article
                className={styles.root}
                id={tabFeatureAttributeBlock.attributeTabContent(
                    this.props.tab,
                    this.props.idx,
                )}
                role="tabpanel"
                tabIndex={0}
                aria-labelledby={tabFeatureAttributeBlock.attributeTabButton(
                    this.props.tab,
                    this.props.idx,
                )}
                hidden={!this.props.isSelected}
            >
                <div className={styles.modelGraphic}>
                    {this.props.tab.tabs.map((modelTab, i) => (
                        <TabFeatureAttributeBlockModelTabImage
                            key={`${modelTab.model}`}
                            attrIdx={this.props.idx}
                            attrTab={this.props.tab}
                            modelIdx={i}
                            modelTab={modelTab}
                            isSelected={modelTab.model === this.selectedModel}
                        />
                    ))}
                </div>
                <div className={styles.modelButtonsAndContent}>
                    <div className={styles.modelButtons}>
                        {this.props.tab.tabs.map((modelTab, i) => (
                            <TabFeatureAttributeBlockModelTabButton
                                key={`${modelTab.model}`}
                                attrIdx={this.props.idx}
                                attrTab={this.props.tab}
                                modelIdx={i}
                                modelTab={modelTab}
                                onClick={() => {
                                    this.selectModelTab(modelTab.model);
                                }}
                                isSelected={
                                    modelTab.model === this.selectedModel
                                }
                            />
                        ))}
                    </div>
                    <div className={styles.modelContent}>
                        {this.props.tab.tabs.map((modelTab, i) => (
                            <TabFeatureAttributeBlockModelTabContent
                                key={`${modelTab.model}`}
                                attribute={this.props.attribute}
                                attrIdx={this.props.idx}
                                attrTab={this.props.tab}
                                modelIdx={i}
                                modelTab={modelTab}
                                isSelected={
                                    modelTab.model === this.selectedModel
                                }
                            />
                        ))}
                    </div>
                </div>
            </article>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        rootProducts: rootProductsSelector(rootState.configurator),
        ...ownProps,
    };
};

export const TabFeatureAttributeBlockAttributeTabContent = connect(
    mapStateToProps,
)(TabFeatureAttributeBlockAttributeTabContentComponent);
