import * as t from "io-ts";

import { ImageURL } from "./nominals";
import { SnippetChooserBlock, StructBlock } from "./streamfield-blocks";
import { nullable } from "./utils";

export const RecommendedProductData = t.interface({
    title: t.string,
    url: t.string,
    image_url: ImageURL,
    rating: nullable(t.number),
    num_reviews: t.number,
});
export type RecommendedProductData = t.TypeOf<typeof RecommendedProductData>;

export const RecommendedProducts = t.array(RecommendedProductData);

/**
 * See Snippet block UGCPost
 */
export const UGCPost = t.intersection([
    t.interface({
        title: t.string,
        post_type: t.string,
        pull_quote: t.string,
        copy: t.string,
        social_media: t.string,
        username: t.string,
    }),
    t.partial({
        avatar: ImageURL,
        avatar_title: t.string,
        photo: ImageURL,
        photo_title: t.string,
        social_link: t.string,
        video_link: t.string,
        view_post: t.boolean,
        recommendations: t.array(RecommendedProductData),
    }),
]);
export type UGCPost = t.TypeOf<typeof UGCPost>;

/**
 * See streamfield block: tsi-tempurpedic/cms.blocks.ugc_grid.UGCListSubBlock
 */
export const UGCListSubBlock = StructBlock(
    {
        post: SnippetChooserBlock(UGCPost),
    },
    {},
);
export type UGCListSubBlock = t.TypeOf<typeof UGCListSubBlock>;
