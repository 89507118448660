import React from "react";

import { CSRF_HEADER, ajax, getCSRFToken } from "../../../../utils/ajax";
import { Actions } from "../../actions";
import { BooleanInput } from "../blocks/BooleanInput";
import { SelectInput } from "../blocks/SelectInput";
import { TextInput } from "../blocks/TextInput";

type IValue = string | boolean | undefined;

type IValues<IFieldName extends string> = {
    [K in IFieldName]?: IValue;
};

type IErrors<IFieldName extends string> = {
    [K in IFieldName]?: string[];
};

export interface IField<IFieldName extends string> {
    name: IFieldName;
    type: "hidden" | "boolean" | "select" | "phone" | "text" | "email";
    label?: string;
    placeholder?: string;
    options?: [string, string][];
    isRequired?: boolean;
}

interface IProps<IFieldName extends string, U> {
    actions: Actions;
    fields: IField<IFieldName>[];
    initialValues: IValues<IFieldName>;
    url?: string;
    baseURL?: string;
    className?: string;
    submitLabel?: string;

    onSubmit?: (
        event: React.FormEvent<HTMLFormElement>,
        data: IValues<IFieldName>,
    ) => Promise<void>;
    onPostSave?: (saveResp: U) => Promise<void>;
}

interface IState<IFieldName extends string> {
    data: IValues<IFieldName>;
    errors: IErrors<IFieldName> & {
        non_field_errors?: string[];
    };
}

export class FormView<T extends string, U> extends React.Component<
    IProps<T, U>,
    IState<T>
> {
    state: IState<T> = {
        data: {},
        errors: {},
    };

    protected inputRefs: {
        [P in T]?: HTMLInputElement | HTMLSelectElement | null;
    } = {};

    componentDidMount() {
        // Set initial state
        this.setState(this.getDefaultState());
        // Auto focus on the first field
        const field = this.props.fields.find((f) => f.type !== "hidden");
        if (field) {
            const node = this.inputRefs[field.name];
            if (node) {
                node.focus();
            }
        }
    }

    protected getDefaultState(): IState<T> {
        const state: IState<T> = {
            data: {},
            errors: {},
        };
        this.props.fields.forEach((field) => {
            let value: IValue = "";
            if (field.type === "select") {
                value = (field.options || [])[0][0];
            }
            if (field.type === "boolean") {
                value = false;
            }
            const val = this.props.initialValues[field.name];
            if (val !== undefined) {
                value = val;
            }
            state.data[field.name] = value;
            // state.errors[field.name] = ([] as string[]);
        });
        return state;
    }

    private readonly onFieldChange = (name: T, value: IValues<T>[T]) => {
        this.setState((s) => {
            return {
                ...s,
                data: {
                    ...s.data,
                    [name]: value,
                },
            };
        });
    };

    private readonly onSubmit = async (
        event: React.FormEvent<HTMLFormElement>,
    ) => {
        try {
            if (this.props.onSubmit) {
                return this.props.onSubmit(event, {
                    ...this.state.data,
                });
            }
            event.preventDefault();
            let loading;
            if (this.props.url) {
                loading = ajax.put(this.props.url);
            } else if (this.props.baseURL) {
                loading = ajax.post(this.props.baseURL);
            } else {
                throw new Error("Both url and baseURL props are missing");
            }
            const resp = await loading
                .set(CSRF_HEADER, await getCSRFToken())
                .send({
                    ...this.state.data,
                });
            await this.onPostSave(resp.body);
        } catch (err) {
            this.setState({
                errors: err.response.body,
            });
        }
    };

    private readonly onPostSave = async (saveResp: U) => {
        if (this.props.onPostSave) {
            return this.props.onPostSave(saveResp);
        }
        this.props.actions.popViewStack();
    };

    private buildFields() {
        return this.props.fields.map((field) => {
            if (field.type === "boolean") {
                return (
                    <BooleanInput
                        key={field.name}
                        forwardRef={(r) => {
                            this.inputRefs[field.name] = r;
                        }}
                        value={!!this.state.data[field.name]}
                        errors={this.state.errors[field.name]}
                        onChange={this.onFieldChange}
                        label={field.label}
                        name={field.name}
                        isRequired={field.isRequired}
                    />
                );
            }
            if (field.type === "select") {
                return (
                    <SelectInput
                        key={field.name}
                        forwardRef={(r) => {
                            this.inputRefs[field.name] = r;
                        }}
                        value={`${this.state.data[field.name]}`}
                        errors={this.state.errors[field.name]}
                        onChange={this.onFieldChange}
                        label={field.label}
                        name={field.name}
                        options={field.options || []}
                        isRequired={field.isRequired}
                    />
                );
            }
            return (
                <TextInput
                    key={field.name}
                    forwardRef={(r) => {
                        this.inputRefs[field.name] = r;
                    }}
                    value={`${this.state.data[field.name]}`}
                    errors={this.state.errors[field.name]}
                    onChange={this.onFieldChange}
                    type={field.type}
                    label={field.label}
                    name={field.name}
                    placeholder={field.placeholder}
                    isRequired={field.isRequired}
                />
            );
        });
    }

    render() {
        return (
            <div className={this.props.className || "csr-edit-form"}>
                <form onSubmit={this.onSubmit}>
                    <ul className="error-list">
                        {(this.state.errors.non_field_errors || []).map(
                            (err, i) => (
                                <li key={i}>{err}</li>
                            ),
                        )}
                    </ul>
                    {this.buildFields()}
                    <button type="submit">
                        {this.props.submitLabel || "Save"}
                    </button>
                </form>
            </div>
        );
    }
}
