import React from "react";
import { t } from "ttag";

import { strings } from "../../../localization";

import styles from "./index.module.scss";

export const WFDeniedMessage = () => (
    <>
        <p>
            {t`Our primary lender, Wells Fargo Bank, N.A., was unable to approve your application for credit. If you have any questions regarding your denial, you may contact Wells Fargo at 1-877-805-7744, Monday – Friday, 9am – 7pm Eastern Time.`}
        </p>
        <p>
            {t`We do not provide details of applications via email. You will receive written notification of this denial in the mail within 30 days.`}
        </p>
        <a
            className={styles.link}
            target="_blank"
            href={strings.get("FINANCING_TERMS_PDF") || ""}
            rel="noreferrer"
        >
            {t`Terms and Conditions [PDF]`}
        </a>
    </>
);
