import React from "react";
import { connect } from "react-redux";

import { IProduct } from "../../../models/catalogue.interfaces";
import { IProductID } from "../../../models/nominals";
import { IRootState as IReduxState } from "../../reducers.interfaces";
import { rootProductSelector } from "../selectors";

interface IOwnProps {
    blockProductID: IProductID;
    content: string;
}

interface IReduxProps {
    selectedRootProduct: IProduct | null;
}

type IProps = IOwnProps & IReduxProps;

interface IState {}

class PDPSelectedRootProductConditionalBlockComponent extends React.Component<
    IProps,
    IState
> {
    render() {
        // If the currently selected value of the configured attribute doesn't match the desired value, don't render anything
        const isSelected =
            this.props.blockProductID === this.props.selectedRootProduct?.id;
        // Build IDs for relationship to tab controls. These IDs must match the IDs used by PDPChangeRootProductBlock.
        const tabControlID = `change-root-product-block-${this.props.blockProductID}`;
        const tabPanelID = `root-product-conditional-block-${this.props.blockProductID}`;
        return (
            <div
                id={tabPanelID}
                role="tabpanel"
                aria-labelledby={tabControlID}
                aria-hidden={!isSelected}
                style={{ display: isSelected ? "block" : "none" }}
                dangerouslySetInnerHTML={{ __html: this.props.content }}
            ></div>
        );
    }
}

const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IProps => {
    return {
        selectedRootProduct: rootProductSelector(state.configurator),
        ...ownProps,
    };
};

export const PDPSelectedRootProductConditionalBlock = connect(mapStateToProps)(
    PDPSelectedRootProductConditionalBlockComponent,
);
