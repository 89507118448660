import classNames from "classnames";
import React from "react";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";
import { t } from "ttag";

import iconCaretDownAccount from "../../../../img/icons/caret-down-account.svg";
import iconCircleClose from "../../../../img/icons/circle-close.svg";
import iconMobileAccount from "../../../../img/icons/mobile-account.svg";
import { Link } from "../../../common/Link";
import { ModalStyles } from "../../../common/Modal";
import { UncontrolledPopover } from "../../../common/Popover";
import { IUser } from "../../../models/user.interfaces";
import { urls } from "../../../utils/urls";
import { TStateMapper } from "../../reducers.interfaces";
import { LoginForm } from "./LoginForm";

import styles from "./AccountMenu.module.scss";

interface IOwnProps {}

interface IReduxProps {
    user: IUser | null;
}

type IProps = IOwnProps & IReduxProps;

enum Page {
    INITIAL,
    LOGIN_FORM,
}

interface IState {
    page: Page;
}

class AccountMenuComponent extends React.Component<IProps, IState> {
    // private menuLink: HTMLButtonElement | null = null;
    private loginPopover: HTMLDivElement | null = null;
    private accountPopover: HTMLDivElement | null = null;
    private container: HTMLDivElement | null = null;

    public state: IState = {
        page: Page.INITIAL,
    };

    componentDidUpdate() {
        this.setModalHeight();
    }

    private readonly onGotoLogin = () => {
        this.setState({
            page: Page.LOGIN_FORM,
        });
    };

    private readonly onGotoList = () => {
        this.setState({
            page: Page.INITIAL,
        });
    };

    private readonly setModalHeight = () => {
        const elem =
            this.state.page === Page.LOGIN_FORM
                ? this.loginPopover
                : this.accountPopover;
        const container = this.container;
        if (!elem || !container) {
            return;
        }
        container.style.height = `${elem.scrollHeight}px`;
    };

    private renderProfileLinkList() {
        const hasEmail = this.props.user && this.props.user.email;
        const hasPassword = this.props.user && this.props.user.has_password;

        const ordersURL = urls.pageURL("customer-order-list");
        const lookupOrdersURL = urls.pageURL("customer-lookup-orders-by-email");
        const customerServiceURL = urls.pageURL("customer-service");
        const myAccountURL = urls.pageURL("customer-profile-view");
        const logoutURL = urls.pageURL("customer-logout");

        const ordersLink = ordersURL ? (
            <li
                className={`${styles.accountMenuPane1ListItem} ${styles.accountMenuPane1ListItemFirst}`}
            >
                <Link target="_top" href={ordersURL}>
                    {t`Orders`}
                </Link>
            </li>
        ) : null;
        const lookupOrdersLink = lookupOrdersURL ? (
            <li className={styles.accountMenuPane1ListItem}>
                <Link target="_top" href={lookupOrdersURL}>
                    {t`Check Order Status`}
                </Link>
            </li>
        ) : null;
        const customerServiceLink = customerServiceURL ? (
            <li className={styles.accountMenuPane1ListItem}>
                <Link target="_top" href={customerServiceURL}>
                    {t`Help Center`}
                </Link>
            </li>
        ) : null;
        const myAccountLink = myAccountURL ? (
            <li className={styles.accountMenuPane1ListItem}>
                <Link target="_top" href={myAccountURL}>
                    {t`My Account`}
                </Link>
            </li>
        ) : null;
        const logoutLink = logoutURL ? (
            <li
                className={`${styles.accountMenuPane1ListItem} ${styles.accountMenuPane1ListItemLast}`}
            >
                <Link target="_top" href={logoutURL}>
                    {t`Log Out`}
                </Link>
            </li>
        ) : null;
        const loginLink = (
            <li
                className={`${styles.accountMenuPane1ListItem} ${styles.accountMenuPane1ListItemLast}`}
            >
                <button type="button" onClick={this.onGotoLogin}>
                    {t`Login/Create Account`}
                </button>
            </li>
        );
        return (
            <ul className={styles.accountMenuPane1List}>
                {hasEmail ? ordersLink : lookupOrdersLink}
                {hasEmail && myAccountLink}
                {customerServiceLink}
                {hasPassword ? logoutLink : loginLink}
            </ul>
        );
    }

    render() {
        const modalStyle: ModalStyles = {
            overlay: {
                position: "absolute",
                backgroundColor: "rgba(255, 255, 255, 0)",
            },
            content: {
                width: "320px",
                padding: "15px",
                overflow: "hidden",
                border: "none",
                boxShadow: "none",
            },
        };
        const modalClasses = classNames({
            [styles.accountMenu]: true,
        });
        const pane1Classes = classNames({
            [styles.accountMenuPane1]: true,
            [styles.accountMenuPaneHidden]: this.state.page !== Page.INITIAL,
        });
        const pane2Classes = classNames({
            [styles.accountMenuPane2]: true,
            [styles.accountMenuPaneHidden]: this.state.page !== Page.LOGIN_FORM,
        });
        const accountPopover = (
            <div
                className={pane1Classes}
                ref={(ref) => {
                    this.accountPopover = ref;
                }}
            >
                {this.renderProfileLinkList()}
            </div>
        );
        const login = (
            <div
                className={pane2Classes}
                ref={(ref) => {
                    this.loginPopover = ref;
                }}
            >
                <h2 className={styles.accountMenuPane2Title}>{t`Login`}</h2>
                <LoginForm
                    onAfterLoad={this.setModalHeight}
                    onAfterSave={this.setModalHeight}
                />
            </div>
        );
        const modalContent = (
            <div
                className={modalClasses}
                ref={(ref) => {
                    this.container = ref;
                }}
            >
                <h1 className="ada-screenreader-only">{t`Support`}</h1>
                {this.state.page === Page.LOGIN_FORM && (
                    <button
                        className={styles.returnLink}
                        onClick={this.onGotoList}
                    >
                        {t`Go Back`}
                    </button>
                )}
                {accountPopover}
                {login}
            </div>
        );
        let accountText: string = t`Support`;
        if (
            this.props.user &&
            this.props.user.first_name &&
            this.props.user.has_password
        ) {
            accountText = t`Hi, ${this.props.user.first_name} `;
        }
        return (
            <span>
                <UncontrolledPopover
                    triggerContent={
                        <>
                            <span className={styles.accountMenuLinkText}>
                                {accountText}
                                &nbsp;
                                <SVG
                                    src={iconCaretDownAccount}
                                    title={t`View Account Icon`}
                                    aria-hidden="true"
                                />
                            </span>
                            <span className={styles.accountMenuLinkIcon}>
                                <SVG
                                    src={iconMobileAccount}
                                    title={t`View Account Icon`}
                                    aria-hidden="true"
                                />
                            </span>
                        </>
                    }
                    triggerBtnProps={{
                        "className": styles.accountMenuLink,
                        "aria-label": t`Support`,
                    }}
                    modalProps={{
                        contentLabel: t`Account`,
                        style: modalStyle,
                        closeBtnIcon: iconCircleClose,
                        closeBtnProps: {
                            style: {
                                top: "10px",
                                right: "10px",
                            },
                        },
                    }}
                >
                    {modalContent}
                </UncontrolledPopover>
            </span>
        );
    }
}

const mapStateToProps: TStateMapper<"common", IReduxProps, IOwnProps> = (
    state,
    ownProps,
) => {
    return {
        user: state.common.user,
        ...ownProps,
    };
};

export const AccountMenu = connect(mapStateToProps)(AccountMenuComponent);
