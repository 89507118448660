import React from "react";
import { t } from "ttag";

import { Trans } from "../../../common/Trans";
import { strings } from "../../../localization";
import { BackendType } from "../../../models/financing";
import { AgreeProps, getFormSteps } from "../FinancingFullApplyForm/steps";
import { ModalFullApplyDenied } from "../models";
import { printSummary } from "../printing";
import { FortivaDeniedMessage } from "./FortivaDeniedMessage";
import { WFDeniedMessage } from "./WFDeniedMessage";

import styles from "./index.module.scss";

export const DeniedMessage = (props: {
    backendType: BackendType;
    modalState: Pick<ModalFullApplyDenied, "appType" | "values">;
}) => {
    const onPrint = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        const stepProps: AgreeProps = {
            backendType: null,
            offer: null,
            onValidStateChange: () => null,
            showErrorMessages: false,
            clientErrors: {},
            onAgree: () => null,
            onESign: () => null,
        };
        const formSteps = getFormSteps(
            props.modalState.appType,
            props.modalState.values,
            stepProps,
        );
        printSummary({
            formSteps: formSteps,
            values: props.modalState.values,
        });
    };

    let innerMsg: JSX.Element | null;
    switch (props.backendType) {
        case BackendType.FORTIVA: {
            innerMsg = <FortivaDeniedMessage />;
            break;
        }
        case BackendType.WELLSFARGO: {
            innerMsg = <WFDeniedMessage />;
            break;
        }
        default: {
            innerMsg = null;
        }
    }
    const cardName = strings.get("FINANCING_CARD_NAME");
    return (
        <>
            <p>
                <strong>
                    {props.modalState.values.main_applicant__first_name},
                </strong>
            </p>
            <p>
                <Trans
                    fmtString={t`Thank you for your interest in the ${cardName}.`}
                    data={{
                        SUP: (content) => <sup key={1}>{content}</sup>,
                    }}
                />
            </p>
            {innerMsg}
            <button className={styles.link} onClick={onPrint}>
                {t`Print Your Application`}
            </button>
        </>
    );
};
