import React from "react";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { ajax } from "tsi-common-react/src/utils/ajax";

import { IEmailSendStorePostData } from "../models.interfaces";
import { buildStoreData } from "../utils";
import { LocatorSendSingleStoreModal } from "./LocatorSendSingleStore_Modal";
import { LocatorSurveyModal } from "./LocatorSurveyModal";

interface IProps {
    store: IRetailStoreWithDistance;
}

interface IState {
    surveyOpen: boolean;
    surveyEmail: string;
}

export class LocatorSendSingleStore extends React.Component<IProps, IState> {
    state: IState = {
        surveyOpen: false,
        surveyEmail: "",
    };

    private readonly onSubmit = async (recipient: string) => {
        const stores = [this.props.store];
        const selected = new Set([this.props.store.external_id]);
        const data: IEmailSendStorePostData = {
            email: recipient,
            stores: buildStoreData(stores, selected),
        };
        await ajax
            .post("https://api.promosis.com/api/v1/tsi-2249/email")
            .send(data);
        this.setState({
            surveyOpen: true,
            surveyEmail: recipient,
        });
    };

    render() {
        return (
            <>
                <LocatorSendSingleStoreModal onSubmit={this.onSubmit} />
                <LocatorSurveyModal
                    isOpen={this.state.surveyOpen}
                    email={this.state.surveyEmail}
                    onClose={() => {
                        this.setState({
                            surveyOpen: false,
                        });
                    }}
                />
            </>
        );
    }
}
